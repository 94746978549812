import * as React from "react";
import { forwardRef, useEffect } from "react";
import { FormAnnotation, FormError, FormSuccess } from "./index";
export function SelectLabel(props, ref) {
    var _a, _b;
    function OnSelectChange(evt) {
        if (typeof props.valueSetter === 'function') {
            props.valueSetter(evt.target.value);
        }
    }
    useEffect(function () {
        if (props.defaultValue !== undefined && props.valueSetter) {
            props.valueSetter(props.defaultValue);
        }
    }, [props.defaultValue]);
    return (React.createElement("label", { hidden: props.hidden, className: props.className + (props.error ? ' error' : '') },
        React.createElement("span", { hidden: !props.title },
            props.title,
            props.required ? React.createElement("span", { className: "required" }, "*") : ''),
        React.createElement("span", { className: "form-item-wrapper" },
            React.createElement("select", { name: (_a = props.name) !== null && _a !== void 0 ? _a : '', defaultValue: props.value, value: props.value, onChange: OnSelectChange, disabled: props.disabled, ref: ref },
                ((_b = props.options) !== null && _b !== void 0 ? _b : []).map(function (_a) {
                    var val = _a[0], name = _a[1], _b = _a[2], disabled = _b === void 0 ? false : _b;
                    return React.createElement("option", { value: val, dangerouslySetInnerHTML: { __html: name }, disabled: disabled });
                }),
                props.children,
                !props.options.length && React.createElement("option", { disabled: true, selected: true }, "(\u043D\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B\u0445 \u0432\u0430\u0440\u0438\u0430\u043D\u0442\u043E\u0432)"))),
        React.createElement(FormAnnotation, { text: props.annotation }),
        React.createElement(FormError, { text: props.error }),
        React.createElement(FormSuccess, { text: props.success })));
}
export var SelectLabelWithRef = forwardRef(SelectLabel);
