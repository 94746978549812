import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../functions";
import Attention from "../../components/Attention";
import { AccessAPI } from "../../api/request/access/api";
export default function AdminDBMonitorPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState([]), checkerMessages = _b[0], setCheckerMessages = _b[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('db'),
            fetch('/api/admin/db/check', { method: 'POST' })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                setCheckerMessages(response);
            }, function (error) {
                setCheckerMessages([
                    { status: 'error', message: 'Что-то не так со скриптом, который проверяет корректность базы данных, напишите в поддержку' }
                ]);
            })
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll().finally(function () {
            finishLoading();
        });
    }, []);
    return (React.createElement("div", { className: "admin-documents-page" },
        React.createElement("div", { className: "container" },
            React.createElement("h2", { className: "admin-documents-page__title" }, "\u041C\u043E\u043D\u0438\u0442\u043E\u0440 \u0431\u0430\u0437\u044B \u0434\u0430\u043D\u043D\u044B\u0445"),
            React.createElement(Attention, { status: "info" }, "\u041F\u043E\u043A\u0430 \u043C\u043E\u043D\u0438\u0442\u043E\u0440\u0438\u0442\u0441\u044F \u0442\u043E\u043B\u044C\u043A\u043E \u0442\u0430\u0431\u043B\u0438\u0446\u0430 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0435\u0439"),
            React.createElement("div", { hidden: Boolean(checkerMessages.length) },
                React.createElement(Attention, { status: "good", style: { marginTop: 20 } },
                    React.createElement("b", null, "\u0421 \u0431\u0430\u0437\u043E\u0439 \u0434\u0430\u043D\u043D\u044B\u0445 \u0432\u0441\u0435 \u0441\u0443\u043F\u0435\u0440!"))),
            checkerMessages.map(function (msg) { return React.createElement(Attention, { status: msg.status, style: { marginTop: 10 } }, msg.message); }))));
}
