import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../functions";
import AvatarForm from "./avatarForm";
import MainInfoForm from "./mainInfoForm";
import TgForm from "./tgForm";
import InfoForm from "./infoForm";
import UserAPI from "../../api/request/user/api";
import { IUserTemplate } from "../../api/interfaces/entity/User";
"";
export default function PersonalPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState(IUserTemplate()), user = _b[0], setUser = _b[1];
    function UpdateInfo() {
        return UserAPI.GetLoggedUser()
            .then(function (res) { return setUser(res.user); });
    }
    useEffect(function () {
        startLoading();
        UpdateInfo()
            .finally(function () {
            finishLoading();
        });
    }, []);
    return (React.createElement("div", { className: "personal-page" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "personal-page__title" },
                React.createElement("span", { className: "material-symbols-outlined personal-page__icon" }, "assignment_ind"),
                " \u041B\u0438\u0447\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435"),
            React.createElement(AvatarForm, { updater: UpdateInfo, user: user }),
            React.createElement(MainInfoForm, { user: user }),
            React.createElement(TgForm, { user: user }),
            React.createElement(InfoForm, { updater: UpdateInfo, user: user }))));
}
