var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import GetLayout from './components/Layout';
// import store from './store/store'
// import {Provider} from 'react-redux'
import { CheckedLoggedIn, CheckIfPageIsPublic, CheckIfPageWithoutEssentials } from './functions';
import Page404 from './pages/404';
import AuthCode from './pages/auth/code';
import AuthLogin from './pages/auth/login';
import AuthSignup from './pages/auth/signup';
import Development from './pages/development';
import DocumentsPage from './pages/documents';
import FAQPage from './pages/faq';
import IndexPage from './pages/index';
import OnboardingPage from './pages/onboarding';
import PaymentsPage from './pages/payments';
import PersonalPage from './pages/personal';
import Structure from './pages/structure';
import TinderPage from './pages/tinder';
import UserPage from './pages/user';
import AdminDBMonitorPage from './pagesAD/dbmonitor';
import AdminDocumentsPage from './pagesAD/documents';
import AdminJobsPage from './pagesAD/jobs';
import AdminJobsApprovePage from './pagesAD/jobs/approve';
import AdminJobsCvsPage from './pagesAD/jobs/cvs';
import AdminJobsWatchPage from './pagesAD/jobs/watch';
import AdminMailingPage from './pagesAD/mailing';
import AdminBusinessPaymentPage from './pagesAD/payments/business';
import AdminCreatePaymentPage from './pagesAD/payments/create';
import AdminHRPaymentPage from './pagesAD/payments/hr';
import AdminNewPositionPage from './pagesAD/positions/newPosition';
import AdminNewRealPositionPage from './pagesAD/positions/newRealPosition';
import AdminOnePositionPage from './pagesAD/positions/onePosition';
import AdminOneRealPositionPage from './pagesAD/positions/oneRealPosition';
import AdminUserPositionsPage from './pagesAD/positions/userPositions';
import AdminSetADGroupsPage from './pagesAD/setadgroups';
import Bot5SignupPage from './public/bot5/signup';
import JobsPage from './public/jobs';
var LayoutWithEssentials = GetLayout(true);
var LayoutNoEssentials = GetLayout(false);
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        function JobsPageWrapper() {
            var match = useMatch('/jobs/:id');
            return (React.createElement(JobsPage, { id: match === null || match === void 0 ? void 0 : match.params.id }));
        }
        function JobsPageAlternativeWrapper() {
            var match = useMatch('/:id');
            return (React.createElement(JobsPage, { id: match === null || match === void 0 ? void 0 : match.params.id }));
        }
        function JobsNavigator() {
            var match = useMatch('/jobs/:id');
            return (React.createElement(Navigate, { to: "/".concat(match === null || match === void 0 ? void 0 : match.params.id), replace: true }));
        }
        if (location.hostname === 'xn--80aae0aajw6a.xn--80a2ac.xn--p1ai') { // вакансии.апо.рф
            return (React.createElement(LayoutNoEssentials, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: '/', Component: JobsPage }),
                    React.createElement(Route, { path: '/:id', Component: JobsPageAlternativeWrapper }),
                    React.createElement(Route, { path: "/jobs", element: React.createElement(Navigate, { to: '/', replace: true }) }),
                    React.createElement(Route, { path: "/jobs/:id", Component: JobsNavigator }),
                    React.createElement(Route, { path: '/miniapps/signup', Component: Bot5SignupPage }))));
        }
        if (CheckIfPageWithoutEssentials(location.pathname)) {
            if (!CheckIfPageIsPublic(location.pathname)) {
                CheckedLoggedIn(false);
            }
            return (React.createElement(LayoutNoEssentials, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: '/auth/login', Component: AuthLogin }),
                    React.createElement(Route, { path: '/auth/code', Component: AuthCode }),
                    React.createElement(Route, { path: '/auth/signup', Component: AuthSignup }),
                    React.createElement(Route, { path: '/jobs', Component: JobsPage }),
                    React.createElement(Route, { path: '/jobs/:id', Component: JobsPageWrapper }))));
        }
        CheckedLoggedIn(true);
        function UserPageWrapper() {
            var match = useMatch('/user/:id');
            return (React.createElement(UserPage, { id: match === null || match === void 0 ? void 0 : match.params.id }));
        }
        function StructurePageWrapper() {
            var match = useMatch('/structure/:id');
            return (React.createElement(Structure, { id: match === null || match === void 0 ? void 0 : match.params.id }));
        }
        function OnboardingPageWrapper() {
            var match = useMatch('/onboarding/:page_id');
            return (React.createElement(OnboardingPage, { pageId: parseInt(match === null || match === void 0 ? void 0 : match.params.page_id, 10) }));
        }
        function AdminPositionsPageWrapper() {
            var match = useMatch('/admin/positions/:user_id');
            return (React.createElement(AdminUserPositionsPage, { user_id: parseInt(match === null || match === void 0 ? void 0 : match.params.user_id, 10) }));
        }
        function AdminSetADGroupsPageWrapper() {
            var match = useMatch('/admin/setadgroups/:user_id');
            return (React.createElement(AdminSetADGroupsPage, { user_id: parseInt(match === null || match === void 0 ? void 0 : match.params.user_id, 10) }));
        }
        function AdminOnePositionPageWrapper() {
            var match = useMatch('/admin/positions/:user_id/:pos_id');
            if ((match === null || match === void 0 ? void 0 : match.params.pos_id) === 'new') {
                return React.createElement(AdminNewPositionPage, { user_id: parseInt(match === null || match === void 0 ? void 0 : match.params.user_id, 10) });
            }
            if ((match === null || match === void 0 ? void 0 : match.params.pos_id) === 'newreal') {
                return React.createElement(AdminNewRealPositionPage, { user_id: parseInt(match === null || match === void 0 ? void 0 : match.params.user_id, 10) });
            }
            if (parseInt(match === null || match === void 0 ? void 0 : match.params.pos_id, 10) < 0) {
                return React.createElement(AdminOneRealPositionPage, { user_id: parseInt(match === null || match === void 0 ? void 0 : match.params.user_id, 10), pos_id: -parseInt(match === null || match === void 0 ? void 0 : match.params.pos_id, 10) });
            }
            return React.createElement(AdminOnePositionPage, { user_id: parseInt(match === null || match === void 0 ? void 0 : match.params.user_id, 10), pos_id: parseInt(match === null || match === void 0 ? void 0 : match.params.pos_id, 10) });
        }
        return (React.createElement(LayoutWithEssentials, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: '/', Component: IndexPage }),
                React.createElement(Route, { path: '/personal', Component: PersonalPage }),
                React.createElement(Route, { path: '/tinder', Component: TinderPage }),
                React.createElement(Route, { path: '/documents', Component: DocumentsPage }),
                React.createElement(Route, { path: '/payments', Component: PaymentsPage }),
                React.createElement(Route, { path: '/teammessage', Component: Development }),
                React.createElement(Route, { path: '/faq', Component: FAQPage }),
                React.createElement(Route, { path: '/booking', Component: Development }),
                React.createElement(Route, { path: '/structure', Component: function () { return React.createElement(Structure, { id: 0 }); } }),
                React.createElement(Route, { path: '/structure/:id', Component: StructurePageWrapper }),
                React.createElement(Route, { path: '/onboarding', Component: function () { return React.createElement(OnboardingPage, { pageId: 1 }); } }),
                React.createElement(Route, { path: '/onboarding/:page_id', Component: OnboardingPageWrapper }),
                React.createElement(Route, { path: '/user/:tg_username', Component: UserPageWrapper }),
                React.createElement(Route, { path: '/admin/documents', Component: AdminDocumentsPage }),
                React.createElement(Route, { path: '/admin/mailing', Component: AdminMailingPage }),
                React.createElement(Route, { path: '/admin/dbmonitor', Component: AdminDBMonitorPage }),
                React.createElement(Route, { path: '/admin/jobs', Component: AdminJobsPage }),
                React.createElement(Route, { path: '/admin/jobs/cvs', Component: AdminJobsCvsPage }),
                React.createElement(Route, { path: '/admin/jobs/approve', Component: AdminJobsApprovePage }),
                React.createElement(Route, { path: '/admin/jobs/watch', Component: AdminJobsWatchPage }),
                React.createElement(Route, { path: '/admin/positions/:user_id/:pos_id', Component: AdminOnePositionPageWrapper }),
                React.createElement(Route, { path: '/admin/positions/:user_id', Component: AdminPositionsPageWrapper }),
                React.createElement(Route, { path: '/admin/payments/create', Component: AdminCreatePaymentPage }),
                React.createElement(Route, { path: '/admin/payments/business', Component: AdminBusinessPaymentPage }),
                React.createElement(Route, { path: '/admin/payments/hr', Component: AdminHRPaymentPage }),
                React.createElement(Route, { path: '/admin/setadgroups/:user_id', Component: AdminSetADGroupsPageWrapper }),
                React.createElement(Route, { path: '*', Component: Page404 }))));
    };
    return App;
}(React.Component));
export default App;
