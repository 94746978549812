import * as React from "react";
import { useState } from "react";
import { FileColor, RussianNoun } from "../../functions";
import DocumentUploaderInput from "./input";
import DocumentsAPI from "../../api/request/documents/api";
export default function DocumentUploader(_a) {
    var doc = _a.document, updater = _a.updater;
    var _b = useState(false), uploading = _b[0], setUploading = _b[1];
    var _c = useState(''), errorText = _c[0], setErrorText = _c[1];
    var documentStatuses = {
        'ok': '<span class="material-symbols-outlined document-uploader-component__status-icon">done</span>',
        'not uploaded': 'не загружено',
        'not filled': 'не все поля заполнены',
        'expires soon': 'скоро истечет срок действия',
        'expired': 'истек срок действия',
    };
    var doctypeColors = {
        'jpg': '#a69ab5',
        'pdf': '#859ca3',
    };
    var doctypes = {
        'jpg': 'image/jpeg',
        'pdf': 'application/pdf',
    };
    function uploadFile(evt) {
        evt.preventDefault();
        setUploading(true);
        var filesize = Array.from(evt.target.files)
            .map(function (_a) {
            var size = _a.size;
            return size;
        })
            .reduce(function (sum, item) { return sum + item; }, 0);
        if (filesize >= 60 * 1024 * 1024) {
            setErrorText('Файлы весят больше 60&nbsp;Мб, воспользуйтесь сервисами для сжатия файлов' + (evt.target.files.length > 1 ? ' или попробуйте загрузить файлы по отдельности' : '') + '. Если с&nbsp;проблемой справиться не&nbsp;получиться, обратитесь в&nbsp;поддержку <a href="https://t.me/apo_help" class="text-link">@apo_help</a>');
            setUploading(false);
            return;
        }
        setErrorText('');
        DocumentsAPI.UploadFile(evt.target.form, function () {
            setErrorText('Возникла ошибка при загрузке файлов, попробуйте еще раз позже. ' +
                'Если проблема останется, обратитесь в&nbsp;поддержку ' +
                '<a href="https://t.me/apo_help" class="text-link">@apo_help</a>');
        })
            .finally(function () { return updater(); })
            .finally(function () { return setUploading(false); });
    }
    function removeFile(classId, filename) {
        DocumentsAPI.RemoveUploadedFile(classId, filename)
            .finally(updater);
    }
    function submitTexts(evt) {
        evt.preventDefault();
        setUploading(true);
        DocumentsAPI.SetTexts(document.querySelector('#form-document-' + doc.id), function () {
            setErrorText('Возникла ошибка при сохранении текстовой информации, попробуйте еще раз позже. ' +
                'Если проблема останется, обратитесь в&nbsp;поддержку ' +
                '<a href="https://t.me/apo_help" class="text-link">@apo_help</a>');
        })
            .finally(function () { return updater(); })
            .finally(function () { return setUploading(false); });
    }
    var buttonText;
    if (doc.multiple) {
        if (doc.urls.length) {
            buttonText = 'Добавить файлы';
        }
        else {
            buttonText = 'Загрузить файлы';
        }
    }
    else {
        if (doc.status === 'expired' || doc.status === 'expires soon') {
            buttonText = 'Обновить файл';
        }
        else {
            buttonText = 'Загрузить один файл';
        }
    }
    return (React.createElement("div", { className: "document-uploader-component" },
        React.createElement("div", { className: 'document-uploader-component__title'
                + (doc.status === 'not uploaded' ? ' document-uploader-component__title--empty ' : '')
                + (doc.status === 'not filled' ? ' document-uploader-component__title--not-filled ' : '')
                + (doc.status === 'expires soon' ? ' document-uploader-component__title--expires-soon ' : '')
                + (doc.status === 'expired' ? ' document-uploader-component__title--expired ' : '') },
            doc.class_name,
            " ",
            React.createElement("span", null,
                React.createElement("span", { className: "document-uploader-component__status-label", hidden: doc.status !== 'expires soon' }, 'истекает через ' + doc.expires_in + ' ' + RussianNoun(doc.expires_in, ['день', 'дня', 'дней'])),
                React.createElement("span", { className: "document-uploader-component__status-label", hidden: doc.status === 'expires soon', dangerouslySetInnerHTML: { __html: documentStatuses[doc.status] } }))),
        React.createElement("div", { className: "document-uploader-component__description" }, doc.description),
        React.createElement("form", { onSubmit: function (evt) { return evt.preventDefault(); }, className: "document-uploader-component__form" },
            React.createElement("label", null,
                React.createElement("div", { className: 'document-uploader-component__upload-button ' + (uploading ? 'disabled' : ''), hidden: doc.urls.length && !doc.multiple && doc.status !== 'expired' && doc.status !== 'expires soon' },
                    buttonText,
                    " ",
                    React.createElement("span", { className: "document-uploader-component__doctype", style: { backgroundColor: doctypeColors[doc.doctype] } }, doc.doctype.toUpperCase())),
                doc.multiple ?
                    React.createElement("input", { type: "file", onChange: uploadFile, name: "file[]", accept: doctypes[doc.doctype], multiple: true, hidden: true }) :
                    React.createElement("input", { type: "file", onChange: uploadFile, name: "file", accept: doctypes[doc.doctype], hidden: true })),
            React.createElement("input", { type: "hidden", name: "class_id", value: doc.class_id }),
            React.createElement("div", { className: "document-uploader-component__files" }, doc.urls.map(function (url) { return React.createElement("div", { className: "document-uploader-component__file-item" },
                React.createElement("a", { href: url, className: "document-uploader-component__file-image", style: doc.doctype === 'jpg' ? { backgroundImage: 'url("' + url + '")' } : {} },
                    React.createElement("div", { className: "document-uploader-component__file-type", style: { backgroundColor: 'hsl(' + FileColor(url) + ' 21% 77%)' }, hidden: doc.doctype !== 'pdf' },
                        React.createElement("span", { className: "material-symbols-outlined" }, "description")),
                    React.createElement("div", { className: 'document-uploader-component__file-open ' + (doc.doctype === 'pdf' ? 'document-uploader-component__file-open--for-file' : '') },
                        React.createElement("span", { className: "material-symbols-outlined" }, "open_in_new"))),
                React.createElement("div", { onClick: function () { return removeFile(doc.class_id, url); }, className: "document-uploader-component__file-delete" },
                    React.createElement("span", { className: "material-symbols-outlined" }, "close"))); }))),
        React.createElement("form", { onSubmit: submitTexts, id: 'form-document-' + doc.id, className: "document-uploader-component__inputs" },
            React.createElement("input", { type: "hidden", name: "class_id", value: doc.class_id }),
            doc.textdata.map(function (label, i) {
                return React.createElement(DocumentUploaderInput, { label: label, i: i, document: doc, init_value: doc.texts[i] });
            }),
            doc.textdata.length ? React.createElement("button", { disabled: uploading }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C") : '')));
}
