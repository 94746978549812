var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from "../../api";
var AccessAPI = /** @class */ (function (_super) {
    __extends(AccessAPI, _super);
    function AccessAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AccessAPI.CheckAccess = function (pageTag) {
        return _a.JustCheckAccess(pageTag)
            .then(function (permitted) {
            if (!permitted) {
                location.href = '/';
            }
        });
    };
    var _a;
    _a = AccessAPI;
    AccessAPI.JustCheckAccess = function (pageTag) { return _a.BooleanQuery({
        url: 'request/access/CheckAccess/',
        data: { page_tag: pageTag },
    }); };
    return AccessAPI;
}(API));
export { AccessAPI };
