import * as React from 'react';
import { BirthdayFormat, SinceFormat } from '../../../functions';
import LockIcon from '../components/lockIcon';
export default function InfoBlock(_a) {
    var _b = _a.user, last_name = _b.last_name, first_name = _b.first_name, patronymic = _b.patronymic, birthday = _b.birthday, in_company_since = _b.in_company_since, city = _b.city, university = _b.university, contract_type = _b.contract_type, isMe = _a.isMe;
    return (React.createElement("div", { className: "user-page__info-block" },
        React.createElement("h2", { className: "user-page__info-title" }, "\u041B\u0438\u0447\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435"),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u0424\u0418\u041E"),
            React.createElement("div", { className: "user-page__info-item-data" },
                last_name,
                " ",
                first_name,
                " ",
                patronymic || '')),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u0414\u0435\u043D\u044C \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F"),
            React.createElement("div", { className: "user-page__info-item-data" }, BirthdayFormat(birthday))),
        in_company_since ?
            React.createElement("div", { className: "user-page__info-item" },
                React.createElement("div", { className: "user-page__info-item-name" }, "\u0412 \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438"),
                React.createElement("div", { className: "user-page__info-item-data" }, SinceFormat(in_company_since))) : '',
        React.createElement("div", { className: "user-page__line", hidden: !city && !university && !contract_type }),
        city ?
            React.createElement("div", { className: "user-page__info-item" },
                React.createElement("div", { className: "user-page__info-item-name" },
                    "\u0413\u043E\u0440\u043E\u0434 ",
                    React.createElement(LockIcon, { hidden: !isMe })),
                React.createElement("div", { className: "user-page__info-item-data" }, city)) : '',
        university ?
            React.createElement("div", { className: "user-page__info-item" },
                React.createElement("div", { className: "user-page__info-item-name" },
                    "\u0412\u0443\u0437 ",
                    React.createElement(LockIcon, { hidden: !isMe })),
                React.createElement("div", { className: "user-page__info-item-data" }, university)) : '',
        contract_type ?
            React.createElement("div", { className: "user-page__info-item" },
                React.createElement("div", { className: "user-page__info-item-name" },
                    "\u0422\u0438\u043F \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u0430 ",
                    React.createElement(LockIcon, { hidden: !isMe })),
                React.createElement("div", { className: "user-page__info-item-data" }, contract_type)) : ''));
}
