var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from "../../api";
import { IUserTemplate } from "../../interfaces/entity/User";
import { IUserHeaderTemplate } from "../../interfaces/entity/UserHeader";
var UserAPI = /** @class */ (function (_super) {
    __extends(UserAPI, _super);
    function UserAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = UserAPI;
    UserAPI.GetUserHeader = function () { return _a.AlwaysResolvingQuery({
        url: 'request/user/GetUserHeader/',
        dataCallback: function (data) { return data.user; },
        defaultValueCallback: function () { return IUserHeaderTemplate(); },
    }); };
    UserAPI.GetLoggedUser = function () { return _a.RejectingOnErrorUnchangedQuery({
        url: 'request/user/GetLoggedUser/',
    }); };
    UserAPI.GetUserById = function (userId) { return _a.AlwaysResolvingQuery({
        url: 'request/user/GetUserById/',
        data: { user_id: userId },
        dataCallback: function (data) { return data.user; },
        defaultValueCallback: function () { return IUserTemplate(); },
    }); };
    UserAPI.GetUserDocumentsStatuses = function (userId) { return _a.AlwaysResolvingQuery({
        url: 'request/user/GetUserDocumentsStatuses/',
        data: { user_id: userId },
        dataCallback: function (data) { return data.documents; },
        defaultValueCallback: function () { return []; },
    }); };
    UserAPI.SetAvatar = function (form) { return _a.VoidQuery({
        url: 'request/user/SetAvatar/',
        data: form,
    }); };
    UserAPI.SetUserInfo = function (form) { return _a.VoidQuery({
        url: 'request/user/SetUserInfo/',
        data: form,
    }); };
    return UserAPI;
}(API));
export default UserAPI;
