import * as React from "react";
import { useEffect, useState } from "react";
import { InputLabel } from "../../components/Form/InputLabel";
export default function TgForm(props) {
    var _a = useState(0), key = _a[0], setKey = _a[1];
    useEffect(function () { setKey(key + 1); }, [props.user]);
    return (React.createElement("form", { onSubmit: function (evt) { return evt.preventDefault(); }, className: "personal-page__form", key: key },
        React.createElement("h3", null, "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B"),
        React.createElement(InputLabel, { title: "\u041D\u0438\u043A\u043D\u0435\u0439\u043C \u0432 \u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C\u0435", annotation: "\u0412\u0430\u0448 \u0430\u043A\u043A\u0430\u0443\u043D\u0442 \u043D\u0430 \u0441\u0430\u0439\u0442\u0435 \u043F\u0440\u0438\u0432\u044F\u0437\u0430\u043D \u043A \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0443 \u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C\u0430. \u0414\u0430\u0436\u0435 \u043F\u0440\u0438 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0438 \u043D\u0438\u043A\u043D\u0435\u0439\u043C\u0430\n                                    \u0441\u0432\u044F\u0437\u044C \u043D\u0435 \u043F\u0440\u043E\u043F\u0430\u0434\u0435\u0442. \u041F\u043E\u044D\u0442\u043E\u043C\u0443, \u0435\u0441\u043B\u0438 \u0432\u044B \u043F\u043E\u043C\u0435\u043D\u044F\u043B\u0438 \u043D\u0438\u043A\u043D\u0435\u0439\u043C, \u043F\u0440\u043E\u0441\u0442\u043E \u043D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u043B\u044E\u0431\u043E\u0435\n                                    \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 \u0432 \u0431\u043E\u0442, \u043E\u043D \u0437\u0430\u043C\u0435\u0442\u0438\u0442 \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u043D\u0438\u043A\u043D\u0435\u0439\u043C\u0430 \u0438 \u0438\u0441\u043F\u0440\u0430\u0432\u0438\u0442 \u0435\u0433\u043E \u043D\u0430 \u0441\u0430\u0439\u0442\u0435", required: true, disabled: true, defaultValue: props.user.tg_username_original_case })));
}
