var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AdminAPI } from "../api";
import { IGetADGroupsForUserResponseTemplate } from "./GetADGroupsForUser/response";
var ADGroupsAdminAPI = /** @class */ (function (_super) {
    __extends(ADGroupsAdminAPI, _super);
    function ADGroupsAdminAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = ADGroupsAdminAPI;
    ADGroupsAdminAPI.AddADGroup = function (userId, adGroupId) { return _a.VoidQuery({
        url: 'admin/adgroups/AddADGroup/',
        data: {
            user_id: userId,
            ad_group: adGroupId,
        },
    }); };
    ADGroupsAdminAPI.RemoveADGroup = function (userId, adGroupId) { return _a.VoidQuery({
        url: 'admin/adgroups/RemoveADGroup/',
        data: {
            user_id: userId,
            ad_group: adGroupId,
        },
    }); };
    ADGroupsAdminAPI.GetADGroupsForUser = function (userId) { return _a.AlwaysResolvingUnchangedQuery({
        url: 'admin/adgroups/GetADGroupsForUser/',
        data: {
            user_id: userId,
        },
        defaultValueCallback: IGetADGroupsForUserResponseTemplate
    }); };
    return ADGroupsAdminAPI;
}(AdminAPI));
export { ADGroupsAdminAPI };
