var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from "../../api";
var JobsAPI = /** @class */ (function (_super) {
    __extends(JobsAPI, _super);
    function JobsAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    JobsAPI.SendCV = function (form) {
        return new Promise(function (resolve) {
            fetch('/api/request/jobs/SendCV/', {
                method: 'POST',
                body: new FormData(form),
            })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    resolve([]);
                }
                else {
                    console.error('Error in request/jobs/SendCV (in processing)', response);
                    resolve(response.errors);
                }
            }, function (error) {
                console.error('Error in request/jobs/SendCV (in promise)', error);
                resolve(['непонятная ошибка']);
            });
        });
    };
    var _a;
    _a = JobsAPI;
    JobsAPI.GetOpenJobs = function () { return _a.AlwaysResolvingQuery({
        url: 'request/jobs/GetOpenJobs/',
        dataCallback: function (data) { return data.jobs; },
        defaultValueCallback: function () { return []; },
        publicQuery: true,
    }); };
    JobsAPI.GetJobById = function (jobId) { return _a.RejectingOnErrorQuery({
        url: 'request/jobs/GetJobById/',
        data: { job_id: jobId },
        dataCallback: function (data) { return data.job; },
        publicQuery: true,
    }); };
    JobsAPI.GetFilters = function () { return _a.AlwaysResolvingQuery({
        url: 'request/jobs/GetFilters/',
        dataCallback: function (data) { return data.filters; },
        defaultValueCallback: function () { return ({}); },
        publicQuery: true,
    }); };
    JobsAPI.IsLoggedUserWatcher = function () { return _a.BooleanQuery({
        url: 'request/jobs/IsLoggedUserWatcher/',
    }); };
    return JobsAPI;
}(API));
export { JobsAPI };
