import * as React from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import { SelectLabelWithRef } from "../SelectLabel";
import { AdminPositionsAPI } from "../../../api/admin/positions/api";
export default function RealPositionClassSelect(props) {
    var _a, _b;
    var _c = useState([]), realPositionClasses = _c[0], setRealPositionClasses = _c[1];
    var selectRef = useRef();
    useEffect(function () {
        AdminPositionsAPI.GetRealPositionClasses()
            .then(function (realPositionClasses) {
            var _a;
            setRealPositionClasses(realPositionClasses.sort(function (lhs, rhs) { return (lhs.title < rhs.title ? -1 : 1); }));
            if (props.defaultValue) {
                ((_a = props.ref) !== null && _a !== void 0 ? _a : selectRef).current.value = String(props.defaultValue);
            }
        });
    }, []);
    useEffect(function () {
        var _a;
        if (props.defaultValue) {
            ((_a = props.ref) !== null && _a !== void 0 ? _a : selectRef).current.value = String(props.defaultValue);
        }
    }, [props.defaultValue]);
    return (React.createElement(SelectLabelWithRef, { title: "\u041A\u043B\u0430\u0441\u0441 \u044E\u0440. \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438", name: (_a = props.name) !== null && _a !== void 0 ? _a : 'class_id', defaultValue: props.defaultValue, options: realPositionClasses.map(function (pos) { return [pos.id, pos.title]; }), ref: (_b = props.ref) !== null && _b !== void 0 ? _b : selectRef }));
}
export var RealPositionClassSelectWithRef = forwardRef(RealPositionClassSelect);
