import * as React from "react";
import { ADGroupsAdminAPI } from "../../api/admin/adgroups/api";
export default function ADGroupLine(props) {
    function AddADGroup() {
        ADGroupsAdminAPI.AddADGroup(props.userId, props.adGroup.id)
            .finally(function () { return props.updateADGroups(); });
    }
    function RemoveADGroup() {
        ADGroupsAdminAPI.RemoveADGroup(props.userId, props.adGroup.id)
            .finally(function () { return props.updateADGroups(); });
    }
    return (React.createElement("div", { className: "admin-user-positions-page__ad-group-line", onClick: ~props.userADGroups.indexOf(props.adGroup.id) ? RemoveADGroup : AddADGroup },
        React.createElement("span", { className: "admin-user-positions-page__ad-group-button admin-user-positions-page__ad-group-button--add", hidden: Boolean(~props.userADGroups.indexOf(props.adGroup.id)) },
            React.createElement("span", { className: "material-symbols-outlined" }, "add_box")),
        React.createElement("span", { className: "admin-user-positions-page__ad-group-button admin-user-positions-page__ad-group-button--remove", hidden: !~props.userADGroups.indexOf(props.adGroup.id) },
            React.createElement("span", { className: "material-symbols-outlined" }, "disabled_by_default")),
        React.createElement("span", { className: 'admin-user-positions-page__ad-group-title ' + (~props.userADGroups.indexOf(props.adGroup.id) ? 'admin-user-positions-page__ad-group-title--selected' : '') }, props.adGroup.title)));
}
