import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles.css";
export default function TextEditor(props) {
    var _a;
    var _b = useState(EditorState.createEmpty()), editorState = _b[0], setEditorState = _b[1];
    var handleChange = function (data) {
        setEditorState(data);
    };
    var htmlData = useMemo(function () { return draftToHtml(convertToRaw(editorState.getCurrentContent())); }, [editorState]);
    var toolbarOptions = {
        options: props.moreOptions ? ["inline", "link", "list"] : ["inline", "link"],
        inline: {
            options: props.moreOptions ? ["bold", "italic", "strikethrough", "underline"] : ["bold", "italic", "strikethrough"],
        },
        list: {
            options: ["unordered"],
        },
    };
    var editorRef = useRef(null);
    useEffect(function () {
        if (props.defaultValue) {
            var blocksFromHTML = convertFromHTML(props.defaultValue);
            var state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            setEditorState(EditorState.createWithContent(state));
        }
    }, [props.defaultValue]);
    return (React.createElement("div", { className: "draft-editor" },
        React.createElement(Editor, { editorState: editorState, onEditorStateChange: handleChange, wrapperClassName: "editor-wrapper", editorClassName: "message-editor", toolbarClassName: "message-toolbar", toolbar: toolbarOptions, placeholder: (_a = props.placeholder) !== null && _a !== void 0 ? _a : 'Текст...', ref: editorRef }),
        React.createElement("input", { type: "hidden", name: props.inputName || 'textcontent', value: htmlData })));
}
