import * as React from "react";
import { Link } from "react-router-dom";
import User from "../../../components/User";
export default function PositionItem(props) {
    var _a, _b, _c, _d, _e, _f;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "user-page__line", hidden: props.hidden }),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F"),
            React.createElement("div", { className: "user-page__info-item-data" }, props.position.organisation_short_name)),
        React.createElement("div", { className: "user-page__info-item", hidden: !props.position.parent_team_id },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u041A\u043E\u043C\u0430\u043D\u0434\u0430/\u043E\u0442\u0434\u0435\u043B"),
            React.createElement("div", { className: "user-page__info-item-data" },
                React.createElement(Link, { to: '/structure/' + props.position.team_id, className: "text-link" }, props.position.team_name),
                " ",
                props.isBoss ? '(руководитель команды)' : '')),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C"),
            React.createElement("div", { className: "user-page__info-item-data" }, props.position.position_different_name || props.position.class_title)),
        props.isBoss ? '' :
            React.createElement("div", { className: "user-page__info-item", hidden: !((_a = props.position.bosses) !== null && _a !== void 0 ? _a : []).length },
                React.createElement("div", { className: "user-page__info-item-name user-page__info-item-name--with-person" }, ((_b = props.position.bosses) !== null && _b !== void 0 ? _b : []).length === 1 ? 'Руководитель' : 'Руководители'),
                React.createElement("div", { className: "user-page__info-item-data" }, ((_c = props.position.bosses) !== null && _c !== void 0 ? _c : []).map(function (boss) { return React.createElement(User, { user: boss }); }))),
        React.createElement("div", { className: "user-page__info-item", hidden: !((_d = props.position.deputies) !== null && _d !== void 0 ? _d : []).length },
            React.createElement("div", { className: "user-page__info-item-name user-page__info-item-name--with-person" }, ((_e = props.position.deputies) !== null && _e !== void 0 ? _e : []).length === 1 ? 'Заместитель' : 'Заместители'),
            React.createElement("div", { className: "user-page__info-item-data" }, ((_f = props.position.deputies) !== null && _f !== void 0 ? _f : []).map(function (deputy) { return React.createElement(User, { user: deputy }); })))));
}
