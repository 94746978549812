import * as React from "react";
import { Link } from "react-router-dom";
export default function Page404() {
    return (React.createElement("div", { className: "page-404" },
        React.createElement("div", { className: "container small-container" },
            React.createElement("div", { className: "page-404__number" },
                React.createElement("span", null, "4"),
                React.createElement("span", null, "0"),
                React.createElement("span", null, "4")),
            React.createElement("div", { className: "page-404__text" },
                React.createElement("div", { className: "page-404__text-top" }, "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435\u00A0\u043D\u0430\u0439\u0434\u0435\u043D\u0430"),
                React.createElement("div", { className: "page-404__text-lower" },
                    "\u0412\u044B\u00A0\u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430\u00A0",
                    React.createElement(Link, { to: "/", className: "text-link" },
                        "\u0433\u043B\u0430\u0432\u043D\u0443\u044E ",
                        React.createElement("span", { className: "page-404__icon material-symbols-outlined" }, "arrow_forward")))))));
}
