import * as React from "react";
import { useContext, useEffect, useState } from "react";
import User from "../../../components/User";
import { ApplyPercentage, FormatMoney, GlobalContext, RussianNoun } from "../../../functions";
import { Link } from "react-router-dom";
import { IUserTemplate } from "../../../api/interfaces/entity/User";
import { AccessAPI } from "../../../api/request/access/api";
import UserAPI from "../../../api/request/user/api";
import { AdminPositionsAPI } from "../../../api/admin/positions/api";
export default function AdminUserPositionsPage(props) {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState(IUserTemplate), user = _b[0], setUser = _b[1];
    var _c = useState([]), realPositions = _c[0], setRealPositions = _c[1];
    var _d = useState(false), hasPositionsAD = _d[0], setHasPositionsAD = _d[1];
    var _e = useState(false), hasRealPositionsAD = _e[0], setHasRealPositionsAD = _e[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.JustCheckAccess('positions'),
            AccessAPI.JustCheckAccess('positions/real'),
            UserAPI.GetUserById(props.user_id)
                .then(function (user) { return setUser(user); }),
            AdminPositionsAPI.GetRealPositionsForUser(props.user_id)
                .then(function (realPositions) {
                setRealPositions(realPositions);
            }),
        ])
            .then(function (_a) {
            var positionsAD = _a[0], realPositionsAD = _a[1];
            if (!positionsAD && !realPositionsAD) {
                location.href = '/';
            }
            setHasPositionsAD(positionsAD);
            setHasRealPositionsAD(realPositionsAD);
        });
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, [props.user_id]);
    function RemovePosition(evt, position_id) {
        evt.preventDefault();
        AdminPositionsAPI.RemovePosition(props.user_id, position_id)
            .finally(UpdateAll);
    }
    function RemoveRealPosition(evt, position_id) {
        evt.preventDefault();
        AdminPositionsAPI.RemoveRealPosition(props.user_id, position_id)
            .finally(UpdateAll);
    }
    return (React.createElement("div", { className: "admin-user-positions-page" },
        React.createElement("div", { className: "container" },
            React.createElement("h2", { className: "admin-user-positions-page__title" },
                React.createElement("span", { className: "material-symbols-outlined admin-user-positions-page__icon" }, "badge"),
                " \u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044F\u043C\u0438"),
            React.createElement(User, { user: user }),
            hasPositionsAD && React.createElement("h3", { className: "admin-user-positions-page__table-title" }, "\u0424\u0430\u043A\u0442\u0438\u0447\u0435\u0441\u043A\u0438\u0435 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438"),
            hasPositionsAD &&
                React.createElement("table", { className: "admin-user-positions-page__table" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438 (\u043A\u043B\u0430\u0441\u0441\u0430)"),
                            React.createElement("th", null, "\u041E\u0442\u0434\u0435\u043B/\u043A\u043E\u043C\u0430\u043D\u0434\u0430"),
                            React.createElement("th", null, "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F"),
                            React.createElement("th", { colSpan: 2 })),
                        user.position_objects.map(function (position) { return React.createElement("tr", null,
                            React.createElement("td", { style: { minWidth: 250, maxWidth: 500 } },
                                position.position_different_name || position.class_title,
                                " ",
                                position.position_different_name ? "(\u043A\u043B\u0430\u0441\u0441: ".concat(position.class_title, ")") : ''),
                            React.createElement("td", { style: { minWidth: 100, maxWidth: 300 } },
                                React.createElement(Link, { to: "/structure/".concat(position.team_id), className: "text-link" }, position.team_name)),
                            React.createElement("td", { style: { minWidth: 70, maxWidth: 200 } },
                                React.createElement(Link, { to: "/structure/".concat(position.organisation_main_team_id), className: "text-link" }, position.organisation_short_name)),
                            React.createElement("td", null,
                                React.createElement(Link, { to: "/admin/positions/".concat(props.user_id, "/").concat(position.id), className: "text-link" }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C")),
                            React.createElement("td", null,
                                React.createElement("span", { className: "text-link", onClick: function (evt) { return RemovePosition(evt, position.id); } }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))); }),
                        React.createElement("tr", null,
                            React.createElement("td", { colSpan: 5 },
                                React.createElement(Link, { to: "/admin/positions/".concat(props.user_id, "/new"), className: "text-link" }, "+ \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043D\u043E\u0432\u0443\u044E \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C"))))),
            hasRealPositionsAD && React.createElement("h3", { className: "admin-user-positions-page__table-title" }, "\u042E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u0438\u0435 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438"),
            hasRealPositionsAD && user.contract_type === 'ТД' &&
                React.createElement("table", { className: "admin-user-positions-page__table" },
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u044E\u0440.\u00A0\u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438 (\u043A\u043B\u0430\u0441\u0441\u0430)"),
                            React.createElement("th", null, "\u042E\u0440. \u043E\u0442\u0434\u0435\u043B/\u043A\u043E\u043C\u0430\u043D\u0434\u0430"),
                            React.createElement("th", null, "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F"),
                            React.createElement("th", null, "\u0414\u043E\u043B\u044F \u0437\u0430\u043D\u044F\u0442\u043E\u0441\u0442\u0438"),
                            React.createElement("th", null, "\u0421\u0442\u0430\u0432\u043A\u0430"),
                            React.createElement("th", null, "\u0418\u0442\u043E\u0433"),
                            React.createElement("th", { colSpan: 2 })),
                        realPositions.map(function (realPosition) { return React.createElement("tr", null,
                            React.createElement("td", { style: { minWidth: 250, maxWidth: 500 } },
                                realPosition.class_title,
                                React.createElement("br", null),
                                React.createElement("i", { style: { color: '#888' } }, realPosition.is_main_job ? '(основная)' : '(по совместительству)')),
                            React.createElement("td", { style: { minWidth: 100, maxWidth: 300 } }, realPosition.team_name),
                            React.createElement("td", { style: { minWidth: 70, maxWidth: 200 } },
                                React.createElement(Link, { to: "/structure/".concat(realPosition.organisation_main_team_id), className: "text-link" }, realPosition.organisation_short_name)),
                            React.createElement("td", { style: { width: 0 } },
                                realPosition.percentage,
                                "%"),
                            React.createElement("td", { style: { width: 0 } },
                                FormatMoney(realPosition.class_salary),
                                React.createElement("br", null),
                                realPosition.class_hours,
                                "\u00A0",
                                RussianNoun(realPosition.class_hours, ['час', 'часа', 'часов'])),
                            React.createElement("td", { style: { width: 0 } },
                                FormatMoney(realPosition.class_salary, { percentage: realPosition.percentage }),
                                React.createElement("br", null),
                                ApplyPercentage(realPosition.class_hours, realPosition.percentage, false),
                                "\u00A0",
                                RussianNoun(ApplyPercentage(realPosition.class_hours, realPosition.percentage, false), ['час', 'часа', 'часов'])),
                            React.createElement("td", null,
                                React.createElement(Link, { to: "/admin/positions/".concat(props.user_id, "/-").concat(realPosition.id), className: "text-link" }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C")),
                            React.createElement("td", null,
                                React.createElement("span", { className: "text-link", onClick: function (evt) { return RemoveRealPosition(evt, realPosition.id); } }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"))); }),
                        React.createElement("tr", null,
                            React.createElement("td", { colSpan: 8 },
                                React.createElement(Link, { to: "/admin/positions/".concat(props.user_id, "/newreal"), className: "text-link" }, "+ \u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043D\u043E\u0432\u0443\u044E \u044E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u0443\u044E \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C"))))),
            hasRealPositionsAD && user.contract_type !== 'ТД' && React.createElement("p", null, "\u0421\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A \u043D\u0430 \u0413\u041F\u0425"))));
}
