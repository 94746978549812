var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from "../../api";
var LoginAPI = /** @class */ (function (_super) {
    __extends(LoginAPI, _super);
    function LoginAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = LoginAPI;
    LoginAPI.IsLoggedIn = function () { return _a.BooleanQuery({
        url: 'request/login/IsLoggedIn/',
        publicQuery: true,
    }); };
    LoginAPI.Login = function (code) { return _a.AlwaysResolvingQuery({
        url: 'request/login/Login/',
        data: { code: code },
        dataCallback: function (data) { return data.status; },
        defaultValueCallback: function () { return 'request error'; },
        publicQuery: true,
    }); };
    LoginAPI.SendCode = function (login) { return _a.AlwaysResolvingQuery({
        url: 'request/login/SendCode/',
        data: { login: login },
        dataCallback: function (data) { return data.status; },
        defaultValueCallback: function () { return 'request error'; },
        publicQuery: true,
    }); };
    return LoginAPI;
}(API));
export default LoginAPI;
