export function IJobTemplate() {
    return {
        id: 0,
        creator_id: 0,
        class_id: 0,
        team_id: 0,
        organisation_id: 0,
        position_different_name: '',
        contract_type: '',
        job_title: '',
        job_short: '',
        job_description: '',
        class_title: '',
        team_name: '',
        organisation_short_name: '',
        quantity: 0,
        deleted: 0,
        filter: 1,
        watchers: [],
        watchers_objects: [],
    };
}
