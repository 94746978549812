import * as React from "react";
import { FileColor } from "../../../functions";
export default function FileItem(props) {
    var imageFormats = ['png', 'jpg', 'jpeg'];
    function GetFormat() {
        return props.url.split('.').reverse()[0].toLowerCase();
    }
    function IsImage() {
        return Boolean(~imageFormats.indexOf(GetFormat()));
    }
    return (React.createElement("div", { className: 'file-item ' + (props.remover ? '' : 'file-item--no-delete') },
        React.createElement("a", { href: props.url, className: "file-item__image", target: "_blank", style: IsImage() ? { backgroundImage: 'url("' + props.url + '")' } : {} },
            React.createElement("div", { className: "file-item__type", style: { backgroundColor: 'hsl(' + FileColor(props.url) + ' 21% 77%)' }, hidden: IsImage() },
                React.createElement("span", { className: "material-symbols-outlined" }, "description"),
                React.createElement("span", { className: "file-item__type-extension" }, props.url.split('.').reverse()[0].slice(0, 4))),
            React.createElement("div", { className: 'file-item__open ' + (IsImage() ? '' : 'file-item__open--for-file') },
                React.createElement("span", { className: "material-symbols-outlined" }, "open_in_new"))),
        props.name ? React.createElement("input", { type: "hidden", name: props.name + '[]', value: props.url }) : '',
        React.createElement("div", { onClick: function () { return (typeof props.remover === 'function' && props.remover(props.url)); }, hidden: typeof props.remover !== 'function', className: "file-item__delete" },
            React.createElement("span", { className: "material-symbols-outlined" }, "close"))));
}
