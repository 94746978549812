import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../functions";
import DocumentUploader from "../../components/DocumentUploader";
import DocumentsAPI from "../../api/request/documents/api";
export default function DocumentsPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState([]), documents = _b[0], setDocuments = _b[1];
    var _c = useState(''), citizenship = _c[0], setCitizenship = _c[1];
    // const [parents, setParents] = useState([]);
    function updateDocuments() {
        return DocumentsAPI.GetMyDocuments()
            .then(function (response) {
            setDocuments(response.documents);
            setCitizenship(response.citizenship);
        });
    }
    useEffect(function () {
        startLoading();
        updateDocuments()
            .finally(finishLoading);
    }, []);
    return (React.createElement("div", { className: "documents-page" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "documents-page__title" },
                React.createElement("span", { className: "material-symbols-outlined documents-page__icon" }, "folder_shared"),
                " \u041C\u043E\u0438 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B"),
            citizenship === 'Россия' ?
                documents.map(function (document) { return React.createElement(DocumentUploader, { document: document, updater: updateDocuments }); }) :
                React.createElement("div", { className: "documents-page__not-russian-citizen" },
                    "\u0412\u044B \u043D\u0435 \u044F\u0432\u043B\u044F\u0435\u0442\u0435\u0441\u044C \u0433\u0440\u0430\u0436\u0434\u0430\u043D\u0438\u043D\u043E\u043C \u0420\u043E\u0441\u0441\u0438\u0438, \u043F\u043E\u044D\u0442\u043E\u043C\u0443 \u0432\u0430\u0448\u0438 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B \u043F\u043E\u0442\u0440\u0435\u0431\u0443\u044E\u0442 \u0440\u0443\u0447\u043D\u043E\u0439 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438, \u043D\u0430\u043F\u0438\u0448\u0438\u0442\u0435, \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0432 \u043D\u0430\u0448\u0443 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443",
                    React.createElement("a", { href: "https://t.me/apo_help", className: "text-link" }, "@apo_help")))));
}
