import * as React from "react";
import { useContext, useEffect, useState } from "react";
import UserSelect from "../../../components/Form/UserSelect";
import { CreateFormDataFromObject, FormatMoney, GlobalContext } from '../../../functions';
import Payment from "../../../components/Payment";
import { GetLastMonths, GetNowMonth } from "../paymentsFunctions";
import { InputLabel, TextareaLabel } from "../../../components/Form/InputLabel";
import { IUserTemplate } from "../../../api/interfaces/entity/User";
import { AccessAPI } from "../../../api/request/access/api";
import TeamSelect from "../../../components/Form/TeamSelect";
export default function AdminCreatePaymentPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var defaultWork = { id: 0, price_rate: 0, item: 'другое' };
    var defaultUser = IUserTemplate({ first_name: 'Выберите сотрудника' });
    var _b = useState(defaultUser), currentUser = _b[0], setCurrentUser = _b[1];
    var _c = useState(1), currentQuantity = _c[0], setCurrentQuantity = _c[1];
    var _d = useState(defaultWork), currentWork = _d[0], setCurrentWork = _d[1];
    var _e = useState(1), currentUnit = _e[0], setCurrentUnit = _e[1];
    var _f = useState(GetNowMonth()), currentMonth = _f[0], setCurrentMonth = _f[1];
    var _g = useState([]), allWorks = _g[0], setAllWorks = _g[1];
    var _h = useState({}), allWorksDict = _h[0], setAllWorksDict = _h[1];
    var _j = useState([]), allUnits = _j[0], setAllUnits = _j[1];
    var _k = useState([]), lastPayments = _k[0], setLastPayments = _k[1];
    function UpdateLastPayments() {
        return Promise.all([
            AccessAPI.CheckAccess('payments/create'),
            fetch('/api/admin/payments/get-last-created-payments', { method: 'POST' })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    setLastPayments(response.payments);
                }
            }, function (error) {
            })
        ]);
    }
    useEffect(function () {
        startLoading();
        Promise.all([
            fetch('/api/admin/payments/get-create-template', { method: 'POST' })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    setAllWorks(response.works);
                    setAllWorksDict(response.works_dict);
                    setAllUnits(response.units);
                }
            }, function (error) {
            }),
            UpdateLastPayments()
        ])
            .finally(finishLoading);
    }, []);
    function UseTemplate(payment) {
        setCurrentUser(payment.user_object);
        setCurrentWork({
            id: payment.work_id,
            price_rate: payment.work_price_rate,
            item: payment.work_item
        });
        setCurrentQuantity(payment.quantity);
        setCurrentUnit(payment.unit_id);
        setCurrentMonth(payment.month);
        var orderInput = document.querySelector('textarea[name="order_text"]');
        orderInput.value = payment.order_text || '';
        var commentInput = document.querySelector('textarea[name="comment_text"]');
        commentInput.value = payment.comment_text || '';
        var dateStartInput = document.querySelector('input[name="date_start"]');
        dateStartInput.value = payment.date_start || '';
        var dateEndInput = document.querySelector('input[name="date_end"]');
        dateEndInput.value = payment.date_end || '';
    }
    var _l = useState(''), userError = _l[0], setUserError = _l[1];
    var _m = useState(''), workError = _m[0], setWorkError = _m[1];
    var _o = useState(''), quantityError = _o[0], setQuantityError = _o[1];
    var _p = useState(''), dateError = _p[0], setDateError = _p[1];
    var _q = useState(false), pendingSubmit = _q[0], setPendingSubmit = _q[1];
    function SubmitPaymentCreation(evt) {
        evt.preventDefault();
        setUserError('');
        setWorkError('');
        setQuantityError('');
        setDateError('');
        var valid = true;
        var userIdInput = document.querySelector('.admin-payment-page__form [name="user_id"]');
        var dateStartInput = document.querySelector('.admin-payment-page__form [name="date_start"]');
        var dateEndInput = document.querySelector('.admin-payment-page__form [name="date_end"]');
        if (userIdInput.value === '0') {
            setUserError('Выберите получателя выплаты');
            valid = false;
        }
        if (currentWork.id === 0) {
            setWorkError('Выберите вид работы');
            valid = false;
        }
        if (typeof currentQuantity !== 'number') {
            setQuantityError('Нужно числом');
            valid = false;
        }
        else if (currentQuantity <= 0) {
            setQuantityError('Должно быть больше нуля');
            valid = false;
        }
        else if (currentQuantity % 1 !== 0) {
            setQuantityError('Должно быть целым');
            valid = false;
        }
        if (!dateStartInput.value || !dateEndInput.value) {
            setDateError('Введите даты');
            valid = false;
        }
        else if (dateStartInput.value > dateEndInput.value) {
            setDateError('Дата конца не должна быть раньше даты начала');
            valid = false;
        }
        if (valid) {
            setPendingSubmit(true);
            fetch('/api/admin/payments/create-payment', {
                method: 'POST',
                body: new FormData(document.querySelector('.admin-payment-page__form')),
            })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                UpdateLastPayments();
            }, function (error) {
            })
                .finally(function () {
                ClearForm();
                setPendingSubmit(false);
            });
        }
    }
    function ClearForm() {
        setUserError('');
        setWorkError('');
        setQuantityError('');
        setDateError('');
        var dateStartInput = document.querySelector('.admin-payment-page__form [name="date_start"]');
        var dateEndInput = document.querySelector('.admin-payment-page__form [name="date_end"]');
        var orderInput = document.querySelector('textarea[name="order_text"]');
        var commentInput = document.querySelector('textarea[name="comment_text"]');
        dateStartInput.value = '';
        dateEndInput.value = '';
        orderInput.value = '';
        commentInput.value = '';
        setCurrentMonth(GetNowMonth());
        setCurrentQuantity(1);
        setCurrentUnit(1);
        setCurrentWork(defaultWork);
        setCurrentUser(defaultUser);
    }
    function ClickDeleteButton(id) {
        return function (evt) {
            evt.preventDefault();
            evt.target.disabled = true;
            DeletePayment(id)
                .finally(function () { return evt.target.disabled = false; });
        };
    }
    function DeletePayment(id) {
        return fetch('/api/admin/payments/delete-payment', {
            method: 'POST',
            body: CreateFormDataFromObject({ id: id }),
        })
            .then(function (res) { return res.json(); })
            .then(function (response) {
            UpdateLastPayments();
        }, function (error) {
        });
    }
    return (React.createElement("div", { className: "admin-payment-page" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "admin-payment-page__layout" },
                React.createElement("div", { className: "admin-payment-page__left-side" },
                    React.createElement("h2", { className: "admin-payment-page__title" },
                        React.createElement("span", { className: "material-symbols-outlined" }, "add_card"),
                        " \u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0432\u044B\u043F\u043B\u0430\u0442\u0443"),
                    React.createElement("form", { className: "admin-payment-page__form", onSubmit: SubmitPaymentCreation },
                        React.createElement("span", null, "\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C"),
                        React.createElement(UserSelect, { name: "user_id", default: currentUser }),
                        React.createElement("span", { className: "error-text", hidden: !userError }, userError),
                        React.createElement("label", null,
                            React.createElement("span", null, "\u041A\u0430\u0444\u0435\u0434\u0440\u0430 (\u043F\u0440\u0438 \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E\u0441\u0442\u0438)"),
                            React.createElement("span", { className: "form-item-wrapper" },
                                React.createElement("select", { name: "unit_id", value: currentUnit, onChange: function (evt) { return setCurrentUnit(parseInt(evt.target['value'], 10)); } }, allUnits.map(function (unit) { return React.createElement("option", { value: unit.id }, unit.title); })))),
                        React.createElement(TeamSelect, { teamName: "team_id" }),
                        React.createElement("label", { className: workError ? 'error' : '' },
                            React.createElement("span", null, "\u0412\u0438\u0434 \u0440\u0430\u0431\u043E\u0442\u044B"),
                            React.createElement("span", { className: "form-item-wrapper" },
                                React.createElement("select", { name: "work_id", value: currentWork['id'], onChange: function (evt) { return setCurrentWork(allWorksDict[evt.target['value']]); } },
                                    React.createElement("option", { value: "0", selected: true, disabled: true, hidden: true }, "(\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0438\u0434 \u0440\u0430\u0431\u043E\u0442\u044B)"),
                                    allWorks.map(function (work) { return React.createElement("option", { value: work.id }, work.work_name); }))),
                            React.createElement("span", { className: "error-text", hidden: !workError }, workError)),
                        React.createElement("div", { className: "flex-buttons", style: { alignItems: 'flex-start' } },
                            React.createElement(InputLabel, { title: "\u0421\u0442\u0430\u0432\u043A\u0430", disabled: true, value: currentWork.item === 'другое' ?
                                    "".concat(FormatMoney(currentWork.price_rate, { noCurrency: true }), " \u0440.") :
                                    "".concat(FormatMoney(currentWork.price_rate, { noCurrency: true }), " \u0440./").concat(currentWork.item) }),
                            React.createElement("span", { style: { transform: "translateY(33px)" } }, "\u00D7"),
                            React.createElement(InputLabel, { title: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E", type: "number", name: "quantity", value: currentQuantity, onInput: function (evt) { return setCurrentQuantity(parseInt(evt.target['value'], 10)); }, error: quantityError })),
                        React.createElement("div", { className: "admin-payment-page__final-sum" }, FormatMoney(currentQuantity * currentWork.price_rate)),
                        React.createElement(TextareaLabel, { title: "\u041F\u0440\u0438\u043A\u0430\u0437", name: "order_text" }),
                        React.createElement(TextareaLabel, { title: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439", name: "comment_text" }),
                        React.createElement("label", { className: dateError ? 'error' : '' },
                            React.createElement("span", null, "\u041F\u0435\u0440\u0438\u043E\u0434 \u0440\u0430\u0431\u043E\u0442"),
                            React.createElement("span", { className: "flex-buttons" },
                                React.createElement("input", { type: "date", name: "date_start" }),
                                React.createElement("span", null, "\u2014"),
                                React.createElement("input", { type: "date", name: "date_end" })),
                            React.createElement("span", { className: "error-text", hidden: !dateError }, dateError)),
                        React.createElement("label", null,
                            React.createElement("span", null, "\u041E\u0442\u0447\u0435\u0442\u043D\u044B\u0439 \u043C\u0435\u0441\u044F\u0446"),
                            React.createElement("span", { className: "form-item-wrapper" },
                                React.createElement("select", { name: "month", value: currentMonth, onChange: function (evt) { return setCurrentMonth(evt.target['value']); } }, GetLastMonths().map(function (month) { return React.createElement("option", { value: month[0] }, month[1]); })))),
                        React.createElement("button", { disabled: pendingSubmit }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0432\u044B\u043F\u043B\u0430\u0442\u0443"))),
                React.createElement("div", { className: "admin-payment-page__right-side" },
                    React.createElement("h2", { className: "admin-payment-page__title" },
                        React.createElement("span", { className: "material-symbols-outlined" }, "analytics"),
                        " \u0412\u0430\u0448\u0438 \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0438\u0435 \u0432\u044B\u043F\u043B\u0430\u0442\u044B"),
                    React.createElement("div", { className: "admin-payment-page__last-payments" }, lastPayments.map(function (payment) { return React.createElement(Payment, { payment: payment },
                        React.createElement("div", { className: "form-button form-button--grey", onClick: function () { return UseTemplate(payment); } }, "\u041A\u0430\u043A \u0448\u0430\u0431\u043B\u043E\u043D"),
                        React.createElement("div", { className: "form-button form-button--red", hidden: payment.status !== 'created' && payment.status !== 'rejected', onClick: ClickDeleteButton(payment.id) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")); })),
                    React.createElement("span", { hidden: Boolean(lastPayments.length) }, "\u0412\u044B \u043F\u043E\u043A\u0430 \u043D\u0435 \u0434\u0435\u043B\u0430\u043B\u0438 \u0432\u044B\u043F\u043B\u0430\u0442"))))));
}
