export function IUserTemplate(object) {
    var template = {
        id: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        avatar: '/img/man-avatar.png',
        raw_avatar: null,
        gender: 1,
        tg_username: '',
        tg_username_original_case: '',
        vk_nickname: '',
        email: '',
        city: '',
        university: '',
        contract_type: '—',
        phone: '',
        positions: [],
        in_company_since: '',
        documents_ok: 0,
        deleted: 0,
        hidden: 0,
        is_ex: 0,
        is_signed_up: 0,
        birthday: '01-01',
        is_birthday: 0,
        date_of_birth: '1900-01-01',
        position_objects: [],
        organization_ids: [],
        team_ids: [],
        position_class_ids: [],
        sorter: 0,
    };
    for (var key in object) {
        if (object.hasOwnProperty(key)) {
            template[key] = object[key];
        }
    }
    return template;
}
