var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from "../../api";
var DocumentsAPI = /** @class */ (function (_super) {
    __extends(DocumentsAPI, _super);
    function DocumentsAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = DocumentsAPI;
    DocumentsAPI.GetMyDocuments = function () { return _a.RejectingOnErrorUnchangedQuery({
        url: 'request/documents/GetMyDocuments/',
    }); };
    DocumentsAPI.GetBadDocumentsCount = function () { return _a.AlwaysResolvingQuery({
        url: 'request/documents/GetBadDocumentsCount/',
        dataCallback: function (data) { return data.count; },
        defaultValueCallback: function () { return 0; },
    }); };
    DocumentsAPI.RemoveUploadedFile = function (classId, filename) { return _a.VoidQuery({
        url: 'request/documents/RemoveUploadedFile/',
        data: {
            class_id: classId,
            filename: filename,
        }
    }); };
    DocumentsAPI.SetTexts = function (form, onError) { return _a.VoidQuery({
        url: 'request/documents/SetTexts/',
        data: form,
        onError: typeof onError === 'function' ? onError : function () { },
    }); };
    DocumentsAPI.UploadFile = function (form, onError) { return _a.VoidQuery({
        url: 'request/documents/UploadFiles/',
        data: form,
        onError: typeof onError === 'function' ? onError : function () { },
    }); };
    return DocumentsAPI;
}(API));
export default DocumentsAPI;
