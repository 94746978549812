import * as React from "react";
import { RussianNoun } from "../../functions";
export default function Job(props) {
    function GetQuantityText() {
        if (!props.full) {
            return '';
        }
        if (props.job.deleted === 1) {
            return 'вакансия удалена';
        }
        if (props.job.quantity === 0) {
            return 'вакансия закрыта';
        }
        if (props.job.quantity === -1) {
            return 'безлимитная';
        }
        return "".concat(props.job.quantity, " ").concat(RussianNoun(props.job.quantity, ['место', 'места', 'мест']));
    }
    return (React.createElement("div", { className: 'job-component ' + (props.children ? '' : ' job-component--no-buttons ')
            + (props.noDescription ? ' job-component--no-description ' : '') },
        React.createElement("div", { className: "job-component__title" },
            props.job.job_title,
            " ",
            React.createElement("span", { className: "job-component__quantity" }, GetQuantityText())),
        React.createElement("div", { className: "job-component__place" },
            props.job.organisation_short_name,
            ", ",
            props.job.team_name),
        React.createElement("div", { className: "job-component__description", hidden: props.noDescription || !props.job.job_description, dangerouslySetInnerHTML: { __html: props.job.job_short } }),
        props.full ?
            React.createElement("div", { className: "job-component__props" },
                React.createElement("div", { className: "job-component__prop" },
                    React.createElement("span", { className: "job-component__prop-title" }, "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F:"),
                    React.createElement("span", { className: "job-component__prop-text" }, props.job.organisation_short_name)),
                React.createElement("div", { className: "job-component__prop" },
                    React.createElement("span", { className: "job-component__prop-title" }, "\u041A\u043E\u043C\u0430\u043D\u0434\u0430/\u043E\u0442\u0434\u0435\u043B:"),
                    React.createElement("span", { className: "job-component__prop-text" }, props.job.team_name)),
                React.createElement("div", { className: "job-component__prop" },
                    React.createElement("span", { className: "job-component__prop-title" }, "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C:"),
                    React.createElement("span", { className: "job-component__prop-text" }, props.job.position_different_name || props.job.class_title)),
                React.createElement("div", { className: "job-component__prop" },
                    React.createElement("span", { className: "job-component__prop-title" }, "\u0422\u0438\u043F \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u0430:"),
                    React.createElement("span", { className: "job-component__prop-text" }, props.job.contract_type))) : '',
        React.createElement("div", { className: "job-component__buttons" }, props.children)));
}
