import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { FormError } from "../../components/Form";
import { InputLabelWithRef, TextareaLabelWithRef } from "../../components/Form/InputLabel";
import { SelectLabel } from "../../components/Form/SelectLabel";
import TeamSelect from "../../components/Form/TeamSelect";
import PositionClassSelectWithRef from "../../components/Form/PositionClassSelect";
import { AlternativeNameInputWithRef } from "../../components/Form/AlternativeNameInput";
import { CheckValue, CheckValueNotEmpty, ReduceStatuses } from "../../components/Form/Checkers";
import { AccessAPI } from "../../api/request/access/api";
import TextEditor from "../../components/Form/TextEditor";
import { JobsAdminAPI } from "../../api/admin/jobs/api";
import JobFilter from "../../components/Form/JobFilter";
export default function CreateJobForm(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var _j = useState([]), organisations = _j[0], setOrganisations = _j[1];
    var _k = useState(false), pending = _k[0], setPending = _k[1];
    var _l = useState(''), titleError = _l[0], setTitleError = _l[1];
    var _m = useState(''), quantityError = _m[0], setQuantityError = _m[1];
    var _o = useState(''), serverError = _o[0], setServerError = _o[1];
    var _p = useState(''), updateTextarea = _p[0], setUpdateTextarea = _p[1];
    var _q = useState('ГПХ'), currentContractType = _q[0], setCurrentContractType = _q[1];
    var _r = useState(0), currentJobFilter = _r[0], setCurrentJobFilter = _r[1];
    function UpdateTemplate() {
        return Promise.all([
            AccessAPI.CheckAccess('jobs/create'),
            JobsAdminAPI.Creator.GetLoggedUserOrganisations()
                .then(function (organisationIds) { return setOrganisations(organisationIds); }),
        ]);
    }
    useEffect(function () {
        UpdateTemplate();
    }, []);
    var jobTitleRef = useRef();
    var jobShortRef = useRef();
    var positionDifferentRef = useRef();
    function ClearTextarea() {
        setUpdateTextarea(' ');
        queueMicrotask(function () { return setUpdateTextarea(''); });
    }
    function SubmitCreation(evt) {
        evt.preventDefault();
        setTitleError('');
        setQuantityError('');
        setServerError('');
        var statuses = [];
        statuses.push(CheckValueNotEmpty('job_title', 'Введите заголовок вакансии', setTitleError, evt.target));
        statuses.push(CheckValueNotEmpty('quantity', 'Введите количество мест', setQuantityError, evt.target));
        statuses.push(CheckValue('quantity', 'Количество мест должно быть не меньше -1', function (val) { return parseInt(val, 10) >= -1; }, setQuantityError, evt.target));
        if (!ReduceStatuses(statuses)) {
            return;
        }
        setPending(true);
        (props.jobToEdit ? JobsAdminAPI.Creator.Job.Update : JobsAdminAPI.Creator.Job.Create)(evt.target)
            .then(function () {
            jobTitleRef.current.value = '';
            jobShortRef.current.value = '';
            ClearTextarea();
            positionDifferentRef.current.value = '';
        })
            .finally(function () {
            if (props.modalCloser) {
                props.modalCloser();
            }
            setPending(false);
            props.updater();
        });
    }
    var quantityRef = useRef();
    useEffect(function () {
        var _a;
        if (props.jobToEdit && quantityRef) {
            quantityRef.current.value = (_a = props.jobToEdit.quantity) !== null && _a !== void 0 ? _a : '1';
        }
        if (props.jobToEdit) {
            setCurrentJobFilter(props.jobToEdit.filter);
        }
    }, [props.jobToEdit]);
    return (React.createElement("form", { className: "jobs-admin-page__form", onSubmit: SubmitCreation },
        React.createElement("h3", { className: "jobs-admin-page__subtitle" }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u0434\u043B\u044F\u00A0\u0441\u043E\u0438\u0441\u043A\u0430\u0442\u0435\u043B\u0435\u0439"),
        React.createElement("p", { className: "jobs-admin-page__block-description" }, "\u041F\u043E\u043B\u044F \u0438\u0437\u00A0\u044D\u0442\u043E\u0433\u043E \u0431\u043B\u043E\u043A\u0430 \u0431\u0443\u0434\u0443\u0442 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0442\u044C\u0441\u044F \u0441\u043E\u0438\u0441\u043A\u0430\u0442\u0435\u043B\u044F\u043C"),
        Boolean(props.jobToEdit) && React.createElement("input", { type: "hidden", value: props.jobToEdit.id, name: "id" }),
        React.createElement(TextareaLabelWithRef, { title: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438", name: "job_title", error: titleError, ref: jobTitleRef, defaultValue: (_a = props === null || props === void 0 ? void 0 : props.jobToEdit) === null || _a === void 0 ? void 0 : _a.job_title }),
        React.createElement(TextareaLabelWithRef, { title: "\u041A\u043E\u0440\u043E\u0442\u043A\u043E\u0435 \u043E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438", defaultValue: (_b = props === null || props === void 0 ? void 0 : props.jobToEdit) === null || _b === void 0 ? void 0 : _b.job_short, name: "job_short", ref: jobShortRef, annotation: "\u0418\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u0442\u0441\u044F \u0432 \u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0435 \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0435 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0439" }),
        React.createElement("span", null, "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438"),
        React.createElement(TextEditor, { inputName: "job_description", defaultValue: (_d = (_c = props === null || props === void 0 ? void 0 : props.jobToEdit) === null || _c === void 0 ? void 0 : _c.job_description) !== null && _d !== void 0 ? _d : updateTextarea, moreOptions: true }),
        React.createElement("span", { className: "annotation" }, "\u041E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u043D\u0430 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0435 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438"),
        React.createElement(SelectLabel, { title: "\u0422\u0438\u043F \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u0430", name: "contract_type", options: [['ТД', 'ТД'], ['ГПХ', 'ГПХ']], value: currentContractType, valueSetter: setCurrentContractType, defaultValue: (_e = props === null || props === void 0 ? void 0 : props.jobToEdit) === null || _e === void 0 ? void 0 : _e.contract_type }),
        React.createElement(JobFilter, { value: currentJobFilter, valueSetter: function (val) { return setCurrentJobFilter(parseInt(val, 10)); }, name: "filter", noAny: true }),
        React.createElement("hr", null),
        React.createElement("h3", { className: "jobs-admin-page__subtitle" }, "\u0422\u0435\u0445\u043D\u0438\u0447\u0435\u0441\u043A\u0430\u044F \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F"),
        React.createElement("p", { className: "jobs-admin-page__block-description" }, "\u041F\u043E\u043B\u044F \u0438\u0437\u00A0\u044D\u0442\u043E\u0433\u043E \u0431\u043B\u043E\u043A\u0430 \u043F\u0440\u0438\u0433\u043E\u0434\u044F\u0442\u0441\u044F \u043F\u0440\u0438\u00A0\u0441\u043E\u0437\u0434\u0430\u043D\u0438\u0438 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0430, \u0430\u00A0\u0442\u0430\u043A\u0436\u0435 \u0435\u0433\u043E\u00A0\u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438 \u0432\u00A0\u0421\u0443\u043F\u0435\u0440\u0430\u043F\u043F\u0435"),
        React.createElement(InputLabelWithRef, { title: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043C\u0435\u0441\u0442", type: "number", name: "quantity", annotation: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 -1, \u0435\u0441\u043B\u0438 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044F \u0431\u0435\u0437\u043B\u0438\u043C\u0438\u0442\u043D\u0430<br/>\n                                           \u0412\u0432\u0435\u0434\u0438\u0442\u0435 0, \u0447\u0442\u043E\u0431\u044B \u0441\u043E\u0437\u0434\u0430\u0442\u044C \u0437\u0430\u043A\u0440\u044B\u0442\u0443\u044E \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E", error: quantityError, defaultValue: "1", ref: quantityRef }),
        React.createElement(TeamSelect, { organisationName: "organisation_id", teamName: "team_id", organisationsFilter: organisations, defaultValue: (_f = props === null || props === void 0 ? void 0 : props.jobToEdit) === null || _f === void 0 ? void 0 : _f.team_id }),
        React.createElement(PositionClassSelectWithRef, { name: "class_id", defaultValue: (_g = props === null || props === void 0 ? void 0 : props.jobToEdit) === null || _g === void 0 ? void 0 : _g.class_id }),
        React.createElement(AlternativeNameInputWithRef, { name: "position_different_name", ref: positionDifferentRef, defaultValue: (_h = props === null || props === void 0 ? void 0 : props.jobToEdit) === null || _h === void 0 ? void 0 : _h.position_different_name }),
        React.createElement("button", { disabled: pending }, props.jobToEdit ? 'Сохранить изменения' : 'Создать вакансию'),
        React.createElement(FormError, { text: serverError })));
}
