import * as React from "react";
export default function Attention(props) {
    if (props.status === undefined) {
        props.status = 'error';
    }
    var icons = {
        'error': 'emergency_home',
        'warning': 'warning',
        'feature': 'rocket_launch',
        'info': 'info',
        'good': 'task_alt',
    };
    return (React.createElement("div", { className: 'attention-component ' + (props.status === 'warning' ? 'attention-component--warning ' : '')
            + (props.status === 'feature' ? 'attention-component--feature ' : '')
            + (props.status === 'info' ? 'attention-component--info ' : '')
            + (props.status === 'good' ? 'attention-component--good ' : ''), style: props.style, hidden: props.hidden },
        React.createElement("div", { className: "attention-component__icon" },
            React.createElement("span", { className: "material-symbols-outlined" }, icons[props.status])),
        React.createElement("div", { className: "attention-component__text" }, props.children)));
}
