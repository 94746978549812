import * as React from "react";
import { GetArraysIntersection } from "../../../functions";
import { Link } from "react-router-dom";
export default function AdminButtonsBlock(props) {
    return (React.createElement("div", { className: "user-page__info-block", hidden: !GetArraysIntersection(props.adminPages, ['positions', 'positions/real', 'ad_groups']).length },
        React.createElement("h2", { className: "user-page__info-title user-page__info-title--admin-buttons" }, "\u0410\u0434\u043C\u0438\u043D\u0441\u043A\u0438\u0435 \u043A\u043D\u043E\u043F\u043A\u0438"),
        React.createElement(Link, { to: '/admin/positions/' + props.user.id, hidden: !GetArraysIntersection(props.adminPages, ['positions', 'positions/real']).length },
            React.createElement("button", { className: "user-page__button" }, "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044F\u043C\u0438")),
        React.createElement(Link, { to: '/admin/setadgroups/' + props.user.id, hidden: !~props.adminPages.indexOf('ad_groups') },
            React.createElement("button", { className: "user-page__button" }, "\u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 AD-\u0433\u0440\u0443\u043F\u043F\u0430\u043C\u0438"))));
}
