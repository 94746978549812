import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { SelectLabel, SelectLabelWithRef } from "../SelectLabel";
import { ComponentsAPI } from "../../../api/request/components/api";
export default function RealTeamSelect(props) {
    var _a, _b;
    var _c = useState(0), currentOrganisation = _c[0], setCurrentOrganisation = _c[1];
    var _d = useState([]), organisations = _d[0], setOrganisations = _d[1];
    var _e = useState([]), teams = _e[0], setTeams = _e[1];
    var _f = useState({}), teamsDict = _f[0], setTeamsDict = _f[1];
    var teamSelectRef = useRef();
    useEffect(function () {
        ComponentsAPI.GetRealTeams()
            .then(function (response) {
            setOrganisations(response.organisations);
            var teams = response.real_teams.sort(function (lhs, rhs) { return lhs.name < rhs.name ? -1 : 1; });
            setTeamsDict(response.real_teams_dict);
            for (var _i = 0, teams_1 = teams; _i < teams_1.length; _i++) {
                var team = teams_1[_i];
                team.public_name =
                    "".concat(team.name, " ").concat(team.name === response.organisations_dict[team.organisation_id].short_name ? '(главный отдел)' : '');
            }
            setTeams(teams);
            setCurrentOrganisation(props.defaultValue ?
                response.real_teams_dict[props.defaultValue].organisation_id :
                (props.organisationsFilter ?
                    props.organisationsFilter[0] :
                    response.organisations[0].id));
            if (props.defaultValue && teamSelectRef.current) {
                teamSelectRef.current.value = String(props.defaultValue);
            }
        });
    }, []);
    useEffect(function () {
        var _a, _b;
        console.log(props.organisationsFilter);
        setCurrentOrganisation(props.defaultValue ?
            (_a = teamsDict[props.defaultValue]) === null || _a === void 0 ? void 0 : _a.organisation_id :
            (props.organisationsFilter ?
                props.organisationsFilter[0] :
                (_b = organisations[0]) === null || _b === void 0 ? void 0 : _b.id));
        if (props.defaultValue && teamSelectRef.current) {
            teamSelectRef.current.value = String(props.defaultValue);
        }
    }, [props.organisationsFilter, props.defaultValue]);
    function GetOptions() {
        return teams
            .filter(function (realTeam) { return realTeam.organisation_id === currentOrganisation; })
            .map(function (realTeam) { return [+realTeam.id, realTeam.public_name]; });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectLabel, { title: "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F", name: (_a = props.organisationName) !== null && _a !== void 0 ? _a : '', value: currentOrganisation, valueSetter: function (val) { return setCurrentOrganisation(Number(val)); }, options: organisations
                .filter(function (org) {
                if (!props.organisationsFilter) {
                    return true;
                }
                return Boolean(~props.organisationsFilter.indexOf(org.id));
            })
                .map(function (org) { return [org.id, org.short_name]; }) }),
        React.createElement(SelectLabelWithRef, { title: "\u042E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u0430\u044F \u043A\u043E\u043C\u0430\u043D\u0434\u0430/\u043E\u0442\u0434\u0435\u043B", name: (_b = props.teamName) !== null && _b !== void 0 ? _b : '', options: GetOptions(), defaultValue: props.defaultValue, ref: teamSelectRef, key: currentOrganisation })));
}
