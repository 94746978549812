var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AdminAPI } from "../api";
var AdminPositionsAPI = /** @class */ (function (_super) {
    __extends(AdminPositionsAPI, _super);
    function AdminPositionsAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = AdminPositionsAPI;
    AdminPositionsAPI.AddPosition = function (form) { return _a.VoidQuery({
        url: 'admin/positions/AddPosition/',
        data: form,
    }); };
    AdminPositionsAPI.RemovePosition = function (user_id, position_id) { return _a.VoidQuery({
        url: 'admin/positions/RemovePosition/',
        data: {
            user_id: user_id,
            position_id: position_id,
        },
    }); };
    AdminPositionsAPI.UpdatePosition = function (form) { return _a.VoidQuery({
        url: 'admin/positions/UpdatePosition/',
        data: form,
    }); };
    AdminPositionsAPI.GetRealPositionsForUser = function (userId) { return _a.AlwaysResolvingQuery({
        url: 'admin/positions/GetRealPositionsForUser/',
        data: { user_id: userId },
        dataCallback: function (data) { return data.real_positions; },
        defaultValueCallback: function () { return []; },
    }); };
    AdminPositionsAPI.GetRealPositionClasses = function () { return _a.AlwaysResolvingQuery({
        url: 'admin/positions/GetRealPositionClasses/',
        dataCallback: function (data) { return data.real_position_classes; },
        defaultValueCallback: function () { return []; },
    }); };
    AdminPositionsAPI.AddRealPosition = function (form) { return _a.VoidQuery({
        url: 'admin/positions/AddRealPosition/',
        data: form,
    }); };
    AdminPositionsAPI.UpdateRealPosition = function (form) { return _a.VoidQuery({
        url: 'admin/positions/UpdateRealPosition/',
        data: form,
    }); };
    AdminPositionsAPI.RemoveRealPosition = function (user_id, position_id) { return _a.VoidQuery({
        url: 'admin/positions/RemoveRealPosition/',
        data: {
            user_id: user_id,
            position_id: position_id,
        },
    }); };
    return AdminPositionsAPI;
}(AdminAPI));
export { AdminPositionsAPI };
