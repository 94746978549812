// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draft-editor {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.editor-wrapper {
    border: 1px solid #c4cdd5;
}

.message-toolbar {
    border-bottom: 1px solid #c4cdd5;
    margin-bottom: 0;
    padding: 6px 5px;
}

.message-editor {
    height: 250px;
    resize: vertical;
    font-size: 16px;
    padding: 0 10px;
}

.rdw-option-wrapper {
    min-width: 35px;
    height: 30px;
}

.rdw-link-modal-target-option {
    display: block;
    margin: 3px 0 7px;
}

.rdw-link-modal-label {
    margin: 0 0 3px;
}

.rdw-link-modal-input {
    margin-bottom: 8px;
}

.rdw-link-modal {
    padding: 5px 10px;
    height: 225px;
}

.rdw-link-modal-buttonsection {
    margin: 0;
}

.rdw-link-modal-btn {
    margin-left: 0;
    margin-right: 10px;
}

.html-output {
    border: 1px solid silver;
    padding: 20px;
    background-color: #fafafa;
}
`, "",{"version":3,"sources":["webpack://./components/Form/TextEditor/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".draft-editor {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n}\n\n.editor-wrapper {\n    border: 1px solid #c4cdd5;\n}\n\n.message-toolbar {\n    border-bottom: 1px solid #c4cdd5;\n    margin-bottom: 0;\n    padding: 6px 5px;\n}\n\n.message-editor {\n    height: 250px;\n    resize: vertical;\n    font-size: 16px;\n    padding: 0 10px;\n}\n\n.rdw-option-wrapper {\n    min-width: 35px;\n    height: 30px;\n}\n\n.rdw-link-modal-target-option {\n    display: block;\n    margin: 3px 0 7px;\n}\n\n.rdw-link-modal-label {\n    margin: 0 0 3px;\n}\n\n.rdw-link-modal-input {\n    margin-bottom: 8px;\n}\n\n.rdw-link-modal {\n    padding: 5px 10px;\n    height: 225px;\n}\n\n.rdw-link-modal-buttonsection {\n    margin: 0;\n}\n\n.rdw-link-modal-btn {\n    margin-left: 0;\n    margin-right: 10px;\n}\n\n.html-output {\n    border: 1px solid silver;\n    padding: 20px;\n    background-color: #fafafa;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
