import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import FileUploader from '../../components/Form/FileUploader';
import Attention from '../../components/Attention';
import { GlobalContext } from '../../functions';
import { FormError, FormSuccess } from '../../components/Form';
import UserAPI from '../../api/request/user/api';
export default function AvatarForm(props) {
    var headerUpdateUser = useContext(GlobalContext).headerUpdateUser;
    var _a = useState(false), pending = _a[0], setPending = _a[1];
    var _b = useState(''), success = _b[0], setSuccess = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    var _d = useState(0), key = _d[0], setKey = _d[1];
    useEffect(function () { setKey(key + 1); }, [props.user]);
    function Submit(evt) {
        evt.preventDefault();
        setPending(true);
        UserAPI.SetAvatar(evt.target)
            .then(function () {
            headerUpdateUser();
            setSuccess('Аватар сохранен');
        }, function () { return setError('Какая-то ошибка при загрузке аватара'); })
            .finally(function () {
            props.updater()
                .finally(function () { return setPending(false); });
        });
    }
    return (React.createElement("form", { onSubmit: Submit, key: key },
        React.createElement("h3", null, "\u0410\u0432\u0430\u0442\u0430\u0440"),
        React.createElement(FileUploader, { name: "avatar", accept: "image/*", maxSize: 1, initFiles: props.user.raw_avatar === null || props.user.raw_avatar === '' ? [] : [props.user.raw_avatar] }),
        React.createElement("button", { disabled: pending }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
        React.createElement(FormError, { text: error }),
        React.createElement(FormSuccess, { text: success }),
        React.createElement(Attention, { status: "feature" },
            "\u0410\u00A0\u0435\u0449\u0435 \u0430\u0432\u0430\u0442\u0430\u0440 \u043C\u043E\u0436\u043D\u043E \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0438\u0437\u00A0\u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C\u0430, \u043D\u0430\u043F\u0438\u0441\u0430\u0432 /avatar \u0432\u00A0",
            React.createElement("a", { className: "text-link", href: "https://t.me/cpm_superapp_bot" }, "\u0431\u043E\u0442\u0435"),
            " :)",
            React.createElement("br", null),
            " (\u0440\u0430\u0431\u043E\u0442\u0430\u0435\u0442, \u0435\u0441\u043B\u0438 \u0432\u0430\u0448\u00A0\u0430\u0432\u0430\u0442\u0430\u0440 \u0432\u00A0\u043E\u0431\u0449\u0435\u043C \u0434\u043E\u0441\u0442\u0443\u043F\u0435)")));
}
