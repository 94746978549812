import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from '../../functions';
import Job from "../../components/Job";
import CreateJobForm from "./CreateJobForm";
import { AccessAPI } from "../../api/request/access/api";
import Modal from "../../components/Modal";
import { JobsAdminAPI } from "../../api/admin/jobs/api";
import UserSelect from "../../components/Form/UserSelect";
import User from "../../components/User";
import { FormError } from "../../components/Form";
export default function AdminJobsPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState('opened'), currentFilter = _b[0], setCurrentFilter = _b[1];
    var _c = useState([]), lastJobs = _c[0], setLastJobs = _c[1];
    var _d = useState(false), watcherModalVisible = _d[0], setWatcherModalVisible = _d[1];
    var _e = useState(false), transferModalVisible = _e[0], setTransferModalVisible = _e[1];
    var _f = useState(''), transferError = _f[0], setTransferError = _f[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('jobs/create'),
            JobsAdminAPI.Creator.GetJobs()
                .then(function (jobs) { return setLastJobs(jobs); }),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, []);
    function FilterJobs(job) {
        return Boolean(+(job.quantity === 0) ^ +(currentFilter === 'opened'));
    }
    function GetButtonActionCallBack(queryFunction, jobObject) {
        return function (evt) {
            evt.target.disabled = true;
            queryFunction(jobObject)
                .finally(function () {
                UpdateAll();
                evt.target.disabled = false;
            });
        };
    }
    function AddQuantity(job) {
        return GetButtonActionCallBack(JobsAdminAPI.Creator.Quantity.Add, { job_id: job.id });
    }
    function ReduceQuantity(job) {
        return GetButtonActionCallBack(JobsAdminAPI.Creator.Quantity.Reduce, { job_id: job.id });
    }
    function MakeInfinite(job) {
        return GetButtonActionCallBack(JobsAdminAPI.Creator.Quantity.MakeInfinite, { job_id: job.id });
    }
    function MakeFinite(job) {
        return GetButtonActionCallBack(JobsAdminAPI.Creator.Quantity.MakeFinite, { job_id: job.id });
    }
    function DeleteForever(job) {
        return GetButtonActionCallBack(JobsAdminAPI.Creator.Job.DeleteForever, { job_id: job.id });
    }
    function CloseJob(job) {
        return GetButtonActionCallBack(JobsAdminAPI.Creator.Job.Close, { job_id: job.id });
    }
    function OpenEditModal(job) {
        return function () {
            setJobToEdit(undefined);
            queueMicrotask(function () { return setJobToEdit(job); });
            setEditModalVisible(true);
        };
    }
    function OpenWatchersModal(job) {
        return function () {
            setJobToEdit(undefined);
            queueMicrotask(function () { return setJobToEdit(job); });
            setWatcherModalVisible(true);
        };
    }
    function OpenTransferModal(job) {
        return function () {
            setJobToEdit(undefined);
            setTransferError('');
            queueMicrotask(function () { return setJobToEdit(job); });
            setTransferModalVisible(true);
        };
    }
    function GetButtons(props) {
        if (props.job.quantity === -1) {
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "job-component__button form-button form-button--yellow", onClick: MakeFinite(props.job) }, "\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u043B\u0438\u043C\u0438\u0442\u043D\u043E\u0439"),
                React.createElement("button", { className: "job-component__button form-button form-button--red", onClick: CloseJob(props.job) }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E"),
                React.createElement("button", { className: "job-component__button form-button form-button--grey", onClick: OpenEditModal(props.job) }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C")));
        }
        if (props.job.quantity === 0) {
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "job-component__button form-button form-button--green", onClick: AddQuantity(props.job) }, "\u041E\u0442\u043A\u0440\u044B\u0442\u044C \u0441\u043D\u043E\u0432\u0430"),
                React.createElement("button", { className: "job-component__button form-button form-button--red", onClick: DeleteForever(props.job) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u043D\u0430\u0432\u0441\u0435\u0433\u0434\u0430"),
                React.createElement("button", { className: "job-component__button form-button form-button--grey", onClick: OpenEditModal(props.job) }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C")));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: "job-component__button form-button form-button--red", onClick: ReduceQuantity(props.job), disabled: props.job.quantity === 1 }, "-1 \u043C\u0435\u0441\u0442\u043E"),
            React.createElement("button", { className: "job-component__button form-button form-button--green", onClick: AddQuantity(props.job) }, "+1 \u043C\u0435\u0441\u0442\u043E"),
            React.createElement("button", { className: "job-component__button form-button form-button--yellow", onClick: MakeInfinite(props.job) }, "\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0431\u0435\u0437\u043B\u0438\u043C\u0438\u0442\u043D\u043E\u0439"),
            React.createElement("button", { className: "job-component__button form-button form-button--red", onClick: CloseJob(props.job) }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E"),
            React.createElement("button", { className: "job-component__button form-button form-button--grey", onClick: OpenEditModal(props.job) }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C")));
    }
    var _g = useState(false), editModalVisible = _g[0], setEditModalVisible = _g[1];
    var _h = useState(undefined), jobToEdit = _h[0], setJobToEdit = _h[1];
    useEffect(function () {
        if (jobToEdit) {
            setJobToEdit(undefined);
            queueMicrotask(function () { var _a; return setJobToEdit((_a = lastJobs.filter(function (job) { return job.id === (jobToEdit === null || jobToEdit === void 0 ? void 0 : jobToEdit.id); })) === null || _a === void 0 ? void 0 : _a[0]); });
        }
    }, [lastJobs]);
    function OnAddSubmit(evt) {
        evt.preventDefault();
        JobsAdminAPI.AddWatcher(evt.target)
            .finally(UpdateAll);
    }
    function OnTransferSubmit(evt) {
        evt.preventDefault();
        JobsAdminAPI.Transfer(evt.target)
            .then(function (hasAd) {
            if (hasAd) {
                UpdateAll();
                setTransferError('');
                setTransferModalVisible(false);
            }
            else {
                setTransferError('У пользователя нет прав управлять вакансиями');
            }
        }, function () { return setTransferError('Какая-то ошибка'); })
            .finally(UpdateAll);
    }
    function RemoveWatcher(jobId, userId) {
        JobsAdminAPI.RemoveWatcher(jobId, userId)
            .finally(UpdateAll);
    }
    return (React.createElement("div", { className: "jobs-admin-page" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "jobs-admin-page__layout" },
                React.createElement("div", { className: "jobs-admin-page__left-side" },
                    React.createElement("h2", { className: "jobs-admin-page__title" },
                        React.createElement("span", { className: "material-symbols-outlined" }, "data_loss_prevention"),
                        " \u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E"),
                    React.createElement("a", { href: "https://\u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438.\u0430\u043F\u043E.\u0440\u0444/", target: "_blank", className: "jobs-admin-page__jobs-link form-button form-button--green" },
                        React.createElement("span", null, "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u0441 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044F\u043C\u0438"),
                        React.createElement("img", { src: "/img/logo-mini-green-new.svg", alt: "\u041B\u043E\u0433\u043E" })),
                    React.createElement(CreateJobForm, { updater: UpdateAll })),
                React.createElement(Modal, { visible: editModalVisible, visibilitySetter: setEditModalVisible },
                    React.createElement("h3", { className: "jobs-admin-page__title", style: { marginBottom: 15 } }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E"),
                    jobToEdit && React.createElement(Job, { job: jobToEdit, noDescription: true }),
                    React.createElement(CreateJobForm, { updater: UpdateAll, jobToEdit: jobToEdit, modalCloser: function () { return setEditModalVisible(false); } })),
                React.createElement(Modal, { visible: watcherModalVisible, visibilitySetter: setWatcherModalVisible },
                    React.createElement("h3", { className: "jobs-admin-page__title", style: { marginBottom: 15 } }, "\u0417\u0440\u0438\u0442\u0435\u043B\u0438"),
                    jobToEdit && React.createElement(Job, { job: jobToEdit, noDescription: true }),
                    React.createElement("div", { className: "jobs-admin-page__watchers" }, ((jobToEdit === null || jobToEdit === void 0 ? void 0 : jobToEdit.watchers_objects) || []).map(function (watcherUser) {
                        return React.createElement("div", { className: "jobs-admin-page__watcher-wrapper" },
                            React.createElement(User, { user: watcherUser }),
                            React.createElement("button", { className: "material-symbols-outlined jobs-admin-page__watcher-remove", onClick: function () { return RemoveWatcher(jobToEdit === null || jobToEdit === void 0 ? void 0 : jobToEdit.id, watcherUser.id); } }, "cancel"));
                    })),
                    React.createElement("h3", { className: "jobs-admin-page__title", style: { marginBottom: 15 } }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0437\u0440\u0438\u0442\u0435\u043B\u044F"),
                    React.createElement("form", { onSubmit: OnAddSubmit },
                        React.createElement("input", { type: "hidden", name: "job_id", value: jobToEdit === null || jobToEdit === void 0 ? void 0 : jobToEdit.id }),
                        React.createElement("div", { style: { marginTop: 10, marginBottom: 20 } },
                            React.createElement(UserSelect, { name: "user_id", key: jobToEdit === null || jobToEdit === void 0 ? void 0 : jobToEdit.id })),
                        React.createElement("button", { className: "job-component__button form-button" }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"))),
                React.createElement(Modal, { visible: transferModalVisible, visibilitySetter: setTransferModalVisible },
                    React.createElement("h3", { className: "jobs-admin-page__title", style: { marginBottom: 15 } }, "\u041F\u0435\u0440\u0435\u0434\u0430\u0442\u044C \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E"),
                    jobToEdit && React.createElement(Job, { job: jobToEdit, noDescription: true }),
                    React.createElement("form", { onSubmit: OnTransferSubmit },
                        React.createElement("input", { type: "hidden", name: "job_id", value: jobToEdit === null || jobToEdit === void 0 ? void 0 : jobToEdit.id }),
                        React.createElement("div", { style: { marginTop: 20, marginBottom: 20 } },
                            React.createElement(UserSelect, { name: "user_id", key: jobToEdit === null || jobToEdit === void 0 ? void 0 : jobToEdit.id })),
                        React.createElement("button", { className: "job-component__button form-button form-button--red" }, "\u041F\u0435\u0440\u0435\u0434\u0430\u0442\u044C"),
                        React.createElement(FormError, { text: transferError }))),
                React.createElement("div", { className: "jobs-admin-page__right-side" },
                    React.createElement("h2", { className: "jobs-admin-page__title" },
                        React.createElement("span", { className: "material-symbols-outlined" }, "background_replace"),
                        " \u0412\u0430\u0448\u0438 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438"),
                    React.createElement("div", { className: "jobs-admin-page__filters" },
                        React.createElement("span", { onClick: function () { return setCurrentFilter('opened'); }, className: 'form-button ' + (currentFilter === 'opened' ? 'form-button--green' : 'form-button--grey') }, "\u041E\u0442\u043A\u0440\u044B\u0442\u044B\u0435"),
                        React.createElement("span", { onClick: function () { return setCurrentFilter('archived'); }, className: 'form-button ' + (currentFilter === 'archived' ? 'form-button--red' : 'form-button--grey') }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044B\u0435")),
                    React.createElement("div", { className: "jobs-admin-page__last-jobs" }, lastJobs.filter(FilterJobs).map(function (job) { return React.createElement(Job, { job: job, full: true },
                        React.createElement(GetButtons, { job: job }),
                        React.createElement("button", { className: "job-component__button form-button form-button--grey", onClick: OpenWatchersModal(job) },
                            "\u0417\u0440\u0438\u0442\u0435\u043B\u0438 (",
                            job.watchers.length,
                            ")"),
                        React.createElement("button", { className: "job-component__button form-button form-button--red", onClick: OpenTransferModal(job) }, "\u041F\u0435\u0440\u0435\u0434\u0430\u0442\u044C \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044E")); })),
                    React.createElement("p", { className: "jobs-admin-page__no-jobs", hidden: Boolean(lastJobs.filter(FilterJobs).length) }, "\u0422\u0443\u0442 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0439"))))));
}
