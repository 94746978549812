import * as React from "react";
import { useContext, useEffect, useState } from "react";
import CV from "../CV";
import { GlobalContext } from "../../../functions";
import { AccessAPI } from "../../../api/request/access/api";
import { JobsAdminAPI } from "../../../api/admin/jobs/api";
export default function AdminJobsApprovePage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState([]), cvs = _b[0], setCvs = _b[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('jobs/approve'),
            JobsAdminAPI.TD.GetCVs()
                .then(function (cvs) { return setCvs(cvs); }),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, []);
    function GetButtonActionCallBack(queryFunction, cvObject) {
        return function (evt) {
            evt.target.disabled = true;
            queryFunction(cvObject)
                .finally(function () {
                UpdateAll();
                evt.target.disabled = false;
            });
        };
    }
    function AcceptCV(cv) {
        // pending
        return GetButtonActionCallBack(JobsAdminAPI.TD.AcceptCV, { cv_id: cv.id });
    }
    function RejectCV(cv) {
        // pending
        return GetButtonActionCallBack(JobsAdminAPI.TD.RejectCV, { cv_id: cv.id });
    }
    function GetButtons(props) {
        return (React.createElement(React.Fragment, null,
            React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: RejectCV(props.cv) }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"),
            React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--green", onClick: AcceptCV(props.cv) }, "\u041F\u0440\u0438\u043D\u044F\u0442\u044C")));
    }
    return (React.createElement("div", { className: "jobs-admin-page" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "jobs-admin-page__title", style: { marginBottom: 15 } },
                React.createElement("span", { className: "material-symbols-outlined" }, "cinematic_blur"),
                " \u0420\u0435\u0437\u044E\u043C\u0435 \u0434\u043B\u044F \u0443\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F \u043D\u0430 \u0422\u0414"),
            React.createElement("div", { className: "jobs-admin-page__cvs" }, cvs.map(function (cv) { return React.createElement(CV, { cv: cv },
                React.createElement(GetButtons, { cv: cv })); })),
            React.createElement("p", { className: "jobs-admin-page__no-jobs", hidden: Boolean(cvs.length) }, "\u0422\u0443\u0442 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u0440\u0435\u0437\u044E\u043C\u0435"))));
}
