import * as React from "react";
import { useEffect, useState } from "react";
import Attention from "../../components/Attention";
import { DateFormat } from "../../functions";
import { InputLabel } from "../../components/Form/InputLabel";
export default function MainInfoForm(props) {
    var _a = useState(0), key = _a[0], setKey = _a[1];
    useEffect(function () { setKey(key + 1); }, [props.user]);
    return (React.createElement("form", { onSubmit: function (evt) { return evt.preventDefault(); }, className: "personal-page__form", key: key },
        React.createElement("h3", null, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0432\u0430\u0441"),
        React.createElement(Attention, { status: "warning" },
            "\u041C\u044B \u043D\u0430\u0434\u0435\u0435\u043C\u0441\u044F, \u0447\u0442\u043E\u00A0\u0432\u0430\u0448\u0438 \u0434\u0430\u043D\u043D\u044B\u0435 \u0431\u044B\u043B\u0438 \u043F\u0440\u0430\u0432\u0438\u043B\u044C\u043D\u043E \u043F\u0435\u0440\u0435\u043D\u0435\u0441\u0435\u043D\u044B \u0432\u00A0\u0431\u0430\u0437\u0443 \u0434\u0430\u043D\u043D\u044B\u0445, \u043D\u043E\u00A0\u043E\u0442\u00A0\u043E\u0448\u0438\u0431\u043E\u043A \u043D\u0435\u00A0\u0437\u0430\u0449\u0438\u0449\u0435\u043D \u043D\u0438\u043A\u0442\u043E. \u0415\u0441\u043B\u0438 \u0432\u044B\u00A0\u043D\u0430\u0439\u0434\u0435\u0442\u0435 \u043E\u0448\u0438\u0431\u043A\u0438 \u0432\u00A0\u0432\u0430\u0448\u0438\u0445 \u0434\u0430\u043D\u043D\u044B\u0445, \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C, \u043F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430 \u0432\u00A0\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443",
            React.createElement("a", { href: "https://t.me/apo_help", className: "text-link" }, "@apo_help")),
        React.createElement(InputLabel, { title: "\u0424\u0430\u043C\u0438\u043B\u0438\u044F", annotation: "\u0414\u043B\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443", required: true, disabled: true, defaultValue: props.user.last_name }),
        React.createElement(InputLabel, { title: "\u0418\u043C\u044F", annotation: "\u0414\u043B\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443", required: true, disabled: true, defaultValue: props.user.first_name }),
        React.createElement(InputLabel, { title: "\u041E\u0442\u0447\u0435\u0441\u0442\u0432\u043E", annotation: "\u0414\u043B\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443", disabled: true, defaultValue: props.user.patronymic }),
        React.createElement(InputLabel, { title: "\u0414\u0430\u0442\u0430 \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F", annotation: "\u0413\u043E\u0434 <u>\u043D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F</u> \u043D\u0430 \u0441\u0430\u0439\u0442\u0435. \u0414\u043B\u044F \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443", disabled: true, required: true, defaultValue: DateFormat(props.user.date_of_birth) })));
}
