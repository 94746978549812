import { Button } from '@gravity-ui/uikit';
import * as React from 'react';
import { useRef, useState } from 'react';
import { DocumentsAdminAPI } from '../../api/admin/documents/api';
import { FormSuccess } from '../../components/Form';
import { TextareaLabelWithRef } from '../../components/Form/InputLabel';
export default function RejectCommentForm(props) {
    var textarea = useRef(null);
    var _a = useState(false), disabled = _a[0], setDisabled = _a[1];
    var _b = useState(false), sent = _b[0], setSent = _b[1];
    return (React.createElement("form", { className: "admin-documents-page__reject-comment modern" },
        React.createElement(TextareaLabelWithRef, { className: "admin-documents-page__reject-comment-textarea", placeholder: "\u041A\u043E\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439", ref: textarea, onInput: function () {
                setSent(false);
            } }),
        React.createElement(Button, { onClick: function () {
                if (textarea.current && textarea.current.value.trim()) {
                    setDisabled(true);
                    DocumentsAdminAPI.SendRejectComment(props.userId, textarea.current.value)
                        .then(function () {
                        textarea.current.value = '';
                        setSent(true);
                    })
                        .finally(function () { return setDisabled(false); });
                }
            }, disabled: disabled }, "\u041F\u0440\u043E\u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C"),
        React.createElement("div", { className: "label" }, sent && React.createElement(FormSuccess, { text: "\u041E\u0442\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u043E" }))));
}
