import * as React from "react";
import { useEffect, useState } from "react";
import { EMAIL_CHECK_REGEXP, PHONE_CHECK_REGEXP, VK_CHECK_REGEXP } from "../../functions";
import { FormError, FormSuccess } from "../../components/Form";
import { InputLabel } from "../../components/Form/InputLabel";
import { CheckValueNotEmpty, CheckValueRegExp, ReduceStatuses } from "../../components/Form/Checkers";
import UserAPI from "../../api/request/user/api";
export default function InfoForm(props) {
    var _a = useState(false), pending = _a[0], setPending = _a[1];
    var _b = useState(''), successText = _b[0], setSuccessText = _b[1];
    var _c = useState(''), errorText = _c[0], setErrorText = _c[1];
    var _d = useState(''), vkNickNameErrorText = _d[0], setVkNickNameErrorText = _d[1];
    var _e = useState(''), emailErrorText = _e[0], setEmailErrorText = _e[1];
    var _f = useState(''), phoneErrorText = _f[0], setPhoneErrorText = _f[1];
    var _g = useState(''), universityErrorText = _g[0], setUniversityErrorText = _g[1];
    var _h = useState(''), cityErrorText = _h[0], setCityErrorText = _h[1];
    var _j = useState(0), key = _j[0], setKey = _j[1];
    useEffect(function () { setKey(key + 1); }, [props.user]);
    function Submit(evt) {
        evt.preventDefault();
        setPending(true);
        var statuses = [];
        setSuccessText('');
        setErrorText('');
        setVkNickNameErrorText('');
        setEmailErrorText('');
        setPhoneErrorText('');
        setUniversityErrorText('');
        setCityErrorText('');
        statuses.push(CheckValueNotEmpty('vk_nickname', 'Введите никнейм ВК', setVkNickNameErrorText));
        statuses.push(CheckValueRegExp('vk_nickname', 'Введите никнейм ВК', VK_CHECK_REGEXP, setVkNickNameErrorText));
        statuses.push(CheckValueRegExp('email', 'Введите корректный email', EMAIL_CHECK_REGEXP, setEmailErrorText));
        statuses.push(CheckValueNotEmpty('phone', 'Введите телефон', setPhoneErrorText));
        statuses.push(CheckValueRegExp('phone', 'Введите телефон в формате +79450001122', PHONE_CHECK_REGEXP, setPhoneErrorText));
        statuses.push(CheckValueNotEmpty('university', 'Введите ваш университет', setUniversityErrorText));
        statuses.push(CheckValueNotEmpty('city', 'Введите ваш город', setCityErrorText));
        if (!ReduceStatuses(statuses)) {
            setPending(false);
        }
        else {
            UserAPI.SetUserInfo(evt.target)
                .then(function () {
                props.updater()
                    .finally(function () {
                    setPending(false);
                    setTimeout(function () { return setSuccessText('Сохранено'); });
                });
            }, function () {
                setPending(false);
                setErrorText('Что-то пошло не так');
            });
        }
    }
    return (React.createElement("form", { onSubmit: Submit, key: key, className: "personal-page__form", style: { marginTop: 0 } },
        React.createElement(InputLabel, { title: "\u041D\u0438\u043A\u043D\u0435\u0439\u043C \u0412\u041A", prefix: "vk.com/", name: "vk_nickname", defaultValue: props.user.vk_nickname, required: true, error: vkNickNameErrorText }),
        React.createElement(InputLabel, { title: "\u042D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430\u044F \u043F\u043E\u0447\u0442\u0430", name: "email", defaultValue: props.user.email, error: emailErrorText }),
        React.createElement(InputLabel, { title: "\u041D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430", name: "phone", type: "tel", annotation: "\u0412 \u0444\u043E\u0440\u043C\u0430\u0442\u0435 +79450001122. <u>\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F</u> \u043F\u0443\u0431\u043B\u0438\u0447\u043D\u043E (\u0432\u0438\u0434\u0435\u043D \u0442\u043E\u043B\u044C\u043A\u043E \u0440\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044E)", defaultValue: props.user.phone, required: true, error: phoneErrorText }),
        React.createElement(InputLabel, { title: "\u041E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u043D\u0438\u0435, \u0443\u043D\u0438\u0432\u0435\u0440\u0441\u0438\u0442\u0435\u0442", name: "university", annotation: "<u>\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F</u> \u043F\u0443\u0431\u043B\u0438\u0447\u043D\u043E (\u0432\u0438\u0434\u0435\u043D \u0442\u043E\u043B\u044C\u043A\u043E \u0440\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044E)", defaultValue: props.user.university, error: universityErrorText }),
        React.createElement(InputLabel, { title: "\u0413\u043E\u0440\u043E\u0434 \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u043D\u0438\u044F", name: "city", annotation: "<u>\u041D\u0435 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F</u> \u043F\u0443\u0431\u043B\u0438\u0447\u043D\u043E (\u0432\u0438\u0434\u0435\u043D \u0442\u043E\u043B\u044C\u043A\u043E \u0440\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u044E)", defaultValue: props.user.city, error: cityErrorText }),
        React.createElement("button", { disabled: pending }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
        React.createElement(FormError, { text: errorText }),
        React.createElement(FormSuccess, { text: successText })));
}
