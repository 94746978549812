var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AdminAPI } from "../api";
function JobsQuery(query) {
    return function (data) {
        return AdminAPI.VoidQuery({
            url: "admin/jobs/".concat(query),
            data: data,
        });
    };
}
var JobsAdminAPI = /** @class */ (function (_super) {
    __extends(JobsAdminAPI, _super);
    function JobsAdminAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = JobsAdminAPI;
    JobsAdminAPI.Creator = {
        Job: {
            Create: JobsQuery('Creator/Job/Create'),
            Close: JobsQuery('Creator/Job/Close'),
            DeleteForever: JobsQuery('Creator/Job/DeleteForever'),
            Update: JobsQuery('Creator/Job/Update'),
        },
        Quantity: {
            Add: JobsQuery('Creator/Quantity/Add'),
            Reduce: JobsQuery('Creator/Quantity/Reduce'),
            MakeFinite: JobsQuery('Creator/Quantity/MakeFinite'),
            MakeInfinite: JobsQuery('Creator/Quantity/MakeInfinite'),
        },
        CV: {
            AcceptGPH: JobsQuery('Creator/CV/AcceptGPH'),
            AcceptTD: JobsQuery('Creator/CV/AcceptTD'),
            Reject: JobsQuery('Creator/CV/Reject'),
            CreateUser: JobsQuery('Creator/CV/CreateUser'),
            DeleteForever: JobsQuery('Creator/CV/DeleteForever'),
        },
        GetCVs: function () { return _a.AlwaysResolvingQuery({
            url: 'admin/jobs/Creator/GetCVs/',
            dataCallback: function (data) { return data.cvs; },
            defaultValueCallback: function () { return []; },
        }); },
        GetJobs: function () { return _a.AlwaysResolvingQuery({
            url: 'admin/jobs/Creator/GetJobs/',
            dataCallback: function (data) { return data.jobs; },
            defaultValueCallback: function () { return []; },
        }); },
        GetLoggedUserOrganisations: function () { return _a.AlwaysResolvingQuery({
            url: 'admin/jobs/Creator/GetJobs/',
            dataCallback: function (data) { return data.organisation_ids; },
            defaultValueCallback: function () { return []; },
        }); },
    };
    JobsAdminAPI.TD = {
        AcceptCV: JobsQuery('TD/AcceptCV'),
        RejectCV: JobsQuery('TD/RejectCV'),
        GetCVs: function () { return _a.AlwaysResolvingQuery({
            url: 'admin/jobs/TD/GetCVs/',
            dataCallback: function (data) { return data.cvs; },
            defaultValueCallback: function () { return []; },
        }); },
    };
    JobsAdminAPI.GetWatcherPage = function () { return _a.RejectingOnErrorUnchangedQuery({
        url: 'admin/jobs/GetWatcherPage/',
    }); };
    JobsAdminAPI.AddWatcher = function (form) { return _a.VoidQuery({
        url: 'admin/jobs/AddWatcher/',
        data: form,
    }); };
    JobsAdminAPI.RemoveWatcher = function (jobId, userId) { return _a.VoidQuery({
        url: 'admin/jobs/RemoveWatcher/',
        data: {
            job_id: jobId,
            user_id: userId,
        },
    }); };
    JobsAdminAPI.RemoveLoggedUser = function (jobId) { return _a.VoidQuery({
        url: 'admin/jobs/RemoveLoggedUser/',
        data: { job_id: jobId },
    }); };
    JobsAdminAPI.Transfer = function (form) { return _a.RejectingOnErrorQuery({
        url: 'admin/jobs/Transfer/',
        data: form,
        dataCallback: function (data) { return data.has_ad; },
    }); };
    return JobsAdminAPI;
}(AdminAPI));
export { JobsAdminAPI };
