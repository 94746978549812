import * as React from "react";
import { useCallback } from "react";
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { NextButton, PrevButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
var EmblaCarousel = function (props) {
    var _a = useEmblaCarousel(props.options, [Autoplay()]), emblaRef = _a[0], emblaApi = _a[1];
    var onNavButtonClick = useCallback(function (emblaApi) {
        var _a;
        var autoplay = (_a = emblaApi === null || emblaApi === void 0 ? void 0 : emblaApi.plugins()) === null || _a === void 0 ? void 0 : _a.autoplay;
        if (!autoplay)
            return;
        var resetOrStop = autoplay.options.stopOnInteraction === false
            ? autoplay.reset
            : autoplay.stop;
        resetOrStop();
    }, []);
    var _b = useDotButton(emblaApi, onNavButtonClick), selectedIndex = _b.selectedIndex, scrollSnaps = _b.scrollSnaps, onDotButtonClick = _b.onDotButtonClick;
    var _c = usePrevNextButtons(emblaApi, onNavButtonClick), prevBtnDisabled = _c.prevBtnDisabled, nextBtnDisabled = _c.nextBtnDisabled, onPrevButtonClick = _c.onPrevButtonClick, onNextButtonClick = _c.onNextButtonClick;
    return (React.createElement("section", { className: "embla" },
        React.createElement("div", { className: "embla__viewport", ref: emblaRef },
            React.createElement("div", { className: "embla__container" }, props.children)),
        React.createElement("div", { className: "embla__controls" },
            React.createElement("div", { className: "embla__buttons" },
                React.createElement(PrevButton, { onClick: onPrevButtonClick, disabled: prevBtnDisabled }),
                React.createElement(NextButton, { onClick: onNextButtonClick, disabled: nextBtnDisabled })),
            React.createElement("div", { className: "embla__dots" }, scrollSnaps.map(function (_, index) { return (React.createElement(DotButton, { key: index, onClick: function () { return onDotButtonClick(index); }, className: 'embla__dot'.concat(index === selectedIndex ? ' embla__dot--selected' : '') })); })))));
};
export default EmblaCarousel;
