var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { JobsAPI } from "../../../api/request/jobs/api";
import { SelectLabelWithRef } from "../SelectLabel";
export default function JobFilter(props) {
    var _a = useState({}), filters = _a[0], setFilters = _a[1];
    var selectRef = useRef(null);
    useEffect(function () {
        JobsAPI.GetFilters()
            .then(function (filters) { return setFilters(filters); });
    }, []);
    useEffect(function () {
        if (selectRef === null || selectRef === void 0 ? void 0 : selectRef.current) {
            selectRef.current.value = String(props.value);
        }
    }, [props.value]);
    return (React.createElement(SelectLabelWithRef, { title: "\u0420\u043E\u0434 \u0434\u0435\u044F\u0442\u0435\u043B\u044C\u043D\u043E\u0441\u0442\u0438", name: props.name, options: props.noAny ? Object.entries(filters) : __spreadArray([[0, 'Любой']], Object.entries(filters), true), value: props.value, valueSetter: props.valueSetter, ref: selectRef }));
}
