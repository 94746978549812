import * as React from "react";
import { useContext } from "react";
import Helmet from "react-helmet";
import Footer from "./footer";
import Header from "./header";
import Loader from "../Loader";
import { GlobalContext } from "../../functions";
export default function GetLayout(withEssentials) {
    return function (props) {
        var loading = useContext(GlobalContext).loading;
        return (React.createElement(React.Fragment, null,
            React.createElement(Helmet, null,
                React.createElement("title", null, "\u0410\u041F\u041E Superapp"),
                React.createElement("meta", { charSet: "UTF-8" }),
                React.createElement("meta", { name: "viewport", content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" }),
                React.createElement("meta", { httpEquiv: "X-UA-Compatible", content: "ie=edge" }),
                React.createElement("meta", { name: "format-detection", content: "telephone=no" }),
                React.createElement("link", { rel: "preconnect", href: "https://fonts.googleapis.com" }),
                React.createElement("link", { rel: "preconnect", href: "https://fonts.gstatic.com", crossOrigin: "" }),
                React.createElement("link", { rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,500,0,0;24,900,1,0" }),
                React.createElement("meta", { httpEquiv: "cache-control", content: "no-cache, no-store, must-revalidate" }),
                React.createElement("meta", { httpEquiv: "pragma", content: "no-cache" }),
                React.createElement("meta", { httpEquiv: "expires", content: "0" })),
            React.createElement("div", null,
                withEssentials ? React.createElement(Header, null) : '',
                React.createElement(Loader, { loading: loading }),
                React.createElement("main", { hidden: loading }, props.children),
                withEssentials && !loading ? React.createElement(Footer, null) : '')));
    };
}
