import * as React from "react";
import { useContext, useEffect, useState } from "react";
import CV from "../CV";
import { GlobalContext } from "../../../functions";
import { JobsAdminAPI } from "../../../api/admin/jobs/api";
import Job from "../../../components/Job";
export default function AdminJobsWatchPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState([]), cvs = _b[0], setCvs = _b[1];
    var _c = useState([]), jobs = _c[0], setJobs = _c[1];
    var _d = useState('jobs'), currentFilter = _d[0], setCurrentFilter = _d[1];
    function UpdateAll() {
        return Promise.all([
            JobsAdminAPI.GetWatcherPage()
                .then(function (response) {
                setCvs(response.cvs);
                setJobs(response.jobs);
                if (!response.jobs.length) {
                    location.href = '/';
                }
            }),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, []);
    function GetButtonActionCallBack(queryFunction, jobObject) {
        return function (evt) {
            evt.target.disabled = true;
            queryFunction(jobObject)
                .finally(function () {
                UpdateAll();
                evt.target.disabled = false;
            });
        };
    }
    function Unfollow(jobId) {
        JobsAdminAPI.RemoveLoggedUser(jobId)
            .finally(UpdateAll);
    }
    return (React.createElement("div", { className: "jobs-admin-page" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "jobs-admin-page__title" },
                React.createElement("span", { className: "material-symbols-outlined" }, "tv_signin"),
                " \u041D\u0430\u0431\u043B\u044E\u0434\u0435\u043D\u0438\u0435 \u0437\u0430 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u044F\u043C\u0438"),
            React.createElement("div", { className: "jobs-admin-page__filters" },
                React.createElement("span", { onClick: function () { return setCurrentFilter('jobs'); }, className: 'form-button ' + (currentFilter === 'jobs' ? 'form-button--yellow' : 'form-button--grey') }, "\u0412\u0430\u043A\u0430\u043D\u0441\u0438\u0438"),
                React.createElement("span", { onClick: function () { return setCurrentFilter('cvs'); }, className: 'form-button ' + (currentFilter === 'cvs' ? 'form-button--yellow' : 'form-button--grey') }, "\u041F\u043E\u043B\u0443\u0447\u0435\u043D\u043D\u044B\u0435 \u0440\u0435\u0437\u044E\u043C\u0435")),
            React.createElement("div", { className: "jobs-admin-page__cvs", hidden: currentFilter !== 'cvs' }, cvs.map(function (cv) { return React.createElement(CV, { cv: cv }); })),
            React.createElement("div", { className: "jobs-admin-page__last-jobs", hidden: currentFilter !== 'jobs' }, jobs.map(function (job) { return React.createElement(Job, { job: job },
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--grey", onClick: function () { return Unfollow(job.id); } }, "\u041E\u0442\u043F\u0438\u0441\u0430\u0442\u044C\u0441\u044F")); })),
            React.createElement("p", { className: "jobs-admin-page__no-jobs", hidden: currentFilter !== 'cvs' || Boolean(cvs.length) }, "\u0422\u0443\u0442 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u0440\u0435\u0437\u044E\u043C\u0435"))));
}
