import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Question(_a) {
    var question_id = _a.question_id, questions = _a.questions, top = _a.top;
    var _b = useState(false), opened = _b[0], setOpened = _b[1];
    var textRef = useRef();
    var navigate = useNavigate();
    useEffect(function () {
        if (textRef.current) {
            textRef.current.querySelectorAll('a').forEach(function (link) {
                if (link.attributes['href'] && link.attributes['href'].value[0] === '/') {
                    link.addEventListener('click', function (evt) {
                        evt.preventDefault();
                        evt.stopImmediatePropagation();
                        navigate(link.attributes['href'].value);
                    });
                }
            });
        }
    }, []);
    return (React.createElement("div", { className: 'faq-component ' + (top ? 'faq-page__parent-question' : '') },
        React.createElement("div", { className: "faq-component__title text-link", onClick: function () { return setOpened(!opened); } },
            React.createElement("span", null, questions[question_id] && questions[question_id].title),
            React.createElement("span", { className: "material-symbols-outlined faq-component__icon" }, opened ? 'expand_less' : 'expand_more')),
        React.createElement("div", { className: "faq-component__wrapper", hidden: !opened },
            React.createElement("div", { className: "faq-component__text-wrapper" },
                React.createElement("span", { className: "material-symbols-outlined faq-component__icon" }, "comment"),
                React.createElement("span", { ref: textRef, className: "faq-component__text" }, questions[question_id].text.split('\n').map(function (text) { return React.createElement("p", { dangerouslySetInnerHTML: { __html: text } }); }))),
            questions[question_id].children.map(function (child_id) { return React.createElement(Question, { question_id: child_id, questions: questions, top: false }); }))));
}
