import * as React from "react";
import { Link } from "react-router-dom";
export default function Development() {
    return (React.createElement("div", { className: "development-page" },
        React.createElement("div", { className: "container small-container" },
            React.createElement("div", { className: "development-page__number" },
                React.createElement("span", null, "\u041F"),
                React.createElement("span", null, "\u0443"),
                React.createElement("span", null, "\u043F"),
                React.createElement("span", null, "\u0443"),
                React.createElement("span", null, "\u043F"),
                React.createElement("span", null, "\u0443")),
            React.createElement("div", { className: "development-page__text" },
                React.createElement("div", { className: "development-page__text-top" }, "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u0432\u00A0\u0440\u0430\u0437\u0440\u0430\u0431\u043E\u0442\u043A\u0435!"),
                React.createElement("div", { className: "development-page__text-lower" },
                    "\u0412\u044B\u00A0\u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430\u00A0",
                    React.createElement(Link, { to: "/", className: "text-link" },
                        "\u0433\u043B\u0430\u0432\u043D\u0443\u044E",
                        React.createElement("span", { className: "page-404__icon material-symbols-outlined" }, "arrow_forward")))))));
}
