var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import { Link } from "react-router-dom";
var AuthSignup = /** @class */ (function (_super) {
    __extends(AuthSignup, _super);
    function AuthSignup(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            error: null,
            isLoaded: false,
        };
        return _this;
    }
    AuthSignup.prototype.componentDidMount = function () {
        var _this = this;
        fetch('/api/request/user/is-logged-in')
            .then(function (res) { return res.json(); })
            .then(function (result) {
            if (result === 1) {
                location.href = '/';
            }
            else {
                _this.setState({
                    isLoaded: true,
                });
            }
        }, function (error) {
            _this.setState({
                isLoaded: true,
                error: error
            });
        });
    };
    AuthSignup.prototype.render = function () {
        return (React.createElement("div", { className: "login-page" },
            React.createElement("div", { className: "container medium-container" },
                React.createElement("a", { href: "/", className: "login-page__logo" },
                    React.createElement("img", { src: "/img/superapp.svg", alt: "\u041B\u043E\u0433\u043E\u0442\u0438\u043F" })),
                React.createElement("p", { className: "login-page__text" },
                    "\u0427\u0442\u043E\u0431\u044B \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u0432\u00A0\u043D\u0430\u0448\u0435\u043C \u0441\u0435\u0440\u0432\u0438\u0441\u0435, \u043F\u0435\u0440\u0435\u0439\u0434\u0438\u0442\u0435 \u0432\u00A0\u0442\u0435\u043B\u0435\u0433\u0440\u0430\u043C-\u0431\u043E\u0442\u0430 ",
                    React.createElement("a", { href: "https://t.me/cpm_superapp_bot", className: "text-link" }, "@cpm_superapp_bot"),
                    " \u0438\u00A0\u043D\u0430\u0447\u043D\u0438\u0442\u0435 \u0434\u0438\u0430\u043B\u043E\u0433. \u0415\u0441\u043B\u0438 \u0432\u044B\u00A0\u0443\u0436\u0435 \u0435\u0441\u0442\u044C \u0432\u00A0\u0431\u0430\u0437\u0435 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u043E\u0432, \u0432\u044B\u00A0\u0443\u0432\u0438\u0434\u0438\u0442\u0435 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435, \u0447\u0442\u043E \u0432\u044B\u00A0\u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D\u044B. \u042D\u0442\u043E\u00A0\u043D\u0443\u0436\u043D\u043E \u0434\u043B\u044F\u00A0\u0442\u043E\u0433\u043E, \u0447\u0442\u043E\u0431\u044B \u043C\u044B\u00A0\u043C\u043E\u0433\u043B\u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u043B\u044F\u0442\u044C \u0432\u0430\u043C \u043A\u043E\u0434 \u0434\u043B\u044F\u00A0\u0432\u0445\u043E\u0434\u0430 \u043D\u0430\u00A0\u0441\u0430\u0439\u0442, \u0430\u00A0\u0442\u0430\u043A\u0436\u0435 \u043F\u0440\u0438\u0441\u044B\u043B\u0430\u0442\u044C \u0432\u0430\u0436\u043D\u044B\u0435 \u0440\u0430\u0431\u043E\u0447\u0438\u0435 \u0443\u0432\u0435\u0434\u043E\u043C\u043B\u0435\u043D\u0438\u044F."),
                React.createElement("p", { className: "login-page__text" },
                    "\u0412\u00A0\u0441\u043B\u0443\u0447\u0430\u0435 \u043E\u0448\u0438\u0431\u043A\u0438, \u0432\u044B\u00A0\u043C\u043E\u0436\u0435\u0442\u0435 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u044C\u0441\u044F \u0432\u00A0\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 ",
                    React.createElement("a", { href: "https://t.me/apo_help", className: "text-link" }, "@apo_help")),
                React.createElement("p", { className: "login-page__text" },
                    "\u0415\u0441\u043B\u0438 \u0432\u044B\u00A0\u0443\u0436\u0435 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043B\u0438\u0441\u044C, \u0432\u044B\u00A0\u043C\u043E\u0436\u0435\u0442\u0435 ",
                    React.createElement(Link, { to: "/auth/login", className: "text-link" }, "\u0432\u043E\u0439\u0442\u0438"),
                    " \u0432 \u0430\u043A\u043A\u0430\u0443\u043D\u0442"))));
    };
    return AuthSignup;
}(React.Component));
export default AuthSignup;
