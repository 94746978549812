import * as React from "react";
import { useContext, useEffect, useState } from "react";
import User from "../../../components/User";
import { GlobalContext } from "../../../functions";
import { Link, useNavigate } from "react-router-dom";
import { FormError } from "../../../components/Form";
import { IUserTemplate } from "../../../api/interfaces/entity/User";
import { AccessAPI } from "../../../api/request/access/api";
import UserAPI from "../../../api/request/user/api";
import { AdminPositionsAPI } from "../../../api/admin/positions/api";
import RealPositionClassSelect from "../../../components/Form/RealPositionClassSelect";
import { InputLabel } from "../../../components/Form/InputLabel";
import RealTeamSelect from "../../../components/Form/RealTeamSelect";
import CheckLabel from "../../../components/Form/CheckLabel";
export default function AdminOneRealPositionPage(props) {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var navigate = useNavigate();
    var _b = useState(IUserTemplate()), user = _b[0], setUser = _b[1];
    var _c = useState(false), loaded = _c[0], setLoaded = _c[1];
    var _d = useState(false), success = _d[0], setSuccess = _d[1];
    var _e = useState(-1), positionIndex = _e[0], setPositionIndex = _e[1];
    var _f = useState([]), realPositions = _f[0], setRealPositions = _f[1];
    var _g = useState(false), pending = _g[0], setPending = _g[1];
    var _h = useState(false), error = _h[0], setError = _h[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('positions/real'),
            UserAPI.GetUserById(props.user_id)
                .then(function (user) {
                setUser(user);
                return user;
            }),
        ])
            .then(function (_a) {
            var _ = _a[0], user = _a[1];
            return AdminPositionsAPI.GetRealPositionsForUser(user.id)
                .then(function (realPositions) {
                var realPositionsIds = realPositions.map(function (realPosition) { return realPosition.id; });
                if (!~realPositionsIds.indexOf(props.pos_id)) {
                    location.href = "/admin/positions/".concat(props.user_id);
                }
                else {
                    var thisPositionIndex = realPositionsIds.indexOf(props.pos_id);
                    setPositionIndex(thisPositionIndex);
                }
                setRealPositions(realPositions);
            });
        });
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(function () {
            setLoaded(true);
            finishLoading();
        });
    }, [props]);
    function SetRealPosition(evt) {
        evt.preventDefault();
        setError(false);
        setPending(true);
        AdminPositionsAPI.UpdateRealPosition(evt.target)
            .then(function () { return navigate("/admin/positions/".concat(props.user_id)); }, function () { return setError(true); })
            .finally(function () { return setPending(false); });
    }
    return (React.createElement("div", { className: "admin-one-position-page" },
        React.createElement("div", { className: "container small-container" },
            React.createElement(Link, { to: "/admin/positions/".concat(props.user_id), className: "text-link" }, "\u2190 \u041D\u0430\u0437\u0430\u0434"),
            React.createElement("h2", { className: "admin-one-position-page__title" },
                React.createElement("span", { className: "material-symbols-outlined admin-one-position-page__icon" }, "business_center"),
                " \u042E\u0440.\u00A0\u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C #",
                positionIndex + 1),
            React.createElement(User, { user: user }),
            loaded &&
                React.createElement("form", { onSubmit: SetRealPosition, className: "admin-one-position-page__form" },
                    React.createElement("input", { type: "hidden", name: "user_id", value: props.user_id }),
                    React.createElement("input", { type: "hidden", name: "position_id", value: props.pos_id }),
                    React.createElement(RealPositionClassSelect, { name: "class_id", defaultValue: realPositions[positionIndex].class_id }),
                    React.createElement(RealTeamSelect, { teamName: "team_id", defaultValue: realPositions[positionIndex].team_id }),
                    React.createElement(InputLabel, { title: "\u0414\u043E\u043B\u044F \u0437\u0430\u043D\u044F\u0442\u043E\u0441\u0442\u0438, \u0432 \u043F\u0440\u043E\u0446\u0435\u043D\u0442\u0430\u0445 (\u0446\u0435\u043B\u043E\u0435 \u0447\u0438\u0441\u043B\u043E)", defaultValue: String(realPositions[positionIndex].percentage), type: "number", name: "percentage" }),
                    React.createElement(CheckLabel, { name: "is_main_job", checked: Boolean(realPositions[positionIndex].is_main_job) }, "\u041E\u0441\u043D\u043E\u0432\u043D\u043E\u0435 \u043C\u0435\u0441\u0442\u043E \u0440\u0430\u0431\u043E\u0442\u044B"),
                    React.createElement("button", { disabled: pending }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
                    React.createElement(FormError, { text: error ? 'Что-то пошло не так' : '' })))));
}
