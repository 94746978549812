import * as React from "react";
import EmblaCarousel from "../../components/EmblaCarousel";
export default function Carousel() {
    return (React.createElement(EmblaCarousel, { options: { loop: true, align: 'start' } },
        React.createElement("div", { className: "embla__slide" },
            React.createElement("div", { className: "jobs-page__slide", style: { backgroundColor: '#fff6e6' } },
                React.createElement("h2", { className: "jobs-page__slide-title" }, "\u0417\u0430\u043D\u0438\u043C\u0430\u0439\u0441\u044F \u0440\u0430\u0437\u0432\u0438\u0442\u0438\u0435\u043C \u0442\u0430\u043B\u0430\u043D\u0442\u043B\u0438\u0432\u044B\u0445 \u0434\u0435\u0442\u0435\u0439"))),
        React.createElement("div", { className: "embla__slide" },
            React.createElement("div", { className: "jobs-page__slide", style: { backgroundColor: '#eceefd' } },
                React.createElement("h2", { className: "jobs-page__slide-title" }, "\u0420\u0430\u0437\u0432\u0438\u0432\u0430\u0439\u0441\u044F \u043F\u0440\u043E\u0444\u0435\u0441\u0441\u0438\u043E\u043D\u0430\u043B\u044C\u043D\u043E"))),
        React.createElement("div", { className: "embla__slide" },
            React.createElement("div", { className: "jobs-page__slide", style: { backgroundColor: '#ffebe8' } },
                React.createElement("h2", { className: "jobs-page__slide-title" }, "\u0420\u0430\u0431\u043E\u0442\u0430\u0439 \u0432\u00A0\u043A\u043E\u043C\u0430\u043D\u0434\u0435 \u0435\u0434\u0438\u043D\u043E\u043C\u044B\u0448\u043B\u0435\u043D\u043D\u0438\u043A\u043E\u0432"))),
        React.createElement("div", { className: "embla__slide" },
            React.createElement("div", { className: "jobs-page__slide", style: { backgroundColor: '#eef4e5' } },
                React.createElement("h2", { className: "jobs-page__slide-title" }, "\u0412\u044B\u043F\u043E\u043B\u043D\u044F\u0439 \u0442\u0432\u043E\u0440\u0447\u0435\u0441\u043A\u0438\u0435 \u0437\u0430\u0434\u0430\u0447\u0438")))));
}
