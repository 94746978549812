import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../functions";
import PositionsBlock from "./blocks/positions";
import InfoBlock from "./blocks/info";
import ContactsBlock from "./blocks/contacts";
import ExSidebarBlock from "./sidebar/ex";
import MainSidebarBlock from "./sidebar/main";
import CurrentVacationSidebarBlock from "./sidebar/currentVacation";
import FutureVacationSidebarBlock from "./sidebar/futureVacation";
import BirthdaySidebarBlock from "./sidebar/birthday";
import DocumentsBlock from "./blocks/documents";
import Attention from "../../components/Attention";
import LockIcon from "./components/lockIcon";
import AdminButtonsBlock from "./blocks/adminButtons";
import UserAPI from "../../api/request/user/api";
import { IUserTemplate } from "../../api/interfaces/entity/User";
import RealPositionsBlock from "./blocks/realPositions";
export default function UserPage(_a) {
    var id = _a.id;
    var _b = useContext(GlobalContext), startLoading = _b.startLoading, finishLoading = _b.finishLoading;
    var _c = useState(IUserTemplate()), user = _c[0], setUser = _c[1];
    var _d = useState(IUserTemplate()), loggedUser = _d[0], setLoggedUser = _d[1];
    var _e = useState([]), adminPages = _e[0], setAdminPages = _e[1];
    var _f = useState([]), bossDocuments = _f[0], setBossDocuments = _f[1];
    var _g = useState(false), isMe = _g[0], setIsMe = _g[1];
    useEffect(function () {
        startLoading();
        Promise.all([
            UserAPI.GetLoggedUser()
                .then(function (res) {
                setLoggedUser(res.user);
                setAdminPages(res.admin_pages);
            }),
            UserAPI.GetUserById(id)
                .then(function (user) { return setUser(user); }),
            UserAPI.GetUserDocumentsStatuses(id)
                .then(function (documentStatuses) { return setBossDocuments(documentStatuses); }),
        ])
            .finally(finishLoading);
    }, [id]);
    useEffect(function () {
        setIsMe(loggedUser.id === user.id);
    }, [loggedUser, user]);
    return (React.createElement("div", { className: "user-page" },
        React.createElement("div", { className: "container", hidden: Boolean(user.id) },
            React.createElement("h1", { className: "center-title" }, "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044C \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D")),
        React.createElement("div", { className: "container", hidden: !user.id },
            React.createElement("div", { className: "user-page__sidebar" },
                React.createElement(ExSidebarBlock, { user: user }),
                React.createElement(MainSidebarBlock, { user: user }),
                React.createElement(BirthdaySidebarBlock, { user: user }),
                user.position_objects.map(function (position) {
                    if (position.days_till_vacation_end !== -1 && position.on_vacation) {
                        return React.createElement(CurrentVacationSidebarBlock, { multiple_positions: user.position_objects.length - 1, position: position });
                    }
                }),
                user.position_objects.map(function (position) {
                    if (position.days_till_vacation_end !== -1 && !position.on_vacation) {
                        return React.createElement(FutureVacationSidebarBlock, { multiple_positions: user.position_objects.length - 1, position: position });
                    }
                })),
            React.createElement("div", { className: "user-page__info" },
                React.createElement(PositionsBlock, { user: user }),
                React.createElement(RealPositionsBlock, { user: user, isMe: isMe }),
                React.createElement(InfoBlock, { user: user, isMe: isMe }),
                React.createElement(ContactsBlock, { user: user, isMe: isMe }),
                React.createElement(DocumentsBlock, { bossDocuments: bossDocuments, isMe: isMe }),
                React.createElement(Attention, { status: "info", hidden: !isMe },
                    "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044E, \u043F\u043E\u043C\u0435\u0447\u0435\u043D\u043D\u0443\u044E ",
                    React.createElement(LockIcon, null),
                    ", \u0432\u0438\u0434\u0438\u0442\u0435 \u0442\u043E\u043B\u044C\u043A\u043E \u0432\u044B \u0438 \u0432\u0430\u0448\u0438 \u0440\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0438"),
                React.createElement(AdminButtonsBlock, { user: user, adminPages: adminPages })))));
}
