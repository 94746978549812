var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import AuthLoginForm from "./form";
var AuthLogin = /** @class */ (function (_super) {
    __extends(AuthLogin, _super);
    function AuthLogin(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isLoaded: false,
        };
        return _this;
    }
    AuthLogin.prototype.componentDidMount = function () {
        addEventListener('keydown', function (evt) {
            if (~'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890!@#$%^&*()'.indexOf(evt.key)) {
                var input = document.querySelector('#auth-login-form input[name="login"]');
                if (input && input instanceof HTMLInputElement) {
                    input.focus();
                }
            }
        });
    };
    AuthLogin.prototype.render = function () {
        return (React.createElement("div", { className: "login-page" },
            React.createElement("div", { className: "container small-container" },
                React.createElement(AuthLoginForm, null),
                React.createElement("a", { href: "https://\u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438.\u0430\u043F\u043E.\u0440\u0444/", className: "login-page__jobs form-button form-button--green" },
                    React.createElement("span", null, "\u041D\u0430\u0448\u0438 \u0432\u0430\u043A\u0430\u043D\u0441\u0438\u0438"),
                    React.createElement("img", { src: "/img/logo-mini-green-new.svg", alt: "\u041B\u043E\u0433\u043E" })))));
    };
    return AuthLogin;
}(React.Component));
export default AuthLogin;
