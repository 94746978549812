import * as React from "react";
export default function BirthdaySidebarBlock(_a) {
    var _b = _a.user, first_name = _b.first_name, is_birthday = _b.is_birthday;
    return (React.createElement("div", { className: "user-page__card user-page__card--birthday", hidden: !is_birthday },
        React.createElement("div", { className: "birthday-wrapper" },
            React.createElement("span", { className: "material-symbols-outlined icon" }, "celebration"),
            React.createElement("div", { className: "birthday-info" },
                React.createElement("span", { className: "birthday-text" },
                    "\u0421 \u0434\u043D\u0435\u043C \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F, ",
                    first_name,
                    "!")))));
}
