import * as React from "react";
import { useState } from "react";
export default function DocumentUploaderInput(_a) {
    var label = _a.label, i = _a.i, document = _a.document, init_value = _a.init_value;
    var _b = useState(init_value), value = _b[0], setValue = _b[1];
    function checkValid(text) {
        return (new RegExp(document.regexps[i])).test(text.trim());
    }
    function checkValue(evt) {
        var newValue = evt.target.value;
        setValue(newValue);
        setValid((new RegExp(document.regexps[i])).test(newValue.trim()));
    }
    var _c = useState(init_value ? checkValid(init_value) : true), valid = _c[0], setValid = _c[1];
    return (React.createElement("label", { className: (valid ? '' : ' error ') + ((value === init_value && checkValid(init_value)) ? ' good ' : '') },
        React.createElement("span", null,
            label,
            " ",
            React.createElement("span", { className: "material-symbols-outlined document-uploader-component__valid-icon", hidden: !(value === init_value && checkValid(init_value)) }, "done")),
        React.createElement("input", { type: "text", name: "fields[]", value: value, onInput: checkValue }),
        React.createElement("span", { className: "annotation", hidden: !document.formats[i] },
            "\u0412 \u0444\u043E\u0440\u043C\u0430\u0442\u0435: ",
            document.formats[i],
            " ",
            React.createElement("span", { className: "error-text", hidden: valid }, "(\u041D\u0435\u043A\u043E\u0440\u0440\u0435\u043A\u0442\u043D\u044B\u0439\u00A0\u0444\u043E\u0440\u043C\u0430\u0442)"),
            " ",
            React.createElement("span", { className: "success-text", hidden: !(value === init_value && checkValid(init_value)) }, "(\u0421\u043E\u0445\u0440\u0430\u043D\u0435\u043D\u043E)"))));
}
