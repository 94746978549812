import * as React from "react";
export function FormError(props) {
    return (React.createElement("span", { className: "error-text", hidden: !props.text, dangerouslySetInnerHTML: { __html: props.text }, style: props.style }));
}
export function FormSuccess(props) {
    return (React.createElement("span", { className: "success-text", hidden: !props.text, dangerouslySetInnerHTML: { __html: props.text }, style: props.style }));
}
export function FormAnnotation(props) {
    return (React.createElement("span", { className: "annotation", hidden: !props.text, dangerouslySetInnerHTML: { __html: props.text }, style: props.style }));
}
