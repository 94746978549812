import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Question from "../../components/Question";
import { GlobalContext } from "../../functions";
import { FAQAPI } from "../../api/request/faq/api";
export default function FAQPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState({}), questions = _b[0], setQuestions = _b[1];
    var _c = useState([]), parents = _c[0], setParents = _c[1];
    useEffect(function () {
        startLoading();
        FAQAPI.GetFAQ()
            .then(function (response) {
            var result = {};
            response.forEach(function (item) { return result[item.id] = item; });
            setQuestions(result);
            setParents(response.filter(function (item) { return item.parent; }).map(function (item) { return item.id; }));
        })
            .finally(function () {
            finishLoading();
        });
    }, []);
    return (React.createElement("div", { className: "faq-page" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "faq-page__title" },
                React.createElement("span", { className: "material-symbols-outlined faq-page__icon" }, "indeterminate_question_box"),
                " \u0427\u0430\u0441\u0442\u044B\u0435 \u0432\u043E\u043F\u0440\u043E\u0441\u044B"),
            parents.map(function (question_id) { return React.createElement(Question, { question_id: question_id, questions: questions, top: true }); }))));
}
