import * as React from "react";
import { forwardRef, useEffect, useRef } from "react";
import { FormAnnotation, FormError, FormSuccess } from "./index";
export function InputLabel(props, ref) {
    var _a, _b, _c, _d, _e, _f;
    function OnInputChange(evt) {
        if (typeof props.valueSetter === 'function') {
            props.valueSetter(evt.target.value);
        }
    }
    var inputRef = useRef();
    if (!ref) {
        ref = inputRef;
    }
    useEffect(function () {
        if (props.defaultValue !== undefined && ref) {
            ref.current.value = props.defaultValue;
        }
    }, [props.defaultValue]);
    return (React.createElement("label", { hidden: props.hidden, className: props.className + (props.error ? ' error' : ''), style: props.style },
        React.createElement("span", { hidden: !props.title },
            props.title,
            props.required ? React.createElement("span", { className: "required" }, "*") : ''),
        props.prefix ?
            React.createElement("span", { className: "combined-input" },
                props.textarea ?
                    React.createElement("textarea", { disabled: props.disabled, value: props.value, onChange: OnInputChange, onInput: props.onInput, defaultValue: props.defaultValue, name: (_a = props.name) !== null && _a !== void 0 ? _a : '', style: props.inputStyle, autoComplete: props.autoComplete, placeholder: props.placeholder, ref: ref }) :
                    React.createElement("input", { type: (_b = props.type) !== null && _b !== void 0 ? _b : 'text', disabled: props.disabled, value: props.value, onChange: OnInputChange, onInput: props.onInput, defaultValue: props.defaultValue, name: (_c = props.name) !== null && _c !== void 0 ? _c : '', style: props.inputStyle, autoComplete: props.autoComplete, placeholder: props.placeholder, ref: ref }),
                React.createElement("span", { className: "prefix" }, props.prefix)) :
            (props.textarea ?
                React.createElement("textarea", { disabled: props.disabled, value: props.value, onChange: OnInputChange, onInput: props.onInput, defaultValue: props.defaultValue, name: (_d = props.name) !== null && _d !== void 0 ? _d : '', style: props.inputStyle, autoComplete: props.autoComplete, placeholder: props.placeholder, ref: ref }) :
                React.createElement("input", { type: (_e = props.type) !== null && _e !== void 0 ? _e : 'text', disabled: props.disabled, value: props.value, onChange: OnInputChange, onInput: props.onInput, defaultValue: props.defaultValue, name: (_f = props.name) !== null && _f !== void 0 ? _f : '', style: props.inputStyle, autoComplete: props.autoComplete, placeholder: props.placeholder, ref: ref })),
        React.createElement(FormAnnotation, { text: props.annotation }),
        React.createElement(FormError, { text: props.error }),
        React.createElement(FormSuccess, { text: props.success })));
}
export function TextareaLabel(props, ref) {
    props.textarea = true;
    if (ref) {
        return InputLabel(props, ref);
    }
    return InputLabel(props);
}
export var InputLabelWithRef = forwardRef(InputLabel);
export var TextareaLabelWithRef = forwardRef(TextareaLabel);
