import * as React from 'react';
import { useEffect, useState } from 'react';
import { IUserTemplate } from '../../api/interfaces/entity/User';
import TinderAPI from '../../api/request/tinder/api';
import User from '../../components/User';
import { RandInt } from '../../functions';
export default function TinderPage() {
    var _a = useState(RandInt(1000, 100000)), randomSeed = _a[0], setRandomSeed = _a[1];
    var _b = useState(5), counter = _b[0], setCounter = _b[1];
    var _c = useState(IUserTemplate), soulmate = _c[0], setSoulmate = _c[1];
    useEffect(function () {
        var timeout = setTimeout(function () {
            if (counter > 0) {
                setCounter(counter - 1);
            }
        }, 1000);
        return function () { return clearTimeout(timeout); };
    }, [counter]);
    useEffect(function () {
        TinderAPI.GetSoulmate(randomSeed)
            .then(function (user) { return setSoulmate(user); });
    }, []);
    return (React.createElement("div", { className: "container small-container", style: {
            marginBottom: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        } },
        React.createElement("h1", { style: { textAlign: 'center' } }, "\u0412\u0430\u0448 \u0441\u043E\u0443\u043B\u043C\u0435\u0439\u0442\u00A0\u2014 \u044D\u0442\u043E..."),
        (!counter && soulmate.id) ?
            React.createElement(User, { user: soulmate }) :
            React.createElement("div", { style: {
                    fontSize: 30,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: '7px 10px',
                    border: '3px solid #000',
                    display: 'inline-block',
                    borderRadius: 10000,
                    margin: '0 auto',
                } }, counter)));
}
