import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { SelectLabel, SelectLabelWithRef } from "../SelectLabel";
import { ComponentsAPI } from "../../../api/request/components/api";
export default function TeamSelect(props) {
    var _a, _b;
    var _c = useState(0), currentOrganisation = _c[0], setCurrentOrganisation = _c[1];
    var _d = useState([]), organisations = _d[0], setOrganisations = _d[1];
    var _e = useState({}), teamsTree = _e[0], setTeamsTree = _e[1];
    var _f = useState([]), mainTeams = _f[0], setMainTeams = _f[1];
    var teamSelectRef = useRef();
    useEffect(function () {
        ComponentsAPI.GetTeams()
            .then(function (response) {
            // TODO Refactor this
            setOrganisations(response.organisations);
            var mainTeamIds = [];
            var tree = response.teams_dict;
            for (var id in tree) {
                tree[id].public_name =
                    "".concat(tree[id].name, " ").concat(tree[id].parent_team_id === null ? '(главный отдел)' : '');
                if (tree[id].parent_team_id === null) {
                    mainTeamIds.push(Number(id));
                }
                else {
                    tree[tree[id].parent_team_id].children_teams.push(tree[id]);
                    tree[tree[id].parent_team_id].children_teams.sort(function (lhs, rhs) { return lhs.name < rhs.name ? -1 : 1; });
                }
            }
            setTeamsTree(tree);
            setMainTeams(mainTeamIds);
            setCurrentOrganisation(props.defaultValue ?
                tree[props.defaultValue].organisation_id :
                (props.organisationsFilter ?
                    props.organisationsFilter[0] :
                    response.organisations[0].id));
            if (props.defaultValue && teamSelectRef.current) {
                teamSelectRef.current.value = String(props.defaultValue);
            }
        });
    }, []);
    useEffect(function () {
        var _a, _b;
        console.log(props.organisationsFilter);
        setCurrentOrganisation(props.defaultValue ?
            (_a = teamsTree[props.defaultValue]) === null || _a === void 0 ? void 0 : _a.organisation_id :
            (props.organisationsFilter ?
                props.organisationsFilter[0] :
                (_b = organisations[0]) === null || _b === void 0 ? void 0 : _b.id));
        if (props.defaultValue && teamSelectRef.current) {
            teamSelectRef.current.value = String(props.defaultValue);
        }
    }, [props.organisationsFilter, props.defaultValue]);
    function GetOptions() {
        var options = [];
        function DFS(team, level) {
            // in case of recursive teams
            // maybe the whole site will break but this component won't :)
            if (level > 10) {
                return;
            }
            options.push([team.id, '—&nbsp;'.repeat(level) + team.public_name]);
            for (var _i = 0, _a = team.children_teams; _i < _a.length; _i++) {
                var childTeam = _a[_i];
                DFS(childTeam, level + 1);
            }
        }
        for (var _i = 0, mainTeams_1 = mainTeams; _i < mainTeams_1.length; _i++) {
            var mainTeamId = mainTeams_1[_i];
            if (teamsTree[mainTeamId].organisation_id === currentOrganisation) {
                DFS(teamsTree[mainTeamId], 0);
            }
        }
        return options;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectLabel, { title: "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F", name: (_a = props.organisationName) !== null && _a !== void 0 ? _a : '', value: currentOrganisation, valueSetter: function (val) { return setCurrentOrganisation(Number(val)); }, options: organisations
                .filter(function (org) {
                if (!props.organisationsFilter) {
                    return true;
                }
                return Boolean(~props.organisationsFilter.indexOf(org.id));
            })
                .map(function (org) { return [org.id, org.short_name]; }) }),
        React.createElement(SelectLabelWithRef, { title: "\u041A\u043E\u043C\u0430\u043D\u0434\u0430/\u043E\u0442\u0434\u0435\u043B", name: (_b = props.teamName) !== null && _b !== void 0 ? _b : '', options: GetOptions(), defaultValue: props.defaultValue, ref: teamSelectRef, key: currentOrganisation })));
}
