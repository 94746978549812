var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from "../../api";
import { ISearchResponseTemplate } from "./Search/response";
var SearchAPI = /** @class */ (function (_super) {
    __extends(SearchAPI, _super);
    function SearchAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = SearchAPI;
    SearchAPI.Search = function (searchString, searchString2) {
        if (searchString2 === void 0) { searchString2 = ''; }
        return _a.AlwaysResolvingUnchangedQuery({
            url: 'request/search/Search/',
            data: {
                search_string: searchString,
                search_string_2: searchString2,
            },
            defaultValueCallback: function () { return ISearchResponseTemplate(); },
        });
    };
    return SearchAPI;
}(API));
export { SearchAPI };
