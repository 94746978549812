var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
export var useDotButton = function (emblaApi, onButtonClick) {
    var _a = useState(0), selectedIndex = _a[0], setSelectedIndex = _a[1];
    var _b = useState([]), scrollSnaps = _b[0], setScrollSnaps = _b[1];
    var onDotButtonClick = useCallback(function (index) {
        if (!emblaApi)
            return;
        emblaApi.scrollTo(index);
        if (onButtonClick)
            onButtonClick(emblaApi);
    }, [emblaApi, onButtonClick]);
    var onInit = useCallback(function (emblaApi) {
        setScrollSnaps(emblaApi.scrollSnapList());
    }, []);
    var onSelect = useCallback(function (emblaApi) {
        setSelectedIndex(emblaApi.selectedScrollSnap());
    }, []);
    useEffect(function () {
        if (!emblaApi)
            return;
        onInit(emblaApi);
        onSelect(emblaApi);
        emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect);
    }, [emblaApi, onInit, onSelect]);
    return {
        selectedIndex: selectedIndex,
        scrollSnaps: scrollSnaps,
        onDotButtonClick: onDotButtonClick
    };
};
export var DotButton = function (props) {
    var children = props.children, restProps = __rest(props, ["children"]);
    return (React.createElement("button", __assign({ type: "button" }, restProps), children));
};
