import * as React from "react";
import { useContext, useEffect, useState } from "react";
import User from "../../components/User";
import { GlobalContext } from "../../functions";
import ADGroupLine from "./adGroup";
import { IUserTemplate } from "../../api/interfaces/entity/User";
import { AccessAPI } from "../../api/request/access/api";
import UserAPI from "../../api/request/user/api";
import { ADGroupsAdminAPI } from "../../api/admin/adgroups/api";
export default function AdminSetADGroupsPage(props) {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState(IUserTemplate()), user = _b[0], setUser = _b[1];
    var _c = useState({}), adGroupsDict = _c[0], setADGroupsDict = _c[1];
    var _d = useState([]), userADGroups = _d[0], setUserADGroups = _d[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('ad_groups'),
            UserAPI.GetUserById(props.user_id)
                .then(function (user) { return setUser(user); }),
            ADGroupsAdminAPI.GetADGroupsForUser(props.user_id)
                .then(function (response) {
                var adGroupsDict = {};
                for (var _i = 0, _a = response.all_ad_groups; _i < _a.length; _i++) {
                    var adGroup = _a[_i];
                    if (!adGroupsDict.hasOwnProperty(adGroup.directory)) {
                        adGroupsDict[adGroup.directory] = [];
                    }
                    adGroupsDict[adGroup.directory].push(adGroup);
                }
                setADGroupsDict(adGroupsDict);
                setUserADGroups(response.user_ad_groups);
            }),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, [props.user_id]);
    return (React.createElement("div", { className: "admin-user-positions-page" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "admin-user-positions-page__title" },
                React.createElement("span", { className: "material-symbols-outlined admin-user-positions-page__icon" }, "lock_person"),
                " \u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 AD-\u0433\u0440\u0443\u043F\u043F\u0430\u043C\u0438"),
            React.createElement(User, { user: user }),
            Object.entries(adGroupsDict).map(function (entry) {
                return (React.createElement("div", { className: "admin-user-positions-page__directory" },
                    React.createElement("span", { className: "admin-user-positions-page__directory-title" }, entry[0]),
                    entry[1].map(function (adGroup) {
                        return React.createElement(ADGroupLine, { adGroup: adGroup, userADGroups: userADGroups, userId: props.user_id, updateADGroups: UpdateAll });
                    })));
            }))));
}
