import * as React from "react";
import { useEffect, useState } from "react";
import { AdminPositionsAPI } from "../../../api/admin/positions/api";
import RealPositionItem from "../components/realPosition";
import LockIcon from "../components/lockIcon";
export default function RealPositionsBlock(props) {
    var _a = useState([]), realPositions = _a[0], setRealPositions = _a[1];
    useEffect(function () {
        AdminPositionsAPI.GetRealPositionsForUser(props.user.id)
            .then(function (realPositions) { return setRealPositions(realPositions); });
    }, [props.user]);
    if (props.user.contract_type !== 'ТД') {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "user-page__info-block" },
        React.createElement("h2", { className: "user-page__info-title" },
            "\u042E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u0438\u0435 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0438 ",
            React.createElement(LockIcon, { hidden: !props.isMe })),
        React.createElement("div", { className: "user-page__info-item", hidden: Boolean(realPositions.length) },
            React.createElement("div", { className: "user-page__info-item-data", style: { color: "red" } }, "\u041D\u0435 \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u044B")),
        realPositions.map(function (realPosition, i) {
            return React.createElement(RealPositionItem, { realPosition: realPosition, hidden: i === 0 });
        })));
}
