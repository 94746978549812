var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from '../../api';
var OnboardingAPI = /** @class */ (function (_super) {
    __extends(OnboardingAPI, _super);
    function OnboardingAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = OnboardingAPI;
    OnboardingAPI.HideOnboardingTest = function () { return _a.VoidQuery({
        url: 'request/onboarding/HideOnboardingTest/',
        publicQuery: true,
    }); };
    OnboardingAPI.SetOnboardingTest = function (form) { return _a.VoidQuery({
        url: 'request/onboarding/SetOnboardingTest/',
        data: form,
        publicQuery: true,
    }); };
    OnboardingAPI.GetOnboardingTest = function () { return _a.AlwaysResolvingUnchangedQuery({
        url: 'request/onboarding/GetOnboardingTest/',
        defaultValueCallback: function () { return ({
            status: 'ok',
            answered: false,
            ans: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            corrects: 0,
        }); },
        publicQuery: true,
    }); };
    return OnboardingAPI;
}(API));
export default OnboardingAPI;
