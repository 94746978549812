var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import API from '../../api';
var TinderAPI = /** @class */ (function (_super) {
    __extends(TinderAPI, _super);
    function TinderAPI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    var _a;
    _a = TinderAPI;
    TinderAPI.GetSoulmate = function (seed) {
        return _a.RejectingOnErrorQuery({
            data: { seed: seed },
            url: 'request/tinder/GetSoulmate/',
            dataCallback: function (data) { return data.soulmate; },
            publicQuery: true,
        });
    };
    return TinderAPI;
}(API));
export default TinderAPI;
