import { IUserTemplate } from "./User";
export function IPaymentTemplate() {
    return {
        id: 0,
        user_id: 0,
        user_object: IUserTemplate(),
        unit_id: 0,
        unit_title: '',
        work_id: 0,
        team_id: 0,
        team_name: '',
        order_text: '',
        comment_text: '',
        date_start: '1900-01-01',
        date_end: '1900-01-01',
        quantity: 0,
        month: '01.1900',
        status: '',
        seen: 0,
        work_name: '',
        work_price_rate: 0,
        work_item: 'шт.',
        financing_org_id: 0,
        financing_org_short_name: '',
        organisation_id: 0,
        organisation_short_name: '',
    };
}
