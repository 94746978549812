import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Payment from "../../components/Payment";
import { TextareaLabelWithRef } from "../../components/Form/InputLabel";
import Modal from "../../components/Modal";
export default function RejectModal(props) {
    var formRef = useRef();
    var commentRef = useRef();
    var _a = useState(false), pending = _a[0], setPending = _a[1];
    var apiLink = {
        'business': '/api/admin/payments/reject-business-payment',
        'hr': '/api/admin/payments/reject-hr-payment'
    };
    function Submit(evt) {
        evt.preventDefault();
        setPending(true);
        fetch(apiLink[props.page], {
            method: 'POST',
            body: new FormData(formRef.current),
        })
            .then(function (res) { return res; })
            .then(function (response) {
            props.updater()
                .finally(function () {
                props.visibilitySetter(false);
                setPending(false);
            });
        }, function (error) {
            setPending(false);
        });
    }
    useEffect(function () {
        commentRef.current.value = '';
    }, [props.payment]);
    return (React.createElement(Modal, { visible: props.visible, visibilitySetter: props.visibilitySetter },
        React.createElement("form", { ref: formRef, onSubmit: Submit },
            React.createElement("h3", { className: "admin-payment-page__modal-title" }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"),
            props.payment.id !== 0 ?
                React.createElement(Payment, { payment: props.payment, short: true, showCreator: true }) : '',
            React.createElement("input", { name: "id", type: "hidden", value: props.payment.id }),
            React.createElement(TextareaLabelWithRef, { title: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439 \u043E\u0442\u043A\u043B\u043E\u043D\u0435\u043D\u0438\u044F \u0432\u044B\u043F\u043B\u0430\u0442\u044B", name: "reject_text", inputStyle: { backgroundColor: "#fff4f4" }, ref: commentRef }),
            React.createElement("button", { className: "form-button--red", disabled: pending }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"))));
}
