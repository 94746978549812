import * as React from "react";
import FileItem from "../../components/Form/FileUploader/fileItem";
import Job from "../../components/Job";
import { DateFormat, FormatPhoneNumber } from "../../functions";
import Attention from "../../components/Attention";
import User from "../../components/User";
export default function CV(props) {
    var _a;
    return (React.createElement("div", { className: "jobs-admin-page__cv" },
        React.createElement("div", { className: "jobs-admin-page__cv-title" },
            React.createElement("span", null,
                props.cv.last_name,
                " ",
                props.cv.first_name,
                " ",
                props.cv.patronymic || '')),
        React.createElement("div", { className: "jobs-admin-page__cv-files" },
            React.createElement("span", { className: "jobs-admin-page__cv-files-title" }, props.cv.cv.length === 0 ? 'Файл не прикреплен' : (props.cv.cv.length === 1 ? 'Файл:' : 'Файлы:')),
            props.cv.cv.map(function (fileUrl) { return React.createElement(FileItem, { url: fileUrl }); })),
        React.createElement("div", { className: "jobs-admin-page__cv-comment", dangerouslySetInnerHTML: { __html: props.cv.comment_text }, hidden: !props.cv.comment_text }),
        React.createElement("div", { className: "jobs-admin-page__cv-props" },
            React.createElement("div", { className: "jobs-admin-page__cv-prop" },
                React.createElement("span", { className: "jobs-admin-page__cv-prop-title" }, "\u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C:"),
                React.createElement("a", { href: 'https://t.me/' + props.cv.tg_username, className: "jobs-admin-page__cv-prop-text text-link" },
                    "@",
                    props.cv.tg_username)),
            React.createElement("div", { className: "jobs-admin-page__cv-prop" },
                React.createElement("span", { className: "jobs-admin-page__cv-prop-title" }, "Email:"),
                React.createElement("a", { href: 'mailto:' + props.cv.email, className: "jobs-admin-page__cv-prop-text text-link" }, props.cv.email)),
            React.createElement("div", { className: "jobs-admin-page__cv-prop" },
                React.createElement("span", { className: "jobs-admin-page__cv-prop-title" }, "\u0422\u0435\u043B\u0435\u0444\u043E\u043D:"),
                React.createElement("a", { href: 'tel:' + props.cv.phone, className: "jobs-admin-page__cv-prop-text text-link" }, FormatPhoneNumber(props.cv.phone))),
            React.createElement("div", { className: "jobs-admin-page__cv-prop" },
                React.createElement("span", { className: "jobs-admin-page__cv-prop-title" }, "\u0414\u0430\u0442\u0430 \u0440\u043E\u0436\u0434\u0435\u043D\u0438\u044F:"),
                React.createElement("span", { className: "jobs-admin-page__cv-prop-text" }, DateFormat(props.cv.date_of_birth))),
            React.createElement("div", { className: "jobs-admin-page__cv-prop" },
                React.createElement("span", { className: "jobs-admin-page__cv-prop-title" }, "\u041E\u0431\u0440\u0430\u0437\u043E\u0432\u0430\u043D\u0438\u0435:"),
                React.createElement("span", { className: "jobs-admin-page__cv-prop-text" }, props.cv.university)),
            React.createElement("div", { className: "jobs-admin-page__cv-prop" },
                React.createElement("span", { className: "jobs-admin-page__cv-prop-title" }, "\u0413\u043E\u0440\u043E\u0434 \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u043D\u0438\u044F:"),
                React.createElement("span", { className: "jobs-admin-page__cv-prop-text" }, props.cv.city))),
        React.createElement(Job, { job: (_a = props.cv) === null || _a === void 0 ? void 0 : _a.job_object, noDescription: true, full: true }),
        props.cv.is_employee ? React.createElement(Attention, { status: "info" },
            React.createElement("p", { className: "jobs-admin-page__already-an-employee" }, "\u0423\u0436\u0435 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A (\u0441\u043E\u0432\u043F\u0430\u0434\u0435\u043D\u0438\u0435 \u0442\u0433-\u043D\u0438\u043A\u043D\u0435\u0439\u043C\u0430):"),
            React.createElement(User, { user: props.cv.employee_object })) : '',
        React.createElement("div", { className: "jobs-admin-page__cv-buttons", hidden: !props.children }, props.children)));
}
