import * as React from "react";
import { useEffect, useState } from "react";
export default function MainSidebarBlock(props) {
    var _a;
    var _b = useState([]), organisations = _b[0], setOrganisations = _b[1];
    useEffect(function () {
        if (props.user.position_objects) {
            var organisationsSet = new Set(props.user.position_objects.map(function (position) {
                return position.organisation_short_name;
            }));
            setOrganisations(Array.from(organisationsSet));
        }
    }, [props.user]);
    return (React.createElement("div", { className: "user-page__card user-page__card--main" },
        React.createElement("span", { className: "avatar", style: { backgroundImage: 'url("' + props.user.avatar + '")' }, hidden: !props.user.avatar }),
        React.createElement("span", { className: "avatar", style: { backgroundImage: 'url("/img/man-avatar.png")' }, hidden: Boolean(props.user.avatar || props.user.gender) }),
        React.createElement("span", { className: "avatar", style: { backgroundImage: 'url("/img/woman-avatar.png")' }, hidden: Boolean(props.user.avatar || !props.user.gender) }),
        React.createElement("span", { className: "name" },
            props.user.last_name,
            " ",
            props.user.first_name,
            " ", (_a = props.user.patronymic) !== null && _a !== void 0 ? _a : ''),
        React.createElement("div", { className: "position", hidden: !organisations.length }, organisations.join(', ')),
        React.createElement("div", { className: "contacts" },
            React.createElement("div", { className: "user-page__main-contacts contact" },
                React.createElement("a", { className: "user-page__main-contact-item", href: 'https://t.me/' + props.user.tg_username_original_case, hidden: !props.user.tg_username_original_case },
                    React.createElement("img", { src: "/img/icons/tg.svg", alt: "\u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C", className: "icon" }),
                    React.createElement("span", null,
                        "@",
                        props.user.tg_username_original_case)),
                React.createElement("a", { className: "user-page__main-contact-item", href: 'https://vk.com/' + props.user.vk_nickname, hidden: !props.user.vk_nickname },
                    React.createElement("img", { src: "/img/icons/vk.svg", alt: "\u0412\u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0435", className: "icon" }),
                    React.createElement("span", null,
                        "@",
                        props.user.vk_nickname))))));
}
