import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChangeKeyboard, GlobalContext, UseDebouncedFunction } from "../../functions";
import User from "../User";
import UserAPI from "../../api/request/user/api";
import { IUserHeaderTemplate } from "../../api/interfaces/entity/UserHeader";
import { SearchAPI } from "../../api/request/search/api";
export default function Header() {
    var headerUpdater = useContext(GlobalContext).headerUpdater;
    var _a = useState(IUserHeaderTemplate()), user = _a[0], setUser = _a[1];
    var _b = useState(''), searchString = _b[0], setSearchString = _b[1];
    var _c = useState(false), searching = _c[0], setSearching = _c[1];
    var _d = useState([]), foundUsers = _d[0], setFoundUsers = _d[1];
    var _e = useState(false), focused = _e[0], setFocused = _e[1];
    var _f = useState(null), focusOutTimeout = _f[0], setFocusOutTimeout = _f[1];
    var debouncedSearch = UseDebouncedFunction(function (str1, str2) {
        SearchAPI.Search(str1, str2)
            .then(function (response) {
            setFoundUsers(response.users);
        })
            .finally(function () { return setSearching(false); });
    }, 1000);
    useEffect(function () {
        UserAPI.GetUserHeader()
            .then(function (user) { return setUser(user); });
    }, [headerUpdater]);
    function onSearchInput(evt) {
        var newValue = evt.target.value;
        setSearchString(newValue);
        var searchString2 = ChangeKeyboard(newValue);
        setSearching(true);
        debouncedSearch(newValue, searchString2);
    }
    return (React.createElement("header", { className: "header" },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "header__top" },
                React.createElement(Link, { to: "/", className: "header__logo" },
                    React.createElement("img", { src: "/img/superapp.svg", alt: "\u041B\u043E\u0433\u043E\u0442\u0438\u043F" })),
                React.createElement("div", { onFocus: function () { return setFocused(true); }, onBlur: function () { return setFocusOutTimeout(setTimeout(function () { return setFocused(false); }, 300)); }, className: "header__search search-vue-app" },
                    React.createElement("form", null,
                        React.createElement("div", { className: "form-item-wrapper form-item-wrapper--search" },
                            React.createElement("input", { name: "search_string", type: "text", value: searchString, onInput: onSearchInput, placeholder: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0430\u043C" }),
                            React.createElement("div", { className: "header__search-results", hidden: !focused || !searchString, onMouseDown: function () { return setTimeout(function () { return clearTimeout(focusOutTimeout); }, 10); } },
                                React.createElement("div", { className: "header__search-loading", hidden: !searching },
                                    React.createElement("div", { className: "header__search-loader-wrapper" },
                                        React.createElement("div", { className: "header__search-loader-wheel" }),
                                        React.createElement("div", { className: "header__search-loader-text" }, "\u0418\u0449\u0435\u043C \u043A\u043E\u043B\u043B\u0435\u0433"))),
                                React.createElement("div", { className: "header__not-found", hidden: Boolean(foundUsers.length || searching || !searchString) }, "\u041D\u0438\u043A\u0442\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D"),
                                React.createElement("div", { className: "header__found-users", hidden: searching || !searchString || !foundUsers.length }, foundUsers.map(function (user) { return React.createElement("div", { className: "header__search-item" },
                                    React.createElement(User, { user: user })); })))))),
                React.createElement("div", { className: "header__account" },
                    React.createElement(Link, { to: "/user/" + user.id, className: "header__avatar", style: { backgroundImage: "url('" + user.avatar + "')" }, title: "\u0412\u0430\u0448 \u043F\u0440\u043E\u0444\u0438\u043B\u044C" }),
                    React.createElement("a", { href: "/api/request/login/logout", className: "header__logout" },
                        React.createElement("span", { className: "material-symbols-outlined" }, "logout")))))));
}
