import * as React from "react";
import { useEffect } from "react";
export default function Modal(props) {
    useEffect(function () {
        document.body.style.overflow = props.visible ? 'hidden' : '';
    }, [props.visible]);
    function CloseModal(evt) {
        if (evt.target.classList.contains('modal-component__closer')) {
            props.visibilitySetter(false);
        }
    }
    return (React.createElement("div", { className: "modal-component", hidden: !props.visible },
        React.createElement("div", { className: "modal-component__closer", onMouseDown: CloseModal },
            React.createElement("div", { className: "modal-component__body" }, props.children))));
}
