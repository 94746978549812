import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../functions";
import User from "../../components/User";
import { TeamsAPI } from "../../api/request/teams/api";
export default function Structure(_a) {
    var id = _a.id;
    id = parseInt(id, 10);
    var _b = useContext(GlobalContext), startLoading = _b.startLoading, finishLoading = _b.finishLoading;
    var _c = useState([]), bosses = _c[0], setBosses = _c[1];
    var _d = useState([]), employees = _d[0], setEmployees = _d[1];
    var _e = useState(0), parentTeamId = _e[0], setParentTeamId = _e[1];
    var _f = useState(''), teamName = _f[0], setTeamName = _f[1];
    var _g = useState([]), childrenTeams = _g[0], setChildrenTeams = _g[1];
    useEffect(function () {
        startLoading();
        TeamsAPI.GetTeamStructure(id)
            .then(function (response) {
            setTeamName(response.name);
            setBosses(response.bosses);
            var employees = response.employees;
            employees.sort(function (lhs, rhs) {
                if (lhs.sorter === rhs.sorter) {
                    return lhs.last_name > rhs.last_name ? 1 : -1;
                }
                return lhs.sorter > rhs.sorter ? 1 : -1;
            });
            setEmployees(employees);
            setParentTeamId(response.parent_team_id);
            setChildrenTeams(response.children_teams);
        })
            .finally(finishLoading);
    }, [id]);
    return (React.createElement("div", { className: "structure-page" },
        React.createElement("div", { className: "container" },
            id === 0 ? React.createElement("h2", { className: "structure-page__title" }, teamName) :
                React.createElement("h2", { className: "structure-page__title" },
                    React.createElement(Link, { to: '/structure/' + (parentTeamId ? parentTeamId : '') },
                        React.createElement("span", { className: "material-symbols-outlined structure-page__arrow-back" }, "arrow_back")),
                    " ",
                    teamName),
            React.createElement("div", { hidden: id === 0 || !bosses.length, className: "structure-page__bosses" },
                React.createElement("h3", null, "\u0420\u0443\u043A\u043E\u0432\u043E\u0434\u0441\u0442\u0432\u043E"),
                React.createElement("div", { className: "structure-page__user-list" }, bosses.map(function (boss) { return React.createElement(User, { user: boss }); }))),
            React.createElement("div", { className: "structure-page__children", hidden: !childrenTeams.length },
                React.createElement("h3", { hidden: id === 0 }, "\u0412\u0445\u043E\u0434\u044F\u0449\u0438\u0435 \u043A\u043E\u043C\u0430\u043D\u0434\u044B/\u043E\u0442\u0434\u0435\u043B\u044B"),
                React.createElement("div", { className: "structure-page__list" }, childrenTeams.map(function (child) {
                    return (React.createElement(Link, { to: '/structure/' + child.id, className: "structure-page__item-wrapper", hidden: !child },
                        React.createElement("div", { className: "structure-page__item" }, child.name)));
                }))),
            React.createElement("div", { hidden: id === 0 || !employees.length, className: "structure-page__employees" },
                React.createElement("h3", null, "\u0421\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0438"),
                React.createElement("div", { className: "structure-page__user-list" }, employees.map(function (employee) { return React.createElement(User, { user: employee }); }))),
            React.createElement("div", { hidden: !(!employees.length && !childrenTeams.length && !bosses.length) }, "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E. \u0421\u043E\u0432\u0441\u0435\u043C \u043D\u0438\u0447\u0435\u0433\u043E"))));
}
