export function DoHapticFeedback(WebApp) {
    var _a, _b, _c;
    // @ts-ignore
    (_c = (_b = (WebApp !== null && WebApp !== void 0 ? WebApp : (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp)) === null || _b === void 0 ? void 0 : _b.HapticFeedback) === null || _c === void 0 ? void 0 : _c.impactOccurred('light');
}
export function CloseWebApp(WebApp) {
    var _a, _b, _c;
    // @ts-ignore
    (_c = (_b = (WebApp !== null && WebApp !== void 0 ? WebApp : (_a = window.Telegram) === null || _a === void 0 ? void 0 : _a.WebApp)) === null || _b === void 0 ? void 0 : _b.HapticFeedback) === null || _c === void 0 ? void 0 : _c.impactOccurred('light');
}
