import * as React from "react";
import { Link } from "react-router-dom";
import { ApplyPercentage, FormatMoney, RussianNoun } from "../../../functions";
export default function RealPositionItem(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "user-page__line", hidden: props.hidden }),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u044F"),
            React.createElement("div", { className: "user-page__info-item-data" },
                React.createElement(Link, { to: "/structure/".concat(props.realPosition.organisation_main_team_id), className: "text-link" }, props.realPosition.organisation_short_name))),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u041A\u043E\u043C\u0430\u043D\u0434\u0430/\u043E\u0442\u0434\u0435\u043B"),
            React.createElement("div", { className: "user-page__info-item-data" },
                props.realPosition.team_name,
                " ",
                props.realPosition.team_name === props.realPosition.organisation_short_name && '(главный отдел)')),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C"),
            React.createElement("div", { className: "user-page__info-item-data" }, props.realPosition.class_title)),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u0421\u0442\u0430\u0432\u043A\u0430 \u043F\u0440\u0438 \u043F\u043E\u043B\u043D\u043E\u0439 \u0437\u0430\u043D\u044F\u0442\u043E\u0441\u0442\u0438"),
            React.createElement("div", { className: "user-page__info-item-data" },
                FormatMoney(props.realPosition.class_salary),
                "(",
                props.realPosition.class_hours,
                " ",
                RussianNoun(props.realPosition.class_hours, ['час', 'часа', 'часов']),
                ")")),
        React.createElement("div", { className: "user-page__info-item" },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u0414\u043E\u043B\u044F \u0437\u0430\u043D\u044F\u0442\u043E\u0441\u0442\u0438, \u0438\u0442\u043E\u0433\u043E\u0432\u0430\u044F \u0441\u0442\u0430\u0432\u043A\u0430"),
            React.createElement("div", { className: "user-page__info-item-data" },
                props.realPosition.percentage,
                "%\u00A0\u21E2 ",
                FormatMoney(props.realPosition.class_salary, { percentage: props.realPosition.percentage }),
                "(",
                ApplyPercentage(props.realPosition.class_hours, props.realPosition.percentage, false),
                " ",
                RussianNoun(ApplyPercentage(props.realPosition.class_hours, props.realPosition.percentage, false), ['час', 'часа', 'часов']),
                ")"))));
}
