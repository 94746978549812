import * as React from "react";
import { FormatPhoneNumber } from "../../../functions";
import LockIcon from "../components/lockIcon";
export default function ContactsBlock(_a) {
    var _b = _a.user, tg_username_original_case = _b.tg_username_original_case, vk_nickname = _b.vk_nickname, email = _b.email, phone = _b.phone, isMe = _a.isMe;
    return (React.createElement("div", { className: "user-page__info-block" },
        React.createElement("h2", { className: "user-page__info-title" }, "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B"),
        React.createElement("div", { className: "user-page__info-item", hidden: !tg_username_original_case },
            React.createElement("div", { className: "user-page__info-item-name" },
                "\u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C ",
                React.createElement(LockIcon, { hidden: !isMe })),
            React.createElement("div", { className: "user-page__info-item-data" },
                React.createElement("a", { href: 'https://t.me/' + tg_username_original_case, className: "text-link" },
                    "@",
                    tg_username_original_case))),
        React.createElement("div", { className: "user-page__info-item", hidden: !vk_nickname },
            React.createElement("div", { className: "user-page__info-item-name" }, "\u0412\u043A\u043E\u043D\u0442\u0430\u043A\u0442\u0435"),
            React.createElement("div", { className: "user-page__info-item-data" },
                React.createElement("a", { href: 'https://vk.com/' + vk_nickname, className: "text-link" },
                    "@",
                    vk_nickname))),
        React.createElement("div", { className: "user-page__info-item", hidden: !email },
            React.createElement("div", { className: "user-page__info-item-name" }, "Email"),
            React.createElement("div", { className: "user-page__info-item-data" },
                React.createElement("a", { href: 'mailto:' + email, className: "text-link" }, email))),
        React.createElement("div", { className: "user-page__info-item", hidden: !phone },
            React.createElement("div", { className: "user-page__info-item-name" },
                "\u0422\u0435\u043B\u0435\u0444\u043E\u043D ",
                React.createElement(LockIcon, { hidden: !isMe })),
            React.createElement("div", { className: "user-page__info-item-data" },
                React.createElement("a", { href: 'tel:' + phone, className: "text-link" }, FormatPhoneNumber(phone))))));
}
