export function CheckValueNotEmpty(name, errorText, setError, form) {
    var _a;
    var element = (form !== null && form !== void 0 ? form : document).querySelector("[name=\"".concat(name, "\"]"));
    if (!element) {
        setError(errorText);
        return false;
    }
    var value = ((_a = element.value) !== null && _a !== void 0 ? _a : '').trim();
    if (!value) {
        setError(errorText);
        return false;
    }
    return true;
}
export function CheckValueRegExp(name, errorText, regexp, setError, form) {
    var _a;
    var element = (form !== null && form !== void 0 ? form : document).querySelector("[name=\"".concat(name, "\"]"));
    if (!element) {
        setError(errorText);
        return false;
    }
    var value = ((_a = element.value) !== null && _a !== void 0 ? _a : '').trim();
    if (!value) {
        return true;
    }
    if (!regexp.test(value)) {
        setError(errorText);
        return false;
    }
    return true;
}
export function CheckValue(name, errorText, cb, setError, form) {
    var _a;
    var element = (form !== null && form !== void 0 ? form : document).querySelector("[name=\"".concat(name, "\"]"));
    if (!element) {
        setError(errorText);
        return false;
    }
    var value = ((_a = element.value) !== null && _a !== void 0 ? _a : '').trim();
    if (!value) {
        return true;
    }
    if (!cb(value)) {
        setError(errorText);
        return false;
    }
    return true;
}
export function CheckCheckbox(name, errorText, setError) {
    var element = document.querySelector("[name=\"".concat(name, "\"]"));
    if (!element || element.type !== 'checkbox' || !element['checked']) {
        setError(errorText);
        return false;
    }
    return true;
}
export function ReduceStatuses(statuses) {
    return statuses.reduce(function (prev, cur) { return prev && cur; }, true);
}
