import * as React from "react";
import { useEffect, useState } from "react";
import FileItem from "./fileItem";
import { FormAnnotation, FormError } from "../index";
import { RussianNoun } from "../../../functions";
export default function FileUploader(props) {
    var _a, _b, _c;
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState((_a = props.initFiles) !== null && _a !== void 0 ? _a : []), files = _e[0], setFiles = _e[1];
    var _f = useState(0), progress = _f[0], setProgress = _f[1]; // [0, 1]
    var _g = useState(''), error = _g[0], setError = _g[1];
    useEffect(function () {
        var _a;
        setFiles((_a = props.initFiles) !== null && _a !== void 0 ? _a : []);
    }, [props.initFiles]);
    var maxSize = (_b = props.maxSize) !== null && _b !== void 0 ? _b : 5; // MB (default is 5 MB)
    var maxSizeB = maxSize * 1024 * 1024; // maxSize, Bytes
    function GetButtonText() {
        if (props.multiple) {
            if (files.length) {
                return 'Добавить файлы';
            }
            return 'Выбрать файлы';
        }
        if (files.length) {
            return 'Заменить файл';
        }
        return 'Выбрать файл';
    }
    function UploadFiles(evt) {
        var inputFiles = evt.target.files;
        if (!inputFiles.length) {
            return;
        }
        setError('');
        if (props.multiple) {
            var tooBigFiles = 0;
            for (var _i = 0, inputFiles_1 = inputFiles; _i < inputFiles_1.length; _i++) {
                var file = inputFiles_1[_i];
                if (file.size > maxSizeB) {
                    ++tooBigFiles;
                }
            }
            if (tooBigFiles === inputFiles.length) {
                setError("\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u0444\u0430\u0439\u043B\u044B \u0432\u0435\u0441\u044F\u0442 \u0431\u043E\u043B\u044C\u0448\u0435 ".concat(maxSize, " \u041C\u0431"));
            }
            else if (tooBigFiles) {
                setError("\u041D\u0435\u043A\u043E\u0442\u043E\u0440\u044B\u0435 \u0444\u0430\u0439\u043B\u044B \u0432\u0435\u0441\u044F\u0442 \u0431\u043E\u043B\u044C\u0448\u0435 ".concat(maxSize, " \u041C\u0431, \u043E\u043D\u0438 \u043D\u0435 \u0431\u044B\u043B\u0438 \u0437\u0430\u0433\u0440\u0443\u0436\u0435\u043D\u044B"));
            }
        }
        else {
            if (inputFiles[0].size > maxSizeB) {
                setError("\u0424\u0430\u0439\u043B \u0432\u0435\u0441\u0438\u0442 \u0431\u043E\u043B\u044C\u0448\u0435 ".concat(maxSize, " \u041C\u0431"));
            }
        }
        var formData = new FormData();
        var goodFilesNum = 0;
        for (var _a = 0, inputFiles_2 = inputFiles; _a < inputFiles_2.length; _a++) {
            var file = inputFiles_2[_a];
            if (file.size <= maxSizeB) {
                formData.append('files[]', file);
                ++goodFilesNum;
            }
        }
        if (!goodFilesNum) {
            return;
        }
        setLoading(true);
        setProgress(0);
        // XHR для того, чтобы трекать прогресс загрузки, так как на момент написания кода не существует
        // способа трекать загрузку на сервер через fetch
        var xhr = new XMLHttpRequest();
        xhr.upload.onprogress = function (e) {
            setProgress(e.loaded / e.total);
        };
        xhr.onload = xhr.onerror = function () {
            var response = JSON.parse(xhr.response);
            if (props.multiple) {
                setFiles(files.concat(response));
            }
            else {
                setFiles([response[0]]);
            }
            evt.target.value = '';
            setLoading(false);
        };
        //
        xhr.open('POST', '/api/request/files/upload', true);
        xhr.send(formData);
    }
    function RemoveFile(filename) {
        setFiles(files.filter(function (file) { return file !== filename; }));
    }
    useEffect(function () {
        if (props.maxCount && files.length > props.maxCount) {
            setFiles(files.slice(0, props.maxCount));
        }
    }, [files]);
    return (React.createElement("div", { className: "file-uploader-component" },
        React.createElement("div", { className: "file-uploader-component__main-part" },
            React.createElement("div", { className: "file-uploader-component__button-side", hidden: props.maxCount && files.length >= props.maxCount },
                React.createElement("label", null,
                    React.createElement("span", { className: "file-uploader-component__button form-button form-button--grey", style: loading ? { backgroundImage: "linear-gradient(90deg, #ffe89c ".concat(progress * 100, "%, #e9e9e9 ").concat(progress * 100 + 0.1, "%)") } : {} }, GetButtonText()),
                    React.createElement("input", { type: "file", hidden: true, accept: (_c = props.accept) !== null && _c !== void 0 ? _c : '', multiple: Boolean(props.multiple), onChange: UploadFiles })),
                React.createElement(FormAnnotation, { text: props.multiple ? "\u041A\u0430\u0436\u0434\u044B\u0439 \u0444\u0430\u0439\u043B \u0434\u043E ".concat(maxSize, " \u041C\u0431") : "\u0424\u0430\u0439\u043B \u0434\u043E ".concat(maxSize, " \u041C\u0431") })),
            React.createElement("div", { className: "file-uploader-component__files" },
                files.map(function (file) { return React.createElement(FileItem, { key: file, url: file, remover: RemoveFile, name: props.name }); }),
                React.createElement("div", { className: "file-uploader-component__loading", hidden: !loading }))),
        React.createElement(FormAnnotation, { text: (props.maxCount && files.length >= props.maxCount)
                ? "\u041C\u0430\u043A\u0441\u0438\u043C\u0443\u043C ".concat(props.maxCount, " ").concat(RussianNoun(props.maxCount, ['файл', 'файла', 'файлов'])) : '' }),
        React.createElement(FormError, { text: error })));
}
