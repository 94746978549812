import * as React from "react";
import { useEffect, useState } from "react";
import User from "../../User";
import { ChangeKeyboard, UseDebouncedFunction } from "../../../functions";
import { IUserTemplate } from "../../../api/interfaces/entity/User";
import { SearchAPI } from "../../../api/request/search/api";
export default function UserSelect(props) {
    var _a;
    var _b = useState((_a = props.default) !== null && _a !== void 0 ? _a : IUserTemplate({ first_name: 'Выберите сотрудника' })), currentUser = _b[0], setCurrentUser = _b[1];
    var _c = useState(''), searchString = _c[0], setSearchString = _c[1];
    var _d = useState(false), searching = _d[0], setSearching = _d[1];
    var _e = useState([]), foundUsers = _e[0], setFoundUsers = _e[1];
    var _f = useState(false), focused = _f[0], setFocused = _f[1];
    var _g = useState(null), focusOutTimeout = _g[0], setFocusOutTimeout = _g[1];
    useEffect(function () {
        var _a;
        setCurrentUser((_a = props.default) !== null && _a !== void 0 ? _a : IUserTemplate({ first_name: 'Выберите сотрудника' }));
    }, [props.default]);
    var debouncedSearch = UseDebouncedFunction(function (str1, str2) {
        SearchAPI.Search(str1, str2)
            .then(function (response) {
            setFoundUsers(response.users);
        })
            .finally(function () { return setSearching(false); });
    }, 1000);
    function onSearchInput(evt) {
        var newValue = evt.target.value;
        setSearchString(newValue);
        var searchString2 = ChangeKeyboard(newValue);
        setSearching(true);
        debouncedSearch(newValue, searchString2);
    }
    return (React.createElement("div", { className: "user-select-component" },
        React.createElement("div", { className: "user-select-component__selected-user" },
            React.createElement(User, { user: currentUser, targetBlank: true, unLink: true })),
        React.createElement("input", { type: "hidden", name: props.name, value: currentUser.id }),
        React.createElement("div", { className: "user-select-component__search", onFocus: function () { return setFocused(true); }, onBlur: function () { return setFocusOutTimeout(setTimeout(function () { return setFocused(false); }, 300)); } },
            React.createElement("div", { className: "form-item-wrapper form-item-wrapper--search" },
                React.createElement("input", { name: "search_string", type: "text", value: searchString, onInput: onSearchInput, placeholder: "\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0430\u043C" }),
                React.createElement("div", { className: "user-select-component__search-results", hidden: !focused || !searchString, onMouseDown: function () { return setTimeout(function () { return clearTimeout(focusOutTimeout); }, 10); } },
                    React.createElement("div", { className: "user-select-component__search-loading", hidden: !searching },
                        React.createElement("div", { className: "user-select-component__search-loader-wrapper" },
                            React.createElement("div", { className: "user-select-component__search-loader-wheel" }),
                            React.createElement("div", { className: "user-select-component__search-loader-text" }, "\u0418\u0449\u0435\u043C \u043A\u043E\u043B\u043B\u0435\u0433"))),
                    React.createElement("div", { className: "user-select-component__not-found", hidden: Boolean(foundUsers.length || searching || !searchString) }, "\u041D\u0438\u043A\u0442\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D"),
                    React.createElement("div", { className: "user-select-component__found-users", hidden: searching || !searchString || !foundUsers.length }, foundUsers.map(function (user) { return React.createElement("div", { className: "user-select-component__search-item" },
                        React.createElement(User, { user: user, unLink: true, clickFunc: function () {
                                setCurrentUser(user);
                                setSearchString('');
                            } })); })))))));
}
