import * as React from "react";
import { useContext, useEffect, useState } from "react";
import User from "../../../components/User";
import { GlobalContext } from "../../../functions";
import { Link, useNavigate } from "react-router-dom";
import TeamSelect from "../../../components/Form/TeamSelect";
import AlternativeNameInput from "../../../components/Form/AlternativeNameInput";
import PositionClassSelect from "../../../components/Form/PositionClassSelect";
import { FormError } from "../../../components/Form";
import { IUserTemplate } from "../../../api/interfaces/entity/User";
import { AccessAPI } from "../../../api/request/access/api";
import UserAPI from "../../../api/request/user/api";
import { AdminPositionsAPI } from "../../../api/admin/positions/api";
export default function AdminOnePositionPage(props) {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var navigate = useNavigate();
    var _b = useState(IUserTemplate()), user = _b[0], setUser = _b[1];
    var _c = useState(false), loaded = _c[0], setLoaded = _c[1];
    var _d = useState(false), success = _d[0], setSuccess = _d[1];
    var _e = useState(-1), positionIndex = _e[0], setPositionIndex = _e[1];
    var _f = useState(false), pending = _f[0], setPending = _f[1];
    var _g = useState(false), error = _g[0], setError = _g[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('positions'),
            UserAPI.GetUserById(props.user_id)
                .then(function (user) {
                if (!~user.positions.indexOf(props.pos_id)) {
                    location.href = "/admin/positions/".concat(props.user_id);
                }
                else {
                    var thisPositionIndex = user.positions.indexOf(props.pos_id);
                    setUser(user);
                    setPositionIndex(thisPositionIndex);
                }
            }),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(function () {
            setLoaded(true);
            finishLoading();
        });
    }, [props]);
    function SetPosition(evt) {
        evt.preventDefault();
        setError(false);
        setPending(true);
        AdminPositionsAPI.UpdatePosition(evt.target)
            .then(function () { return navigate("/admin/positions/".concat(props.user_id)); }, function () { return setError(true); })
            .finally(function () { return setPending(false); });
    }
    return (React.createElement("div", { className: "admin-one-position-page" },
        React.createElement("div", { className: "container small-container" },
            React.createElement(Link, { to: "/admin/positions/".concat(props.user_id), className: "text-link" }, "\u2190 \u041D\u0430\u0437\u0430\u0434"),
            React.createElement("h2", { className: "admin-one-position-page__title" },
                React.createElement("span", { className: "material-symbols-outlined admin-one-position-page__icon" }, "business_center"),
                " \u0414\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C #",
                user.positions.indexOf(props.pos_id) + 1),
            React.createElement(User, { user: user }),
            loaded ?
                React.createElement("form", { onSubmit: SetPosition, className: "admin-one-position-page__form" },
                    React.createElement("input", { type: "hidden", name: "user_id", value: props.user_id }),
                    React.createElement("input", { type: "hidden", name: "position_id", value: props.pos_id }),
                    React.createElement(PositionClassSelect, { name: "class_id", defaultValue: user.position_objects[positionIndex].class_id }),
                    React.createElement(AlternativeNameInput, { defaultValue: user.position_objects[positionIndex].position_different_name || '' }),
                    React.createElement(TeamSelect, { teamName: "team_id", defaultValue: user.position_objects[positionIndex].team_id }),
                    React.createElement("button", { disabled: pending }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
                    React.createElement(FormError, { text: error ? 'Что-то пошло не так' : '' })) : '')));
}
