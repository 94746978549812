import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Payment from "../../components/Payment";
import { TextareaLabelWithRef } from "../../components/Form/InputLabel";
import Modal from "../../components/Modal";
export default function ApproveModal(props) {
    var formRef = useRef();
    var orderTextareaRef = useRef();
    var commentTextareaRef = useRef();
    var specialCommentTextareaRef = useRef();
    var _a = useState(false), pending = _a[0], setPending = _a[1];
    var textareaColor = {
        'business': '#fff8e8',
        'hr': '#f9ffe8',
    };
    var textareaComment = {
        'business': 'Технический комментарий бизнеса (не виден получателю)',
        'hr': 'Технический комментарий отдела кадров (не виден получателю)',
    };
    var apiLink = {
        'business': '/api/admin/payments/approve-business-payment',
        'hr': '/api/admin/payments/approve-hr-payment',
    };
    function Submit(evt) {
        evt.preventDefault();
        setPending(true);
        fetch(apiLink[props.page], {
            method: 'POST',
            body: new FormData(formRef.current),
        })
            .then(function (res) { return res; })
            .then(function (response) {
            props.updater()
                .finally(function () {
                props.visibilitySetter(false);
                setPending(false);
            });
        }, function (error) {
            setPending(false);
        });
    }
    useEffect(function () {
        var _a, _b;
        if (props.payment.id !== 0) {
            orderTextareaRef.current.value = (_a = props.payment.order_text) !== null && _a !== void 0 ? _a : '';
            commentTextareaRef.current.value = (_b = props.payment.comment_text) !== null && _b !== void 0 ? _b : '';
            specialCommentTextareaRef.current.value = '';
        }
    }, [props.payment]);
    return (React.createElement(Modal, { visible: props.visible, visibilitySetter: props.visibilitySetter },
        React.createElement("form", { ref: formRef, onSubmit: Submit },
            React.createElement("h3", { className: "admin-payment-page__modal-title" }, "\u0423\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C"),
            props.payment.id !== 0 ?
                React.createElement(Payment, { payment: props.payment, short: true, showCreator: true }) : '',
            React.createElement("input", { name: "id", type: "hidden", value: props.payment.id }),
            React.createElement(TextareaLabelWithRef, { title: "\u041F\u0440\u0438\u043A\u0430\u0437", name: "order_text", ref: orderTextareaRef }),
            React.createElement(TextareaLabelWithRef, { title: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439", name: "comment_text", ref: commentTextareaRef }),
            React.createElement(TextareaLabelWithRef, { title: textareaComment[props.page], name: "".concat(props.page, "_comment"), inputStyle: { backgroundColor: textareaColor[props.page] }, ref: specialCommentTextareaRef }),
            React.createElement("button", { disabled: pending }, "\u0423\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C"))));
}
