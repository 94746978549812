import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Payment from "../../../components/Payment";
import { GlobalContext } from "../../../functions";
import ApproveModal from "../ApproveModal";
import RejectModal from "../RejectModal";
import { AccessAPI } from "../../../api/request/access/api";
import { IPaymentTemplate } from "../../../api/interfaces/entity/Payment";
export default function AdminBusinessPaymentPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState('pending-approval'), currentFilter = _b[0], setCurrentFilter = _b[1];
    var _c = useState([]), lastPayments = _c[0], setLastPayments = _c[1];
    function UpdateLastPayments() {
        return Promise.all([
            AccessAPI.CheckAccess('payments/business'),
            fetch('/api/admin/payments/get-last-payments-for-business-approve', { method: 'POST' })
                .then(function (res) { return res.json(); })
                .then(function (response) {
                if (response.result === 'ok') {
                    setLastPayments(response.payments);
                }
            }, function (error) {
            })
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateLastPayments()
            .finally(finishLoading);
    }, []);
    function FilterPayments(payment) {
        if (currentFilter === 'pending-approval') {
            return payment.status === 'created';
        }
        if (currentFilter === 'rejected') {
            return payment.status === 'rejected';
        }
        if (currentFilter === 'approved') {
            return payment.status === 'approved' || payment.status === 'approved by business';
        }
        return true;
    }
    var _d = useState(false), approveModalVisible = _d[0], setApproveModalVisible = _d[1];
    var _e = useState(IPaymentTemplate), currentPaymentToApprove = _e[0], setCurrentPaymentToApprove = _e[1];
    var _f = useState(false), rejectModalVisible = _f[0], setRejectModalVisible = _f[1];
    var _g = useState(IPaymentTemplate), currentPaymentToReject = _g[0], setCurrentPaymentToReject = _g[1];
    function OpenApproveModal(payment) {
        setApproveModalVisible(true);
        setCurrentPaymentToApprove(payment);
    }
    function OpenRejectModal(payment) {
        setRejectModalVisible(true);
        setCurrentPaymentToReject(payment);
    }
    return (React.createElement("div", { className: "admin-payment-page" },
        React.createElement(ApproveModal, { visible: approveModalVisible, visibilitySetter: setApproveModalVisible, updater: UpdateLastPayments, page: "business", payment: currentPaymentToApprove }),
        React.createElement(RejectModal, { visible: rejectModalVisible, visibilitySetter: setRejectModalVisible, updater: UpdateLastPayments, page: "hr", payment: currentPaymentToReject }),
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "admin-payment-page__title" },
                React.createElement("span", { className: "material-symbols-outlined" }, "analytics"),
                " \u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0432\u044B\u043F\u043B\u0430\u0442 (\u0431\u0438\u0437\u043D\u0435\u0441)"),
            React.createElement("div", { className: "admin-payment-page__filters" },
                React.createElement("span", { onClick: function () { return setCurrentFilter('all'); }, className: 'form-button ' + (currentFilter === 'all' ? '' : 'form-button--grey') }, "\u0412\u0441\u0435"),
                React.createElement("span", { onClick: function () { return setCurrentFilter('pending-approval'); }, className: 'form-button ' + (currentFilter === 'pending-approval' ? '' : 'form-button--grey') }, "\u0416\u0434\u0443\u0442 \u0443\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F"),
                React.createElement("span", { onClick: function () { return setCurrentFilter('approved'); }, className: 'form-button ' + (currentFilter === 'approved' ? '' : 'form-button--grey') }, "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u043D\u044B\u0435"),
                React.createElement("span", { onClick: function () { return setCurrentFilter('rejected'); }, className: 'form-button ' + (currentFilter === 'rejected' ? '' : 'form-button--grey') }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0435\u043D\u043D\u044B\u0435")),
            React.createElement("div", { className: "admin-payment-page__last-payments" }, lastPayments.filter(FilterPayments).map(function (payment) { return React.createElement(Payment, { payment: payment, showCreator: true },
                React.createElement("div", { className: "form-button form-button--green", hidden: payment.status !== 'created', onClick: function () { return OpenApproveModal(payment); } }, "\u0423\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C"),
                React.createElement("div", { className: "form-button form-button--red", hidden: payment.status !== 'created', onClick: function () { return OpenRejectModal(payment); } }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C")); })),
            React.createElement("span", { hidden: Boolean(lastPayments.filter(FilterPayments).length) }, "\u0422\u0443\u0442 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u0432\u044B\u043F\u043B\u0430\u0442"))));
}
