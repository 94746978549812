import * as React from "react";
import { useContext, useEffect, useState } from "react";
import CV from "../CV";
import { GlobalContext } from "../../../functions";
import { AccessAPI } from "../../../api/request/access/api";
import { JobsAdminAPI } from "../../../api/admin/jobs/api";
export default function AdminJobsCvsPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState([]), cvs = _b[0], setCvs = _b[1];
    var _c = useState('new'), currentFilter = _c[0], setCurrentFilter = _c[1];
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('jobs/create'),
            JobsAdminAPI.Creator.GetCVs()
                .then(function (cvs) { return setCvs(cvs); }),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, []);
    function GetButtonActionCallBack(queryFunction, cvObject) {
        return function (evt) {
            evt.target.disabled = true;
            queryFunction(cvObject)
                .finally(function () {
                UpdateAll();
                evt.target.disabled = false;
            });
        };
    }
    function RejectCV(cv) {
        // new, pending, accepted
        return GetButtonActionCallBack(JobsAdminAPI.Creator.CV.Reject, { cv_id: cv.id });
    }
    function AcceptTDCV(cv) {
        // new, rejected
        return GetButtonActionCallBack(JobsAdminAPI.Creator.CV.AcceptTD, { cv_id: cv.id });
    }
    function AcceptGPHCV(cv) {
        // new, rejected
        return GetButtonActionCallBack(JobsAdminAPI.Creator.CV.AcceptGPH, { cv_id: cv.id });
    }
    function DeleteCVForever(cv) {
        // rejected
        return GetButtonActionCallBack(JobsAdminAPI.Creator.CV.DeleteForever, { cv_id: cv.id });
    }
    function CreateUser(cv) {
        // accepted
        return GetButtonActionCallBack(JobsAdminAPI.Creator.CV.CreateUser, { cv_id: cv.id });
    }
    function GetButtons(props) {
        if (props.cv.status === 'new') {
            if (props.cv.job_object.contract_type === 'ТД') {
                return (React.createElement(React.Fragment, null,
                    React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: RejectCV(props.cv) }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"),
                    React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--green", onClick: AcceptTDCV(props.cv), disabled: props.cv.job_object.quantity === 0 },
                        "\u041F\u0440\u0438\u043D\u044F\u0442\u044C \u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043D\u0430 \u0443\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 ",
                        props.cv.job_object.quantity ? '' : '(нет мест)')));
            }
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: RejectCV(props.cv) }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"),
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--green", onClick: AcceptGPHCV(props.cv), disabled: props.cv.job_object.quantity === 0 },
                    "\u041F\u0440\u0438\u043D\u044F\u0442\u044C ",
                    props.cv.job_object.quantity ? '' : '(нет мест)')));
        }
        if (props.cv.status === 'pending') {
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: RejectCV(props.cv) }, "\u041E\u0442\u043E\u0437\u0432\u0430\u0442\u044C \u0438 \u043E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C")));
        }
        if (props.cv.status === 'accepted') {
            if (props.cv.user_created) {
                return (React.createElement(React.Fragment, null));
            }
            if (props.cv.is_employee) {
                return (React.createElement(React.Fragment, null,
                    React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: RejectCV(props.cv) }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"),
                    React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--yellow", onClick: CreateUser(props.cv) }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0443")));
            }
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: RejectCV(props.cv) }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0438\u0442\u044C"),
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--yellow", onClick: CreateUser(props.cv) }, "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u044F")));
        }
        if (props.cv.status === 'rejected') {
            if (props.cv.job_object.contract_type === 'ТД') {
                return (React.createElement(React.Fragment, null,
                    React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: DeleteCVForever(props.cv) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u043D\u0430\u0432\u0441\u0435\u0433\u0434\u0430"),
                    React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--green", onClick: AcceptTDCV(props.cv), disabled: props.cv.job_object.quantity === 0 },
                        "\u041F\u0440\u0438\u043D\u044F\u0442\u044C \u0438 \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043D\u0430 \u0443\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 ",
                        props.cv.job_object.quantity ? '' : '(нет мест)')));
            }
            return (React.createElement(React.Fragment, null,
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--red", onClick: DeleteCVForever(props.cv) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u043D\u0430\u0432\u0441\u0435\u0433\u0434\u0430"),
                React.createElement("button", { className: "jobs-admin-page__cv-button form-button form-button--green", onClick: AcceptGPHCV(props.cv), disabled: props.cv.job_object.quantity === 0 },
                    "\u041F\u0440\u0438\u043D\u044F\u0442\u044C ",
                    props.cv.job_object.quantity ? '' : '(нет мест)')));
        }
    }
    return (React.createElement("div", { className: "jobs-admin-page" },
        React.createElement("div", { className: "container medium-container" },
            React.createElement("h2", { className: "jobs-admin-page__title" },
                React.createElement("span", { className: "material-symbols-outlined" }, "contact_mail"),
                " \u041F\u043E\u043B\u0443\u0447\u0435\u043D\u043D\u044B\u0435 \u0440\u0435\u0437\u044E\u043C\u0435"),
            React.createElement("div", { className: "jobs-admin-page__filters" },
                React.createElement("span", { onClick: function () { return setCurrentFilter('new'); }, className: 'form-button ' + (currentFilter === 'new' ? 'form-button--yellow' : 'form-button--grey') }, "\u041D\u043E\u0432\u044B\u0435"),
                React.createElement("span", { onClick: function () { return setCurrentFilter('pending'); }, className: 'form-button ' + (currentFilter === 'pending' ? 'form-button--yellow' : 'form-button--grey') }, "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u043D\u0430\u00A0\u0422\u0414"),
                React.createElement("span", { onClick: function () { return setCurrentFilter('accepted'); }, className: 'form-button ' + (currentFilter === 'accepted' ? 'form-button--green' : 'form-button--grey') }, "\u041F\u0440\u0438\u043D\u044F\u0442\u044B\u0435"),
                React.createElement("span", { onClick: function () { return setCurrentFilter('rejected'); }, className: 'form-button ' + (currentFilter === 'rejected' ? 'form-button--red' : 'form-button--grey') }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0435\u043D\u043D\u044B\u0435")),
            React.createElement("div", { className: "jobs-admin-page__cvs" }, cvs.filter(function (cv) { return cv.status === currentFilter; }).map(function (cv) { return React.createElement(CV, { cv: cv },
                React.createElement(GetButtons, { cv: cv })); })),
            React.createElement("p", { className: "jobs-admin-page__no-jobs", hidden: Boolean(cvs.filter(function (cv) { return cv.status === currentFilter; }).length) }, "\u0422\u0443\u0442 \u043F\u043E\u043A\u0430 \u043D\u0435\u0442 \u0440\u0435\u0437\u044E\u043C\u0435"))));
}
