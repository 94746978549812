import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { CropText, GlobalContext, RussianNoun } from "../../functions";
import TextEditor from "../../components/Form/TextEditor";
import User from "../../components/User";
import { FormError, FormSuccess } from "../../components/Form";
import { AccessAPI } from "../../api/request/access/api";
import MailingAPI from "../../api/admin/mailing/api";
import FileUploader from "../../components/Form/FileUploader";
export default function AdminMailingPage() {
    var _a = useContext(GlobalContext), startLoading = _a.startLoading, finishLoading = _a.finishLoading;
    var _b = useState(false), processing = _b[0], setProcessing = _b[1];
    var _c = useState(''), errorText = _c[0], setErrorText = _c[1];
    var _d = useState(''), successText = _d[0], setSuccessText = _d[1];
    var _e = useState([]), organisations = _e[0], setOrganisations = _e[1];
    var _f = useState([]), checkedOrganisations = _f[0], setCheckedOrganisations = _f[1];
    var _g = useState({}), organisationsDict = _g[0], setOrganisationsDict = _g[1];
    var _h = useState([]), teams = _h[0], setTeams = _h[1];
    var _j = useState([]), checkedTeams = _j[0], setCheckedTeams = _j[1];
    var _k = useState([]), positionClasses = _k[0], setPositionClasses = _k[1];
    var _l = useState([]), checkedPositionClasses = _l[0], setCheckedPositionClasses = _l[1];
    var _m = useState([]), lastMailing = _m[0], setLastMailing = _m[1];
    var _o = useState([]), users = _o[0], setUsers = _o[1];
    var _p = useState([]), addedUsers = _p[0], setAddedUsers = _p[1];
    var _q = useState([]), removedUsers = _q[0], setRemovedUsers = _q[1];
    var _r = useState(true), hiddenUsers1 = _r[0], setHiddenUsers1 = _r[1];
    var _s = useState(true), hiddenUsers2 = _s[0], setHiddenUsers2 = _s[1];
    var _t = useState(true), hiddenUsers3 = _t[0], setHiddenUsers3 = _t[1];
    var _u = useState(true), hiddenUsers4 = _u[0], setHiddenUsers4 = _u[1];
    var _v = useState(true), hiddenTeams = _v[0], setHiddenTeams = _v[1];
    var _w = useState(true), hiddenClasses = _w[0], setHiddenClasses = _w[1];
    var _x = useState(true), hiddenSentMailings = _x[0], setHiddenSentMailings = _x[1];
    function UpdateLastMailing() {
        return Promise.all([
            AccessAPI.CheckAccess('mailing'),
            MailingAPI.GetLastMailings()
                .then(function (lastMailings) {
                for (var i = 0; i < lastMailings.length; ++i) {
                    lastMailings[i].cropped_text = CropText(lastMailings[i].just_text, 180);
                }
                setLastMailing(lastMailings);
            }),
        ]);
    }
    function UpdateAll() {
        return Promise.all([
            AccessAPI.CheckAccess('mailing'),
            MailingAPI.GetFilters()
                .then(function (response) {
                setOrganisations(response.organisations);
                setCheckedOrganisations(response.organisations.map(function (item) { return item.id; }));
                setOrganisationsDict(response.organisations_dict);
                setPositionClasses(response.position_classes);
                setCheckedPositionClasses(response.position_classes.map(function (item) { return item.id; }));
                setTeams(response.teams);
                setCheckedTeams(response.teams.map(function (item) { return item.id; }));
                setUsers(response.users);
            }),
            UpdateLastMailing(),
        ]);
    }
    useEffect(function () {
        startLoading();
        UpdateAll()
            .finally(finishLoading);
    }, []);
    var FilterByOrg = function (i) { return ~checkedOrganisations.indexOf(i.organisation_id); };
    var ManualAddUser = function (userId) {
        if (~addedUsers.indexOf(userId)) {
            return;
        }
        setAddedUsers(addedUsers.concat(userId));
        setRemovedUsers(removedUsers.filter(function (i) { return i !== userId; }));
    };
    var ManualRemoveUser = function (userId) {
        if (~removedUsers.indexOf(userId)) {
            return;
        }
        setAddedUsers(addedUsers.filter(function (i) { return i !== userId; }));
        setRemovedUsers(removedUsers.concat(userId));
    };
    var ManualRevertUser = function (userId) {
        setAddedUsers(addedUsers.filter(function (i) { return i !== userId; }));
        setRemovedUsers(removedUsers.filter(function (i) { return i !== userId; }));
    };
    var GetFilterInfos = function (item) {
        var isOrganisation = item.organisation_ids.reduce(function (sum, i) { return sum || Boolean(~checkedOrganisations.indexOf(i)); }, false);
        var isTeam = item.team_ids.reduce(function (sum, i) { return sum || Boolean(~checkedTeams.indexOf(i)); }, false);
        var isPositionClass = item.position_class_ids.reduce(function (sum, i) { return sum || Boolean(~checkedPositionClasses.indexOf(i)); }, false);
        return [isOrganisation, isTeam, isPositionClass];
    };
    var UsersFiltered = function (item) {
        if (~removedUsers.indexOf(item.id)) {
            return false;
        }
        var _a = GetFilterInfos(item), isOrganisation = _a[0], isTeam = _a[1], isPositionClass = _a[2];
        return isOrganisation && isTeam && isPositionClass;
    };
    var UsersUnfiltered = function (item) {
        if (~addedUsers.indexOf(item.id)) {
            return false;
        }
        var _a = GetFilterInfos(item), isOrganisation = _a[0], isTeam = _a[1], isPositionClass = _a[2];
        return !isOrganisation || !isTeam || !isPositionClass;
    };
    var UsersAdded = function (item) {
        var _a = GetFilterInfos(item), isOrganisation = _a[0], isTeam = _a[1], isPositionClass = _a[2];
        return !(isOrganisation && isTeam && isPositionClass) && ~addedUsers.indexOf(item.id);
    };
    var UsersRemoved = function (item) {
        var _a = GetFilterInfos(item), isOrganisation = _a[0], isTeam = _a[1], isPositionClass = _a[2];
        return !(!isOrganisation || !isTeam || !isPositionClass) && ~removedUsers.indexOf(item.id);
    };
    var SortSignedUp = function (lhs, rhs) {
        if (lhs.is_signed_up === rhs.is_signed_up) {
            return 0;
        }
        return lhs.is_signed_up > rhs.is_signed_up ? -1 : 1;
    };
    function UseTemplate(filterUsed) {
        setCheckedOrganisations(filterUsed.checkedOrganisations);
        setCheckedTeams(filterUsed.checkedTeams);
        setCheckedPositionClasses(filterUsed.checkedPositionClasses);
        setAddedUsers(filterUsed.addedUsers);
        setRemovedUsers(filterUsed.removedUsers);
    }
    // const GetCurrentUsers = () => {
    //     const usersText = users.filter(UsersFiltered).concat(users.filter(UsersAdded)).map((item) => item.id).join(',');
    //     setCurrentUsers(usersText);
    //     return usersText;
    // }
    function SubmitMailing(evt) {
        evt.preventDefault();
        setProcessing(true);
        setSuccessText('');
        setErrorText('');
        var textInput = document.querySelector('input[name="text_html"]');
        var usersInput = document.querySelector('input[name="user_ids"]');
        MailingAPI.Mail(evt.target)
            .then(function (response) {
            if (response.result === 'ok') {
                if (!Array.isArray(response.not_sent) || !response.not_sent.length) {
                    setSuccessText('Рассылка успешно сделана');
                }
                else {
                    setSuccessText("\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0430 \u0441\u0434\u0435\u043B\u0430\u043D\u0430. \u041D\u043E \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F \u0434\u043E\u0448\u043B\u0438 \u043D\u0435 \u0434\u043E \u0432\u0441\u0435\u0445 (\u043D\u0435 \u043F\u043E\u043B\u0443\u0447\u0438\u043B\u043E\u0441\u044C \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C ".concat(response.not_sent.length, " ").concat(RussianNoun(response.not_sent.length, ['сообщение', 'сообщения', 'сообщений']), ")"));
                }
            }
            else {
                if (response.error_text === 'empty text') {
                    setErrorText('Пустое сообщение');
                }
                else if (response.error_text === 'no access') {
                    setErrorText('Нет доступа');
                }
                else {
                    setErrorText('Непредвиденная ошибка');
                }
            }
        }, function (error) {
            setErrorText('Непредвиденная ошибка');
            console.error(error);
        })
            .finally(function () {
            setProcessing(false);
            UpdateLastMailing();
        });
    }
    function CancelMailing(id) {
        MailingAPI.CancelMailing(id)
            .finally(UpdateLastMailing);
    }
    return (React.createElement("div", { className: "admin-mailing-page" },
        React.createElement("div", { className: "container max-container" },
            React.createElement("h2", { className: "admin-mailing-page__title" },
                React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon" }, "stacked_email"),
                " \u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443 \u0432\u00A0\u0422\u0435\u043B\u0435\u0433\u0440\u0430\u043C"),
            React.createElement("form", { className: "admin-mailing-page__form", onSubmit: SubmitMailing },
                React.createElement("div", { className: "admin-mailing-page__left-side" },
                    React.createElement(TextEditor, { inputName: "text_html" })),
                React.createElement("div", { className: "admin-mailing-page__right-side" },
                    React.createElement("div", { className: "admin-mailing-page__file-wrapper" },
                        React.createElement(FileUploader, { name: "images", multiple: true, maxCount: 10, accept: "image/*" })),
                    React.createElement("button", { disabled: processing }, "\u0421\u0434\u0435\u043B\u0430\u0442\u044C \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443"),
                    React.createElement(FormSuccess, { text: successText }),
                    React.createElement(FormError, { text: errorText })),
                React.createElement("input", { type: "hidden", name: "filter_used", value: JSON.stringify({
                        checkedOrganisations: checkedOrganisations,
                        checkedTeams: checkedTeams,
                        checkedPositionClasses: checkedPositionClasses,
                        addedUsers: addedUsers,
                        removedUsers: removedUsers
                    }) }),
                React.createElement("input", { type: "hidden", name: "user_ids", value: users.filter(UsersFiltered).concat(users.filter(UsersAdded)).map(function (item) { return item.id; }).join(',') })),
            React.createElement("h3", { className: "admin-mailing-page__subtitle" },
                React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon" }, "tune"),
                " \u0424\u0438\u043B\u044C\u0442\u0440\u044B"),
            React.createElement("h4", { className: "admin-mailing-page__filter-group-name" }, "\u041E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438"),
            React.createElement("div", { className: "admin-mailing-page__filter-group" },
                React.createElement("div", { hidden: organisations.length <= 1, className: 'admin-mailing-page__filter-item ' + (checkedOrganisations.length === organisations.length ? 'active' : ''), onClick: function () { return setCheckedOrganisations(checkedOrganisations.length === organisations.length ? [] : organisations.map(function (item) { return item.id; })); } },
                    React.createElement("b", null, "\u0412\u0441\u0435")),
                organisations.map(function (org) {
                    return (React.createElement("div", { className: 'admin-mailing-page__filter-item ' + (~checkedOrganisations.indexOf(org.id) ? 'active' : ''), onClick: function () { return setCheckedOrganisations(~checkedOrganisations.indexOf(org.id) ? checkedOrganisations.filter(function (i) { return i !== org.id; }) : checkedOrganisations.concat(org.id)); } }, org.short_name));
                })),
            React.createElement("h4", { className: "admin-mailing-page__filter-group-name" }, "\u041A\u043E\u043C\u0430\u043D\u0434\u044B/\u043E\u0442\u0434\u0435\u043B\u044B"),
            React.createElement("div", { className: "admin-mailing-page__filter-group", hidden: teams.filter(FilterByOrg).length === 0 },
                React.createElement("div", { hidden: teams.filter(FilterByOrg).length <= 1, className: 'admin-mailing-page__filter-item ' + (checkedTeams.length === teams.filter(FilterByOrg).length ? 'active' : ''), onClick: function () { return setCheckedTeams(checkedTeams.length === teams.filter(FilterByOrg).length ? [] : teams.filter(FilterByOrg).map(function (item) { return item.id; })); } },
                    React.createElement("b", null, "\u0412\u0441\u0435")),
                teams.map(function (team) {
                    return (React.createElement("div", { hidden: !FilterByOrg(team) || (hiddenTeams && teams.filter(FilterByOrg).indexOf(team) > 4), className: 'admin-mailing-page__filter-item ' + (~checkedTeams.indexOf(team.id) ? 'active' : ''), onClick: function () { return setCheckedTeams(~checkedTeams.indexOf(team.id) ? checkedTeams.filter(function (i) { return i !== team.id; }) : checkedTeams.concat(team.id)); } },
                        team.name,
                        " (",
                        team.parent_team_id === null ? 'главный отдел' : organisationsDict[team.organisation_id].short_name,
                        ")"));
                }),
                React.createElement("div", { hidden: !teams.filter(FilterByOrg).length },
                    React.createElement("span", { className: "admin-mailing-page__show-more", onClick: function () { return setHiddenTeams(!hiddenTeams); } },
                        React.createElement("span", { className: "text-link" }, hiddenTeams ? 'показать' : 'скрыть')))),
            React.createElement("div", { className: "admin-mailing-page__not-found", hidden: teams.filter(FilterByOrg).length > 0 }, "\u041D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E \u043D\u0438 \u043E\u0434\u043D\u043E\u0433\u043E \u043E\u0442\u0434\u0435\u043B\u0430 (\u043D\u0438\u043A\u0442\u043E \u043D\u0435 \u043F\u043E\u043F\u0430\u0434\u0435\u0442 \u0432 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443 \u043F\u043E \u0444\u0438\u043B\u044C\u0442\u0440\u0430\u043C, \u043D\u043E \u0432\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043B\u044E\u0434\u0435\u0439 \u0432\u0440\u0443\u0447\u043D\u0443\u044E)"),
            React.createElement("h4", { className: "admin-mailing-page__filter-group-name" }, "\u041A\u043B\u0430\u0441\u0441\u044B \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u0435\u0439"),
            React.createElement("div", { className: "admin-mailing-page__filter-group" },
                React.createElement("div", { className: 'admin-mailing-page__filter-item ' + (checkedPositionClasses.length === positionClasses.length ? 'active' : ''), onClick: function () { return setCheckedPositionClasses(checkedPositionClasses.length === positionClasses.length ? [] : positionClasses.map(function (item) { return item.id; })); } },
                    React.createElement("b", null, "\u0412\u0441\u0435")),
                positionClasses.sort(function (lhs, rhs) { return lhs.title < rhs.title ? -1 : 1; })
                    .map(function (positionClass) {
                    return (React.createElement("div", { hidden: hiddenClasses && positionClasses.indexOf(positionClass) > 4, className: 'admin-mailing-page__filter-item ' + (~checkedPositionClasses.indexOf(positionClass.id) ? 'active' : ''), onClick: function () { return setCheckedPositionClasses(~checkedPositionClasses.indexOf(positionClass.id) ? checkedPositionClasses.filter(function (i) { return i !== positionClass.id; }) : checkedPositionClasses.concat(positionClass.id)); } }, positionClass.title));
                }),
                React.createElement("div", { hidden: !positionClasses.length },
                    React.createElement("span", { className: "admin-mailing-page__show-more", onClick: function () { return setHiddenClasses(!hiddenClasses); } },
                        React.createElement("span", { className: "text-link" }, hiddenClasses ? 'показать' : 'скрыть')))),
            React.createElement("h3", { className: "admin-mailing-page__subtitle", hidden: !users.filter(UsersFiltered).length },
                React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon admin-mailing-page__text-icon--add" }, "person_search"),
                " \u041F\u043E\u043F\u0430\u0434\u0443\u0442 \u0432 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443 ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation" }, "(\u043F\u043E \u0444\u0438\u043B\u044C\u0442\u0440\u0430\u043C)"),
                " ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation text-link", onClick: function () { return setHiddenUsers1(!hiddenUsers1); } }, hiddenUsers1 ? 'показать' : 'скрыть')),
            React.createElement("div", { className: "admin-mailing-page__filter-users" }, users.filter(function () { return !hiddenUsers1; }).filter(UsersFiltered).sort(SortSignedUp).map(function (user) { return React.createElement(User, { clickFunc: function () { return ManualRemoveUser(user.id); }, unLink: true, notSignedUpText: "\u041D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 (\u043D\u0435\u00A0\u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D)", user: user },
                React.createElement("div", { style: { marginLeft: 'auto' } },
                    React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon--remove", style: { padding: 5 } }, "unsubscribe"))); })),
            React.createElement("h3", { className: "admin-mailing-page__subtitle", hidden: !users.filter(UsersAdded).length },
                React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon admin-mailing-page__text-icon--add" }, "person_add"),
                " \u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u044B \u0432 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443 ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation" }, "(\u0432\u0440\u0443\u0447\u043D\u0443\u044E)"),
                " ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation text-link", onClick: function () { return setHiddenUsers2(!hiddenUsers2); } }, hiddenUsers2 ? 'показать' : 'скрыть')),
            React.createElement("div", { className: "admin-mailing-page__filter-users" }, users.filter(function () { return !hiddenUsers2; }).filter(UsersAdded).sort(SortSignedUp).map(function (user) { return React.createElement(User, { clickFunc: function () { return ManualRevertUser(user.id); }, unLink: true, notSignedUpText: "\u041D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 (\u043D\u0435\u00A0\u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D)", user: user },
                React.createElement("div", { style: { marginLeft: 'auto' } },
                    React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon--remove", style: { padding: 5 } }, "unsubscribe"))); })),
            React.createElement("h3", { className: "admin-mailing-page__subtitle", hidden: !users.filter(UsersUnfiltered).length },
                React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon admin-mailing-page__text-icon--remove" }, "person_off"),
                " \u041D\u0435 \u043F\u043E\u043F\u0430\u0434\u0443\u0442 \u0432 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443 ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation" }, "(\u043F\u043E \u0444\u0438\u043B\u044C\u0442\u0440\u0430\u043C)"),
                " ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation text-link", onClick: function () { return setHiddenUsers3(!hiddenUsers3); } }, hiddenUsers3 ? 'показать' : 'скрыть')),
            React.createElement("div", { className: "admin-mailing-page__filter-users" }, users.filter(function () { return !hiddenUsers3; }).filter(UsersUnfiltered).sort(SortSignedUp).map(function (user) { return React.createElement(User, { clickFunc: function () { return ManualAddUser(user.id); }, unLink: true, notSignedUpText: "\u041D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 (\u043D\u0435\u00A0\u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D)", user: user },
                React.createElement("div", { style: { marginLeft: 'auto' } },
                    React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon--add", style: { padding: 5 } }, "forward_to_inbox"))); })),
            React.createElement("h3", { className: "admin-mailing-page__subtitle", hidden: !users.filter(UsersRemoved).length },
                React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon admin-mailing-page__text-icon--remove" }, "person_remove"),
                " \u0418\u0441\u043A\u043B\u044E\u0447\u0435\u043D\u044B \u0438\u0437 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation" }, "(\u0432\u0440\u0443\u0447\u043D\u0443\u044E)"),
                " ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation text-link", onClick: function () { return setHiddenUsers4(!hiddenUsers4); } }, hiddenUsers4 ? 'показать' : 'скрыть')),
            React.createElement("div", { className: "admin-mailing-page__filter-users" }, users.filter(function () { return !hiddenUsers4; }).filter(UsersRemoved).sort(SortSignedUp).map(function (user) {
                return React.createElement(User, { clickFunc: function () { return ManualRevertUser(user.id); }, unLink: true, notSignedUpText: "\u041D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435 (\u043D\u0435\u00A0\u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D)", user: user },
                    React.createElement("div", { style: { marginLeft: 'auto' } },
                        React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon--add", style: { padding: 5 } }, "forward_to_inbox")));
            })),
            React.createElement("h3", { className: "admin-mailing-page__subtitle" },
                React.createElement("span", { className: "material-symbols-outlined admin-mailing-page__text-icon" }, "notification_multiple"),
                " \u0412\u0430\u0448\u0438 \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0438\u0435 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 ",
                React.createElement("span", { className: "admin-mailing-page__title-annotation text-link", onClick: function () { return setHiddenSentMailings(!hiddenSentMailings); } }, hiddenSentMailings ? 'показать' : 'скрыть')),
            React.createElement("div", { className: "admin-mailing-page__table-wrapper" },
                React.createElement("table", { className: "admin-mailing-page__table", hidden: hiddenSentMailings },
                    React.createElement("tbody", null, lastMailing.map(function (mailing) { return React.createElement("tr", null,
                        React.createElement("td", { style: { minWidth: 300, maxWidth: 500 } }, mailing.cropped_text),
                        React.createElement("td", { style: { width: 110 } }, mailing.date),
                        React.createElement("td", { style: { width: 110 } },
                            mailing.sent_user_ids.length + mailing.not_sent_user_ids.length,
                            "\u00A0",
                            RussianNoun(mailing.sent_user_ids.length + mailing.not_sent_user_ids.length, ['получатель', 'получателя', 'получателей'])),
                        React.createElement("td", { style: { width: 130, textAlign: "center" } },
                            React.createElement("span", { className: "text-link", onClick: function () { return UseTemplate(mailing.filter_used); } }, "\u0418\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C \u043F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u0435\u0439 \u043A\u0430\u043A\u00A0\u0448\u0430\u0431\u043B\u043E\u043D")),
                        React.createElement("td", { style: { width: 130, textAlign: "center" } },
                            React.createElement("span", { className: "text-link", onClick: function () { return CancelMailing(mailing.id); } }, "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443"))); })))))));
}
