import * as React from "react";
import PositionItem from "../components/position";
export default function PositionsBlock(props) {
    return (React.createElement("div", { className: "user-page__info-block" },
        React.createElement("h2", { className: "user-page__info-title" }, "\u0420\u0430\u0431\u043E\u0442\u0430"),
        React.createElement("div", { className: "user-page__info-item", hidden: Boolean(props.user.position_objects.length) },
            React.createElement("div", { className: "user-page__info-item-data" }, "\u041E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442")),
        props.user.position_objects.map(function (position, i) {
            var _a;
            return React.createElement(PositionItem, { position: position, hidden: i === 0, isBoss: Boolean(~((_a = position.bosses) !== null && _a !== void 0 ? _a : []).map(function (item) { return item.id; }).indexOf(props.user.id)) });
        })));
}
