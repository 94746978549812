export function ITeamTemplate() {
    return {
        id: 0,
        name: '',
        public_name: '',
        boss_ids: [],
        parent_team_id: null,
        organisation_id: 0,
        children_teams: [],
        organisation_object: [],
        bosses: [],
        employees: [],
    };
}
