import * as React from "react";
import LockIcon from "../components/lockIcon";
export default function DocumentsBlock(_a) {
    var bossDocuments = _a.bossDocuments, isMe = _a.isMe;
    function IconForDoc(props) {
        if (!props) {
            return React.createElement(React.Fragment, null);
        }
        if (props.doc.status === 'approved') {
            return React.createElement("div", { className: "user-page__document-item user-page__document-item--valid", title: "\u0412\u0441\u0435 \u0445\u043E\u0440\u043E\u0448\u043E" },
                React.createElement("span", { className: "material-symbols-outlined user-page__document-icon" }, "task"),
                " ",
                props.doc.name);
        }
        if (props.doc.status === 'pending') {
            return React.createElement("div", { className: "user-page__document-item user-page__document-item--pending", title: "\u041D\u0430 \u043F\u0440\u043E\u0432\u0435\u0440\u043A\u0435" },
                React.createElement("span", { className: "material-symbols-outlined user-page__document-icon" }, "schedule"),
                " ",
                props.doc.name);
        }
        if (props.doc.status === 'expires soon') {
            return React.createElement("div", { className: "user-page__document-item user-page__document-item--almost-invalid", title: "\u0421\u043A\u043E\u0440\u043E \u0438\u0441\u0442\u0435\u043A\u0430\u0435\u0442 \u0441\u0440\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F" },
                React.createElement("span", { className: "material-symbols-outlined user-page__document-icon" }, "device_reset"),
                " ",
                props.doc.name);
        }
        if (props.doc.status === 'expired') {
            return React.createElement("div", { className: "user-page__document-item user-page__document-item--invalid", title: "\u0418\u0441\u0442\u0435\u043A \u0441\u0440\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F" },
                React.createElement("span", { className: "material-symbols-outlined user-page__document-icon" }, "device_reset"),
                " ",
                props.doc.name);
        }
        return React.createElement("div", { className: "user-page__document-item user-page__document-item--invalid", title: "\u041D\u0435 \u0437\u0430\u0433\u0440\u0443\u0436\u0435\u043D\u043E \u0438\u043B\u0438 \u043D\u0435 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u043E" },
            React.createElement("span", { className: "material-symbols-outlined user-page__document-icon" }, "scan_delete"),
            " ",
            props.doc.name);
    }
    return (React.createElement("div", { className: "user-page__info-block", hidden: !bossDocuments.length },
        React.createElement("h2", { className: "user-page__info-title" },
            "\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u044B \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u043A\u0430 ",
            React.createElement(LockIcon, { hidden: !isMe })),
        React.createElement("div", { className: "user-page__documents" }, bossDocuments.map(function (doc) { return React.createElement(IconForDoc, { doc: doc }); }))));
}
