import * as React from "react";
import { DateFormat, FormatMoney, GetTextForPaymentMonth } from "../../functions";
import User from "../User";
import { Link } from "react-router-dom";
export default function Payment(props) {
    function GetStatus(status) {
        if (status === 'created') {
            return React.createElement("div", { className: "payment-component__status payment-component__status--created" },
                React.createElement("span", { className: "material-symbols-outlined payment-component__status-icon" }, "work_history"),
                React.createElement("span", { className: "payment-component__status-text" },
                    "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435",
                    React.createElement("br", null),
                    "(\u0431\u0438\u0437\u043D\u0435\u0441)"));
        }
        if (status === 'approved by business') {
            return React.createElement("div", { className: "payment-component__status payment-component__status--approved-by-business" },
                React.createElement("span", { className: "material-symbols-outlined payment-component__status-icon" }, "pending_actions"),
                props.public ?
                    React.createElement("span", { className: "payment-component__status-text" }, "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435") :
                    React.createElement("span", { className: "payment-component__status-text" },
                        "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435",
                        React.createElement("br", null),
                        "(\u043E\u0442\u0434\u0435\u043B\u00A0\u043A\u0430\u0434\u0440\u043E\u0432)"));
        }
        if (status === 'approved') {
            return React.createElement("div", { className: "payment-component__status payment-component__status--approved" },
                React.createElement("span", { className: "material-symbols-outlined payment-component__status-icon" }, "select_check_box"),
                React.createElement("span", { className: "payment-component__status-text" }, "\u0423\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u043E"));
        }
        if (status === 'rejected') {
            return React.createElement("div", { className: "payment-component__status payment-component__status--rejected" },
                React.createElement("span", { className: "material-symbols-outlined payment-component__status-icon" }, "block"),
                React.createElement("span", { className: "payment-component__status-text" }, "\u041E\u0442\u043A\u043B\u043E\u043D\u0435\u043D\u043E"));
        }
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "payment-component" },
        React.createElement("div", { className: 'payment-component__top ' + (props.payment.status === 'rejected' ? 'payment-component__top--rejected' : '') },
            React.createElement("div", { className: "payment-component__price-and-user" },
                React.createElement("div", { className: "payment-component__price-block" },
                    React.createElement("div", { className: "payment-component__price" }, FormatMoney(props.payment.work_price_rate * props.payment.quantity)),
                    React.createElement("div", { className: "payment-component__price-division" },
                        "(",
                        props.payment.quantity,
                        " \u00D7 ",
                        FormatMoney(props.payment.work_price_rate),
                        props.payment.work_item === 'другое' ? '' : "/".concat(props.payment.work_item),
                        ")")),
                React.createElement("div", { className: "payment-component__price-arrow", hidden: props.public },
                    React.createElement("span", { className: "material-symbols-outlined" }, "arrow_forward")),
                props.public ? '' : React.createElement(User, { user: props.payment.user_object })),
            props.short ? '' : GetStatus(props.payment.status)),
        React.createElement("div", { className: "payment-component__bottom" },
            React.createElement("div", { className: "payment-component__props" },
                React.createElement("div", { className: "payment-component__comment", hidden: !props.payment.comment_text }, props.payment.comment_text),
                React.createElement("div", { className: "payment-component__business-comment", hidden: !props.payment.business_comment },
                    React.createElement("span", { className: "payment-component__business-comment-title" }, "\u0422\u0435\u0445\u043D\u0438\u0447\u0435\u0441\u043A\u0438\u0439 \u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439 \u0431\u0438\u0437\u043D\u0435\u0441\u0430"),
                    props.payment.business_comment),
                React.createElement("div", { className: "payment-component__hr-comment", hidden: !props.payment.hr_comment },
                    React.createElement("span", { className: "payment-component__hr-comment-title" }, "\u0422\u0435\u0445\u043D\u0438\u0447\u0435\u0441\u043A\u0438\u0439 \u043A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439 \u043E\u0442\u0434\u0435\u043B\u0430 \u043A\u0430\u0434\u0440\u043E\u0432"),
                    props.payment.hr_comment),
                React.createElement("div", { className: "payment-component__reject-text", hidden: !props.payment.reject_text }, props.payment.reject_text),
                React.createElement("div", { className: "payment-component__prop", hidden: !props.payment.order_text || props.short },
                    React.createElement("span", { className: "payment-component__prop-title" }, "\u041F\u0440\u0438\u043A\u0430\u0437:"),
                    React.createElement("span", { className: "payment-component__prop-text" }, props.payment.order_text)),
                React.createElement("div", { className: "payment-component__prop", hidden: props.short },
                    React.createElement("span", { className: "payment-component__prop-title" }, "\u0412\u0438\u0434 \u0440\u0430\u0431\u043E\u0442:"),
                    React.createElement("span", { className: "payment-component__prop-text" }, props.payment.work_name)),
                React.createElement("div", { className: "payment-component__prop", hidden: props.payment.unit_id === 1 || props.short },
                    React.createElement("span", { className: "payment-component__prop-title" }, "\u041A\u0430\u0444\u0435\u0434\u0440\u0430/\u043E\u0442\u0434\u0435\u043B:"),
                    React.createElement("span", { className: "payment-component__prop-text" }, props.payment.unit_title)),
                React.createElement("div", { className: "payment-component__prop", hidden: props.payment.unit_id === 1 || props.short },
                    React.createElement("span", { className: "payment-component__prop-title" }, "\u0412\u044B\u043F\u043B\u0430\u0442\u0430 \u043E\u0442 \u043A\u043E\u043C\u0430\u043D\u0434\u044B/\u043E\u0442\u0434\u0435\u043B\u0430:"),
                    React.createElement("span", { className: "payment-component__prop-text" },
                        React.createElement(Link, { to: "/structure/".concat(props.payment.team_id), className: "text-link" }, props.payment.team_name))),
                React.createElement("div", { className: "payment-component__prop", hidden: props.short },
                    React.createElement("span", { className: "payment-component__prop-title" }, "\u041F\u0435\u0440\u0438\u043E\u0434 \u0440\u0430\u0431\u043E\u0442:"),
                    React.createElement("span", { className: "payment-component__prop-text" }, props.payment.date_start === props.payment.date_end ?
                        DateFormat(props.payment.date_start) :
                        "".concat(DateFormat(props.payment.date_start), "\u2013").concat(DateFormat(props.payment.date_end)))),
                React.createElement("div", { className: "payment-component__prop", hidden: props.short },
                    React.createElement("span", { className: "payment-component__prop-title" }, "\u041E\u0442\u0447\u0435\u0442\u043D\u044B\u0439 \u043C\u0435\u0441\u044F\u0446:"),
                    React.createElement("span", { className: "payment-component__prop-text" }, GetTextForPaymentMonth(props.payment.month))),
                React.createElement("div", { className: "payment-component__prop payment-component__prop-user", hidden: !props.showCreator },
                    React.createElement("span", { className: "payment-component__prop-title" }, "\u0412\u044B\u043F\u043B\u0430\u0442\u0443 \u043D\u0430\u0437\u043D\u0430\u0447\u0438\u043B:"),
                    React.createElement("span", { className: "payment-component__prop-text" }, props.payment.creator_object ?
                        React.createElement(User, { user: props.payment.creator_object }) : ''))),
            React.createElement("div", { className: "payment-component__buttons" }, props.children))));
}
