export function GetLastMonths() {
    var now = new Date();
    var result = [];
    var months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    now.setMonth(now.getMonth() + 2);
    for (var i = 0; i < 10; ++i) {
        result.push([
            "".concat(('0' + (now.getMonth() + 1)).slice(-2), ".").concat(now.getFullYear()),
            "".concat(months[now.getMonth()], " ").concat(now.getFullYear()),
        ]);
        now.setMonth(now.getMonth() - 1);
    }
    return result;
}
export function GetNowMonth() {
    var now = new Date();
    return "".concat(('0' + (now.getMonth() + 1)).slice(-2), ".").concat(now.getFullYear());
}
