import * as React from "react";
import { RussianNoun } from "../../../functions";
export default function FutureVacationSidebarBlock(_a) {
    var multiple_positions = _a.multiple_positions, _b = _a.position, days_till_vacation_start = _b.days_till_vacation_start, vacation_date_range = _b.vacation_date_range, position_different_name = _b.position_different_name, class_title = _b.class_title;
    return (React.createElement("div", { className: "user-page__card user-page__card--vacation" },
        React.createElement("div", { className: "vacation-wrapper" },
            React.createElement("span", { className: "material-symbols-outlined icon" }, "beach_access"),
            React.createElement("div", { className: "vacation-info" },
                React.createElement("span", { className: "vacation-till" },
                    "\u0414\u043E \u043E\u0442\u043F\u0443\u0441\u043A\u0430 ",
                    days_till_vacation_start,
                    " ",
                    RussianNoun(days_till_vacation_start, ['день', 'дня', 'дней'])),
                React.createElement("span", { className: "vacation-period" }, vacation_date_range))),
        React.createElement("span", { className: "vacation-position", hidden: !multiple_positions },
            "\u043D\u0430 \u043F\u043E\u0437\u0438\u0446\u0438\u0438 ",
            position_different_name || class_title)));
}
