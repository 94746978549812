import * as React from "react";
import { useState } from "react";
import User from "../../components/User";
import DocumentCell from "./DocumentCell";
import { DocumentsAdminAPI } from "../../api/admin/documents/api";
/* TODO types */
export default function UserRow(props) {
    var _a = useState(false), pending = _a[0], setPending = _a[1];
    function ApproveAll() {
        setPending(true);
        DocumentsAdminAPI.ApproveAll(props.user.id)
            .finally(function () {
            return props.updater();
        })
            .finally(function () {
            setPending(false);
        });
    }
    return (React.createElement("tr", null,
        React.createElement("td", { className: "admin-documents-page__user-cell", style: { minWidth: 350 } },
            React.createElement("div", { className: "admin-documents-page__user-link" },
                React.createElement(User, { user: props.user })),
            props.allApproved ?
                React.createElement("span", { className: "admin-documents-page__approved-status" },
                    React.createElement("span", { className: "material-symbols-outlined document-uploader-component__valid-icon" }, "done"),
                    " \u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u043E") :
                (props.documents.filter(function (doc) { return doc.status === 'ok' || doc.status === 'expires soon'; }).length === props.documents.length ?
                    React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "admin-documents-page__pending-status" },
                            React.createElement("span", { className: "material-symbols-outlined document-uploader-component__pending-icon" }, "schedule"),
                            " \u0416\u0434\u0435\u0442 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F"),
                        React.createElement("button", { className: "admin-documents-page__button", disabled: pending, onClick: ApproveAll }, "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C \u0432\u0441\u0435")) :
                    React.createElement("span", { className: "admin-documents-page__rejected-status" },
                        React.createElement("span", { className: "material-symbols-outlined document-uploader-component__invalid-icon" }, "scan_delete"),
                        " \u041D\u0435 \u0432\u0441\u0435 \u0437\u0430\u0433\u0440\u0443\u0436\u0435\u043D\u043E"))),
        React.createElement("td", { className: "admin-documents-page__user-documents-list" }, props.structure.map(function (structureItem) {
            if (!props.documents) {
                return;
            }
            var document = props.documents.find(function (doc) { return doc.class_id === structureItem.id; });
            if (!document) {
                return;
            }
            return (React.createElement(DocumentCell, { document: document, documentName: structureItem.name, textdata: structureItem.textdata, user: props.user, updater: props.updater }));
        }))));
}
