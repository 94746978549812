import * as React from "react";
import { Link } from "react-router-dom";
export default function User(props) {
    var _a, _b;
    function Click(evt) {
        if (typeof props.clickFunc === 'function') {
            evt.preventDefault();
            props.clickFunc(evt);
        }
    }
    return (React.createElement(Link, { to: '/user/' + props.user.id, target: props.targetBlank ? '_blank' : '', className: "user-component", onClick: Click },
        React.createElement("span", { className: "user-component__avatar", style: { backgroundImage: 'url("' + props.user.avatar + '")' } },
            React.createElement("span", { className: "user-component__avatar-birthday", hidden: !props.user.is_birthday },
                React.createElement("span", { className: "material-symbols-outlined" }, "celebration"))),
        React.createElement("span", { className: "user-component__text" },
            React.createElement("span", { className: "user-component__not-signed-up", hidden: Boolean(!props.notSignedUpText || props.user.is_signed_up), dangerouslySetInnerHTML: { __html: props.notSignedUpText } }),
            React.createElement("span", { className: "user-component__name" },
                React.createElement("span", null, props.user.last_name),
                " ",
                React.createElement("span", null, props.user.first_name),
                " ",
                React.createElement("span", null, props.user.patronymic)),
            React.createElement("span", { className: "user-component__position" }, ((_a = props.user.position_objects) !== null && _a !== void 0 ? _a : []).map(function (position) { return React.createElement("span", null,
                position.organisation_short_name,
                ", ",
                position.position_different_name || position.class_title,
                position.parent_team_id ? ', ' + position.team_name : ''); }))), (_b = props.children) !== null && _b !== void 0 ? _b : ''));
}
