var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import AuthCodeForm from "./form";
var AuthCode = /** @class */ (function (_super) {
    __extends(AuthCode, _super);
    function AuthCode(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isLoaded: false,
        };
        return _this;
    }
    AuthCode.prototype.componentDidMount = function () {
        addEventListener('keydown', function (evt) {
            if (~'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890!@#$%^&*()'.indexOf(evt.key)) {
                var input = document.querySelector('#auth-code-form input[name="code"]');
                if (input && input instanceof HTMLInputElement) {
                    input.focus();
                }
            }
        });
    };
    AuthCode.prototype.render = function () {
        return (React.createElement("div", { className: "login-page" },
            React.createElement("div", { className: "container small-container" },
                React.createElement(AuthCodeForm, null))));
    };
    return AuthCode;
}(React.Component));
export default AuthCode;
